<template>
  <div class="contain1">
    <div class="themapdiv">
      <div class="chart pt-10">
        <div id="mapChart2" class="mapChart2"></div>
      </div>
    </div>
  </div>
</template>
    <script>
import { getMyMarketColor } from "@/api/sidebaruserinfo.js";
import { _debounce } from "@/util/common.js";
import axios from "axios";
export default {
  name: "GlobalDivision",
  data() {
    return {
      countryList: [],
      myChart: null,
      chinaCode: "world",
      curMapName: "China", // 当前地图名
      colorList: [],
    };
  },

  methods: {
    async drawMapChart(mapName, mapJSON) {
      if (
        this.myChart != null &&
        this.myChart != "" &&
        this.myChart != undefined
      ) {
        this.myChart.dispose();
      }
      this.myChart = this.$echarts.init(document.getElementById("mapChart2"));
      this.$echarts.registerMap(mapName, mapJSON);
      // 获取颜色列表
      const res = await getMyMarketColor();
      const thecolorJson = res.data;

      // 修改颜色列表
      thecolorJson.forEach((item) => {
        item.itemStyle = {
          normal: {
            areaColor: "#C8161D", // 选中区域的颜色
          },
        };
      });
     
     
      this.myChart.setOption({
        series: [
          {
            type: "map",
            map: mapName,
            roam: false, // 是否开启鼠标缩放和平移漫游
            zoom: 2,
            selectedMode: "false", // 是否允许选中多个区域
            label: {
              show: false, //地图上地区文字的现实
              color: "#a8e6e1",
            },
            zoom: 1, // 设置初始缩放级别
            zoomLimit: {
              // 设置缩放级别的限制范围
              min: 1, // 最小缩放级别
              max: 1, // 最大缩放级别
            },

            emphasis: {
              label: {
                show: true,
                color: "#ffffff",
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#14517c",
                borderColor: "#14517c",
                borderWidth: 1,
              },
              //鼠标悬浮区域上的背景颜色
              emphasis: {
                show: true,
                areaColor: "#C8161D",
              },
            },
            tooltip: {
              trigger: "item",
              show: true,
              backgroundColor: "transparent",

              textStyle: {
                width: 300,
                height: 300,
              },
            },

            // data: this.initMapData(mapJSON),
            data: thecolorJson, // 使用修改后的颜色列表作为地图数据
          },
        ],
      });
     
      
    },
    initMapData(mapJson) {
      var thecolorJson = this.colorList;
      thecolorJson.map((item) => {
        item.itemStyle = {
          normal: {
            areaColor: "#C8161D", //选中区域的颜色
          },
        };
      });
      let mapData = [];
      for (let i = 0; i < mapJson.features.length; i++) {
        mapData.push({ name: mapJson.features[i].properties.name });
      }
      // console.log("🌈thecolorJson", thecolorJson);
      return thecolorJson;
    },

    // 浏览器窗口大小改变时，重新加载图表以自适应
    resizeCharts: _debounce(function () {
      this.$echarts.init(document.getElementById("mapChart2")).resize();
    }, 500),
    // 获取地图数据
    getMapData(map) {
      axios
        .get(`/map/${map}.json`)
        .then((res) => {
          if (res.status == 200) {
            const mapJSON = res.data;
            this.drawMapChart(this.curMapName, mapJSON);
          }
        })
        .catch((err) => {
          this.$message({ message: err, type: "error", showClose: true });
        });
    },
  },
  mounted() {
    this.getMapData(this.chinaCode);
    window.addEventListener("resize", this.resizeCharts);
  },
  beforeDestroy() {
    window.addEventListener("resize", this.resizeCharts);
  },
};
</script>
    <style lang='scss' scoped>
.chart {
  // background-image: url("../assets/img/bgImg.png");
  // background-size: 100% 100%;
  #mapChart2 {
    width: 100%;
    height: 580px;
    position: relative;
  }
  .mapChoose {
    position: absolute;
    left: 20px;
    top: 155px;
    color: #eee;
    .title {
      padding: 5px;
      border-top: 1px solid rgba(132, 219, 233, 0.8);
      border-bottom: 1px solid rgba(147, 235, 248, 0.8);
      cursor: pointer;
    }
    .icon {
      font-family: "simsun";
      font-size: 25px;
      margin: 0 11px;
    }
  }
}
.contain1 {
  // padding-bottom: 94px;
  background: #f9f9f9;
  .themapdiv {
    width: 100%;
    background: #ffffff;
    //   height: 952px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content_qu {
    width: 1240px;
    // height: 555px;
    background: #ffffff;
    box-shadow: 0px 8px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px;
    margin: 40px auto;
    padding: 20px 30px;
    .qu_title {
      font-size: 48px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      text-align: center;
    }
    .small_title {
      font-size: 24px;
      font-family: MicrosoftYaHei;
      color: #999999;
      margin-top: 20px;
      text-align: center;
    }
    .all_shi {
      margin-top: 90px;
      background: skyblue;
    }
    .flex_contry {
      display: flex;
      flex-wrap: wrap;
      padding-left: 100px;

      .thecontry {
        margin-top: 40px;
        width: 360px;
        margin-right: 20px;
        .contry_title {
          font-size: 24px;
          font-family: MicrosoftYaHei;
          color: #333333;
        }
        .contry_img {
          width: 242px;
          height: 123px;
          margin: 20px 0;
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
        .contry_flex {
          display: flex;
          margin-bottom: 20px;
          align-items: center;
          i {
            font-size: 26px;
          }
        }
        .contry_name {
          font-size: 18px;
          font-family: MicrosoftYaHei;
          color: #333333;
          margin-left: 20px;
        }
      }
    }
  }
}
</style>