<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2022-09-29 09:05:39
-->
<template>
	<div class="contain">
		<!-- 海外协议弹层-->
		<div>
			<deal />
		</div>
		<div>
			<!-- 忘记密码提示弹层-->
			<el-dialog :visible.sync="dialogs" width="30%" class="dia-log-s" style="background: none"
				:close-on-click-modal="false" :close-on-press-escape="true" center>
				<div class="bg-color">
					<div class="bg_title">{{ $t("w_0034") }}</div>
					<div class="bg_text">
						{{ $t("w_0284") }} <span>{{ $t("w_0285") }}</span>
					</div>
					<div class="bg_button">
						<img src="@/assets/images/button-icon.png" />
						<div class="bg_text_s" @click="tcShow">{{ $t("w_0286") }}</div>
					</div>
				</div>
				<!--强制修改密码 上线后打开 <div class="close-tc" @click="closeTc" v-if="userInfo.pkCountry!=1">
                  <img src="@/assets/images/close-tc.png" />
              </div>-->
				<div class="close-tc" @click="closeTc">
					<img src="@/assets/images/close-tc.png" />
				</div>
			</el-dialog>
			<!-- 修改密码弹框-->
			<el-dialog title="修改密码" :visible.sync="dialogVisible" :close-on-click-modal="false" :show-close="false"
				:close-on-press-escape="false" width="35%" center>
				<div class="changePwd">
					<el-form ref="loginObj" :model="loginObj" :rules="rulesObj" label-width="100px">
						<el-row :gutter="20">
							<el-col :span="16">
								<el-form-item :label="$t('N_I_124')" prop="memberCode">
									<el-input placeholder="" v-model="loginObj.memberCode"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="16">
								<el-form-item :label="$t('w_0047')" prop="newPassword">
									<el-input v-model="loginObj.newPassword" :type="flag1 ? 'text' : 'password'"
										style="border: 1px solid #fff" placeholder="">
										<i slot="suffix" :class="[flag1 ? 'el-icon-minus' : 'el-icon-view']"
											style="font-size: 18px" autocomplete="auto" @click="flag1 = !flag1" />
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8" class="righTxt">
								{{ $t("w_0049") }}
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="16">
								<el-form-item :label="$t('w_0050')" prop="loginPassword">
									<el-input v-model="loginObj.loginPassword" :type="flag2 ? 'text' : 'password'"
										style="border: 1px solid #fff" placeholder="">
										<i slot="suffix" :class="[flag2 ? 'el-icon-minus' : 'el-icon-view']"
											style="font-size: 18px" autocomplete="auto" @click="flag2 = !flag2" />
									</el-input>
								</el-form-item>
							</el-col>
							<el-col :span="8" class="righTxt">
								{{ $t("w_0049") }}
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="16">
								<el-form-item :label="$t('w_0052')" prop="phone">
									<el-input placeholder="" v-model="loginObj.phone"></el-input>
								</el-form-item>
							</el-col>
						</el-row>
						<el-row :gutter="20">
							<el-col :span="16">
								<el-form-item :label="$t('w_0043') + ':'" prop="code">
									<el-input v-model="loginObj.code" placeholder="">
										<i slot="suffix" @click="submitForm('loginObj')" class="hqyzm1"
											:class="isSend ? 'hui' : ''">{{ getSmsCode }}</i></el-input>
								</el-form-item>
							</el-col>
						</el-row>
					</el-form>
				</div>
				<!--              <span slot="footer"-->
				<!--                    v-if="userInfo.pkCountry!=1"-->
				<!--                    class="dialog-footer">-->
				<!--        <el-button @click="dialogVisible = false" class="cancel-button">{{$t('MY_ORD_24')}}</el-button>-->
				<!--        <el-button style="color:#fff;background: #cb2b2b;"-->
				<!--                   @click="submitLoginObj('loginObj')" class="submit-button">{{$t('w_0035')}}</el-button>-->
				<!--      </span>-->
				<!--              <span slot="footer"-->
				<!--                    v-if="userInfo.pkCountry==1"-->
				<!--                    class="dialog-footer">-->
				<!--        <el-button style="color:#fff;background: #cb2b2b;margin-left: 0"-->
				<!--                   @click="submitLoginObj('loginObj')" class="submit-button">{{$t('w_0035')}}</el-button>-->
				<!--      </span>-->
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible = false" class="cancel-button">{{
            $t("MY_ORD_24")
          }}</el-button>
					<el-button style="color: #fff; background: #cb2b2b" @click="submitLoginObj('loginObj')"
						class="submit-button">{{ $t("w_0035") }}</el-button>
				</span>
			</el-dialog>
		</div>
		<div>
			<!-- 左侧侧边栏 -->
			<sidebarUserInfo></sidebarUserInfo>
		</div>
		<div class="topbanner">
			<el-carousel height="380px" v-if="bannerList.length > 0">
				<el-carousel-item v-for="item in bannerList" :key="item.pkId">
					<img class="bannerimg" :src="item.picture" alt="" />
				</el-carousel-item>
			</el-carousel>
			<div class="target" id="targetId" v-show="menu1">
				<div>
					<userTarget ref="userTarget" />
				</div>
				<a id="link"></a>
			</div>
			<div class="add-target" v-show="menu2">
				<div class="title">{{ $t("S_C_71") }}</div>
				<div>
					<performance />
				</div>
			</div>
			<!-- <div class="add-target" style="height: auto !important">
				<div class="title">{{ $t("w_1035") }}</div>
				<div>
					<yearGift />
				</div>
			</div> -->
			<div class="add-target">
				<div class="title">{{ $t("S_C_81") }}</div>
				<mySuperEcharts></mySuperEcharts>
			</div>
			<!-- <div class="add-target">
				<div class="title">{{ $t("MN_F_T_198") }}</div>
				<div>
					<globalEcharts></globalEcharts>
				</div>
			</div> -->
			<!-- 全球市场 -->
			<!--  <div class="add-target">
        <globalMarket></globalMarket>
      </div> -->
			<div style="height: 300px"></div>
		</div>
		<div>
			<!-- 右侧侧侧边栏 -->
			<SidebarRight></SidebarRight>
		</div>
		<div>
			<!-- 公告弹窗 -->
			<notice-popup :userInfo="userInfo" :publishLocationIndex="index" ref="child"></notice-popup>

			<!-- 站内信弹窗 -->
			<znNews-popup ref="child2" @childMethodTrigger="callChildMethod"></znNews-popup>

			<!-- 直推排行弹窗 -->
			<directrank-popup @callznMethodTrigger="callznMethod"></directrank-popup>
		</div>

		<div class="domTree">
			<el-dialog title="" width="35%" :visible.sync="isTree" :before-close="closeTree" :show-close="false">
				<div class="shareClass" :style="{ backgroundImage: `url(${treeObj.cover})` }">
					<div class="cha" @click="isTree = false">
						<i class="el-icon-close"></i>
					</div>
					<div class="titTre">{{ treeObj.actName }}</div>
					<img class="shareImg" @click="toPayTree" src="@/assets/images/zs.png" alt="" />
				</div>
			</el-dialog>
		</div>
		<div class="goodsDiag_a">
			<!-- 植树 -->
			<el-dialog title="" :visible.sync="isGoods" width="25%" center :append-to-body="true" style="z-index: 30000"
				:before-close="closeGoods">
				<div class="goodsDiag">
					<div class="dis_t">{{ $t("w_0283") }}</div>
					<div class="dis_a">
						<div class="disFlex">
							<div class="dis_l">{{ $t("MY_TH_2") }}:</div>
							<el-input-number size="mini" @change="treePrice" v-model="treeObj.quantity"
								:min="treeMin"></el-input-number>
						</div>
						<div class="disFlex">
							<div class="dis_l">{{ $t("w_0287") }}:</div>
							<div class="dis_r">￥{{ treeObj.amount | numberToCurrency }}</div>
						</div>
					</div>
				</div>
				<span slot="footer" class="treefooter">
					<el-button type="primary" class="delBtn" @click="payGoods">{{
            $t("w_0035")
          }}</el-button>
				</span>
			</el-dialog>
		</div>
		<PayTree :isPay="isPay" ref="payment" @closePay="closePay"></PayTree>
	</div>
</template>
<script>
	import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
	import deal from "@/components/deal.vue";
	import SidebarRight from "@/components/sidebarRight.vue";
	import performance from "@/components/performance.vue";

	import honoraryAward from "@/components/honoraryAward.vue";
	import yearGift from "@/components/yearGift.vue";
	import globalEcharts from "@/components/globalEcharts.vue";
	import userTarget from "@/components/userTarget.vue";
	import mySuperEcharts from "@/components/mySuperEcharts.vue";
	import noticePopup from "@/components/noticePopup.vue";
	import globalMarket from "@/components/globalMarket.vue";

	import * as api from "@/api/index.js";
	import {
		mapGetters
	} from "vuex";
	import {
		getUserUpdatePWd
	} from "@/api/wallet";
	import {
		forgetPwd,
		forgetSms
	} from "@/api/login";
	import PayTree from "@/components/PayTree.vue";
	import * as goods from "@/api/goods.js";
	import znNewsPopup from "@/components/znNewsPopup.vue";
	import directrankPopup from "@/components/directrankPopup.vue";
	// import { getAlertUserAgreement } from "@/api/index.js";
	export default {
		name: "Index",
		components: {
			userTarget,
			sidebarUserInfo,
			SidebarRight,
			deal,
			performance,
			honoraryAward,
			globalEcharts,
			mySuperEcharts,
			noticePopup,
			globalMarket,
			PayTree,
			znNewsPopup,
			directrankPopup,
			yearGift,
		},
		computed: {
			...mapGetters(["userInfo"]),
		},
		data() {
			let validatePwd = (rule, value, callback) => {
				if (this.loginObj.newPassword != value) {
					callback(new Error(this.$t("w_0054")));
				} else {
					callback();
				}
			};
			return {
				treeMin: 0,
				isPay: false,
				isTree: false,
				isGoods: false,
				index: 10,
				isSend: false,
				bannerList: [],
				closeFlag: false,
				dialogs: false,
				flag1: false,
				getSmsCode: this.$t("w_0055"),
				flag2: false,
				dialogVisible: false,
				marketData: {},
				loginObj: {},
				rulesObj: {
					memberCode: [{
						required: true,
						trigger: "blur",
						message: this.$t("w_0046")
					}, ],
					newPassword: [{
						required: true,
						trigger: "blur",
						message: this.$t("w_0048")
					}, ],
					loginPassword: [{
						validator: validatePwd,
						trigger: "blur"
					}],
					phone: [{
						required: true,
						trigger: "blur",
						message: this.$t("w_0053")
					}, ],
				},
				treeObj: "",
				menu1: false,
				menu2: false,
				menu3: false,
				promptFlag: 0, //提示标记（0=弹窗提示，1=不弹窗提示）
				promptMsg: "", //提示信息
				jumpPage: 1, //跳转页面（1=实名认证，2=续约专区）
			};
		},

		mounted() {
			// if (
			// 	localStorage.getItem("showInfo") == undefined ||
			// 	localStorage.getItem("showInfo") == 0
			// ) {
			// 	this.getRepurchase();
			// }

			this.getBanner();
			// this.getMarket();
			this.getMenuList();
			// this.getTree();
			this.getService();
			// this.getUserPwdTc(); //强制弹窗密码 上线后加上
		},
		methods: {
			// 复购弹框提示
			// getRepurchase() {
			// 	api.index_repurchase().then((res) => {
			// 		if (res.msg) {
			// 			this.$alert(res.msg, "提示", {
			// 				confirmButtonText: "确定",
			// 				callback: (action) => {
			// 					localStorage.setItem("showInfo", 1);
			// 				},
			// 			});
			// 		}
			// 	});
			// },
			// agreement_expire
			getService() {
				api.agreement_expire().then((res) => {
					if (res.code == 200) {
						this.promptFlag = res.data.promptFlag;
						this.promptMsg = res.data.promptMsg;
						this.jumpPage = res.data.jumpPage;
						let name;
						if (this.jumpPage == 1) {
							name = "实名认证";
						} else {
							name = "立即续约";
						}
						if (this.promptFlag == 0) {
							this.$alert(this.promptMsg, "提示", {
								confirmButtonText: name,
								callback: (action) => {
									if (action != "cancel") {
										if (this.jumpPage == 1) {
											this.$router.push({
												path: "/vipCenter",
												query: {
													id: 5,
													atabActiveId: 9
												},
											});
										} else {
											this.$router.push({
												path: "/shoppingMall",
												query: {
													id: 30
												},
											});
										}
									}
								},
							});
						}
					}
				});
			},
			callChildMethod() {
				this.$refs.child.getUserTc();
			},
			callznMethod() {
				this.$refs.child2.getUserTc();
			},
			getMenuList() {
				goods.menuList().then((res) => {
					res.data.forEach((item) => {
						if (item.menu == "101") {
							// 目标设定
							this.menu1 = true;
							this.$refs.userTarget.getAllData();
						}
						if (item.menu == "102") {
							// 新增业绩
							this.menu2 = true;
						}
						if (item.menu == "103") {
							// 荣誉奖衔
							this.menu3 = true;
						}
					});
				});
			},
			closePay(val) {
				if (val) {
					this.isPay = false;
					this.isGoods = false;
				} else {
					this.$confirm(this.$t("w_0090") + "?")
						.then((_) => {
							this.isPay = false;
							this.isGoods = false;
						})
						.catch((_) => {});
				}
			},
			closeTree() {
				this.isTree = false;
			},
			closeGoods() {
				this.isGoods = false;
			},
			treePrice(e) {
				let amount = e * Number(this.treeObj.price);
				this.$set(this.treeObj, "amount", amount);
			},
			payGoods() {
				this.$refs.payment.howMoney(this.treeObj);
				this.isPay = true;
				// api
				//   .shoppingTree({
				//     pkBaseId: this.treeObj.pkBaseId,
				//     pkTreeRule: this.treeObj.pkTreeRule,
				//     shopQuantity: this.treeObj.quantity,
				//     orderAmount: this.treeObj.amount,
				//   })
				//   .then((res) => {})
			},
			toPayTree() {
				this.isGoods = true;
				this.isTree = false;
			},
			getTree() {
				api.queryTreeActivity().then((res) => {
					if (res.data) {
						if (res.data.isStrongAlert == 0) {
							this.treeObj = res.data;
							this.treeMin = res.data.quantity;
							this.$set(
								this.treeObj,
								"amount",
								Number(res.data.quantity) * Number(res.data.price)
							);
							this.isTree = true;
						}
					}
				});
			},
			tcShow() {
				this.dialogVisible = true;
			},
			submitLoginObj(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						forgetPwd(this.loginObj)
							.then((res) => {
								this.$message({
									message: res.msg,
									type: "success",
								});
								this.dialogVisible = false;
							})
							.catch((err) => {});
					} else {
						return false;
					}
				});
			},
			// 绑卡获取验证码
			submitForm(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						this.startTime();
						forgetSms(this.loginObj)
							.then((res) => {
								// this.bankCode = res.data
							})
							.catch((err) => {
								this.beginTime = 0;
							});
					} else {
						return false;
					}
				});
			},
			getUserPwdTc() {
				getUserUpdatePWd().then((res) => {
					if (res.code == "200") {
						this.dialogs = res.data > 0 ? false : true;
					}
				});
			},
			startTime() {
				if (this.isSend) return;
				this.isSend = true;
				this.getSmsCode = this.beginTime + this.$t("w_0058");
				let that = this;
				this.timer = setInterval(() => {
					this.beginTime--;
					this.getSmsCode = this.beginTime + this.$t("w_0058");
					if (this.beginTime < 0) {
						clearInterval(this.timer);
						this.getSmsCode = this.$t("w_0055");
						this.beginTime = 60;
						this.isSend = false;
					}
				}, 1000);
			},
			closeTc() {
				this.dialogs = false;
			},
			getBanner() {
				api.getIndexBanner().then((res) => {
					console.log("🚀 ~ res", res);
					this.bannerList = res.rows;
				});
			},
			// getMarket() {
			//   api.getMyMarket().then((res) => {
			//     this.marketData = res.data;
			//   });
			// },
		},
	};
</script>
<style lang="scss" scoped>
	.cancel-button {
		width: 200px;
		height: 66px;
		background: #cccccc;
		border-radius: 10px 10px 10px 10px;
		color: #fff;
		font-size: 22px;
	}

	.submit-button {
		width: 200px;
		height: 66px;
		background: #d61820;
		border-radius: 10px 10px 10px 10px;
		color: #fff;
		font-size: 22px;
		margin-left: 60px;
	}

	.hqyzm1 {
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #0099ff;
		cursor: pointer;
		margin-right: 10px;
		font-style: normal;
	}

	.hui {
		opacity: 0.5;
	}

	.demo-ruleForm {
		padding-bottom: 21px;
	}

	.sumBtn {
		background: #cb2b2b;
		border-radius: 10px;
		height: 66px;
		font-size: 22px;
		font-weight: bold;
		color: #ffffff;
		line-height: 66px;
		letter-spacing: 1px;
		text-align: center;
		cursor: pointer;
	}

	.pr {
		position: relative;
	}

	.pa {
		position: absolute;
		right: 0;
		bottom: -32px;
		font-size: 12px;
		cursor: pointer;
		color: #999999;
	}

	.righTxt {
		// margin-bottom: 25px;
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		cursor: pointer;
		line-height: 63px;
	}

	.changePwd {
		::v-deep .el-input__inner {
			// border: 0;
			// background: rgba(213, 213, 213, 0.3);
			// border-radius: 8px;
			height: 63px;
		}

		::v-deep .el-form-item__label {
			line-height: 63px;
			color: #333;
		}

		::v-deep .el-input__suffix {
			line-height: 63px;
		}

		::v-deep .el-icon-view {
			line-height: 63px;
		}
	}

	::v-deep .dia-log-s .el-dialog__body {
		padding-bottom: 60px;
	}

	.close-tc {
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -50px;
		cursor: pointer;

		img {
			width: 28px;
			height: 28px;
		}
	}

	.bg_text_s {
		position: absolute;
		bottom: 31px;
		width: 100%;
		display: flex;
		justify-content: center;
		color: #fff;
		font-size: 20px;
	}

	.bg_button {
		position: absolute;
		bottom: 30px;
		cursor: pointer;

		img {
			width: 250px;
			height: 60px;
		}
	}

	.bg_text {
		position: absolute;
		top: 100px;
		color: #fff;
		font-size: 20px;

		span {
			font-size: 30px;
		}
	}

	.bg_title {
		position: absolute;
		color: #fff;
		font-size: 30px;
		top: 20px;
	}

	::v-deep .dia-log-s .el-dialog {
		background: none !important;
		box-shadow: none;
	}

	::v-deep .dia-log-s .el-dialog__header {
		display: none;
	}

	.pwdForget {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 600px;
		height: 600px;
		z-index: 1;
		text-align: center;
	}

	.bg-color {
		background: url("~@/assets/images/forget-bg.png") no-repeat;
		position: relative;
		width: 440px;
		height: 530px;
		z-index: 11;
		background-size: 100% 100%;
		display: flex;
		justify-content: center;
	}

	.map {
		height: 390px;
		margin-top: 5px;
		background: #fff;
		box-shadow: 7px 5px 10px 0px rgba(59, 59, 59, 0.14);
		border-radius: 10px;
	}

	body,
	html,
	#app {
		background: rgba(0, 0, 0, 0.05);
	}

	.title {
		color: #333;
		font-size: 18px;
	}

	.add-target {
		padding: 20px;
		box-shadow: 7px 5px 10px 0px rgba(59, 59, 59, 0.14);
		border-radius: 10px 10px 10px 10px;
		background-color: #fff;
		// height: 580px;
		position: relative;
		margin-top: 20px;
		width: 1040px;
	}

	.target {
		//margin-top: 20px;
		padding: 0px 20px;
		box-shadow: 7px 5px 10px 0px rgba(59, 59, 59, 0.14);
		border-radius: 10px 10px 10px 10px;
		background-color: #fff;
		//height: 480px;
		//margin-top: 20px;
	}

	::v-deep .el-carousel--horizontal {
		border-radius: 8px;
		width: 1080px;
	}

	.contain {
		width: 100%;
		height: 100%;
		padding: 40px 0;
		display: flex;

		.topbanner {
			width: 1080px;
			// padding:0 20px;
			border-radius: 8px;
			//background-color: #333;
			height: 380px;

			.bannerimg {
				width: 100%;
				height: 100%;
				border-radius: 10px;
			}
		}
	}

	.superDiv {
		display: flex;
	}

	.videoContainer {
		position: fixed;
		width: 100%;
		height: 100%;
		overflow: hidden;
		z-index: -100;
	}

	.videoContainer:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		z-index: -1;
		top: 0;
		left: 0;
		background: rgba(25, 29, 34, 0.65);
	}

	.fullscreenVideo {
		width: 100%;
		height: 100%;
	}

	video {
		width: 100% !important;
		object-fit: fill; //消除留白
	}

	.tit {
		display: flex;
		position: absolute;
		margin: auto;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 142px;
		font-weight: normal;
		color: #ffffff;
		font-family: FZLSJW--GB1-0, FZLSJW--GB1;

		div {
			white-space: nowrap;
			margin-right: 100px;
		}
	}

	.shareClass {
		border-radius: 10px 10px 10px 10px;
		// background-image: url('../assets/images/sm.png');
		background-size: 100%;
		background-repeat: no-repeat;
		// text-align: center;
		position: relative;
		height: 750px;
		text-align: center;
	}

	.cha {
		position: absolute;
		top: 10px;
		right: 10px;
		font-size: 28px;
	}

	.titTre {
		font-size: 32px;
		font-weight: 600;
		color: #055316;
		padding-top: 20px;
	}

	.shareImg {
		width: 221px;
		height: 73px;
		position: absolute;
		left: 10px;
		right: 0;
		margin: 0 auto;
		bottom: 7%;
		cursor: pointer;
	}

	.domTree ::v-deep .el-dialog__header {
		padding: 0;
	}

	.domTree ::v-deep .el-dialog__body {
		padding: 0;
	}

	.domTree ::v-deep .el-dialog {
		border-radius: 10px;
	}

	.goodsDiag {
		text-align: center;

		.dis_t {
			font-size: 28px;
			font-weight: 500;
			color: #333;
			margin-top: -40px;
		}

		.dis_a {
			margin-top: 40px;
		}

		.disFlex {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
		}

		.dis_l {
			margin-right: 10px;
		}

		.dis_r {
			color: #e3000f;
			font-size: 28px;
			font-weight: 500;
		}
	}

	.treefooter {
		::v-deep .el-button {
			width: 200px;
		}

		// 删除按钮
		.delBtn {
			background: #c8161d;
			color: #fff;
			border-color: #c8161d;
		}

		.delBtn:hover {
			background: #c8161d;
			color: #fff;
			border-color: #c8161d;
		}
	}
</style>