
<template>
  <div>
    <el-dialog
      class="notice"
      width="35%"
      :title="$t('ENU_APPROVE_B_522')"
      :visible.sync="noticeFlag"
      @close="closeTap()"
    >
      <div class="tchdbox">
        <div class="bjtp">
          <div class="top_kuai kuai1">
            <div :class="popupList[0].isLoginMember == 1 ? 'mname' : 'mname2'">
              {{ popupList[0].memberName }}
            </div>
            <div class="tx">
              <img
                :src="
                  popupList[0].headPath
                    ? popupList[0].headPath
                    : userInfo.countryCircularIcon
                "
                alt=""
              />
            </div>
            <div class="yeji">{{ popupList[0].orderAchieveStr }}</div>
            <div class="topb topb1">TOP.1</div>
          </div>
          <div class="top_kuai kuai2">
            <div :class="popupList[1].isLoginMember == 1 ? 'mname' : 'mname2'">
              {{ popupList[1].memberName }}
            </div>
            <div class="tx">
              <img
                :src="
                  popupList[1].headPath
                    ? popupList[1].headPath
                    : userInfo.countryCircularIcon
                "
                alt=""
              />
            </div>
            <div class="yeji">{{ popupList[1].orderAchieveStr }}</div>
            <div class="topb topb2">TOP.2</div>
          </div>
          <div class="top_kuai kuai3">
            <div :class="popupList[2].isLoginMember == 1 ? 'mname' : 'mname2'">
              {{ popupList[2].memberName }}
            </div>
            <div class="tx">
              <img
                :src="
                  popupList[2].headPath
                    ? popupList[2].headPath
                    : userInfo.countryCircularIcon
                "
                alt=""
              />
            </div>
            <div class="yeji">{{ popupList[2].orderAchieveStr }}</div>
            <div class="topb topb3">TOP.3</div>
          </div>
          <div class="poupmain">
            <div class="main_title">{{ themonth }}月直推排行榜</div>
            <div class="table">
              <div class="linerow flexrow">
                <div class="line1">排名</div>
                <div class="line2"></div>
                <div class="line3">会员姓名</div>
                <div class="line4">直推业绩(万)</div>
              </div>
              <div
                v-for="(item, index) in popupList2"
                :key="index"
                :class="item.isLoginMember == 1 ? 'flexrow' : 'tcrow'"
              >
                <div class="theline line1">
                  <div class="indexrow">{{ index + 4 }}</div>
                </div>
                <div class="theline line2">
                  <img
                    :src="
                      item.headPath
                        ? item.headPath
                        : userInfo.countryCircularIcon
                    "
                    alt=""
                  />
                </div>
                <div class="theline line3">{{ item.memberName }}</div>
                <div class="theline line4">{{ item.orderAchieveStr }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="closediv">
          <img class="qyclose" :src="closeImg" @click="closeTap()" />
        </div>
      </div>
      <!-- <div class="title">
          {{ item.title }}
        </div>
        <div class="img-auto" v-html="item.content"></div>
        <div class="surebtn1">
          <div class="btn" @click="closeTap(item, 2)">已读</div>
        </div> -->
    </el-dialog>
  </div>
</template>
    
<script>
import { getMemberDirectpush } from "@/api/index.js";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Title",

  data() {
    return {
      popupList: [],
      noticeFlag: false,
      popupList2: [],
      themonth: "1",
      closeImg: require("@/assets/images/close-tc.png"),
    };
  },
  mounted() {
    this.getDirectrank();
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    closeTap() {
      this.noticeFlag = false;
      this.$forceUpdate(); // 强制更新视图
      this.$emit("callznMethodTrigger");
    },
    getDirectrank() {
      if (this.userInfo.pkCountry == 1) {
        const currentDate = new Date();
        this.themonth = currentDate.getMonth() + 1;
        getMemberDirectpush().then((res) => {
          if (res.code == 200) {
            if (res.data && res.data.length > 0) {
              this.popupList = res.data;
              let arr = res.data;
              let newArr = arr.filter((item, index) => index >= 3); // 过滤掉索引小于3的元素
              this.popupList2 = newArr;
              this.noticeFlag = true;
            } else {
              this.$emit("callznMethodTrigger");
            }
          }
        });
      } else {
        this.$emit("callznMethodTrigger");
      }
    },
  },
};
</script>
    
    <style lang="scss" scoped>
.notice {
  background: rgba(0, 0, 0, 0.7) !important;
}

::v-deep .el-dialog__headerbtn i {
  font-size: 24px;
  color: #333;
}

::v-deep .el-dialog__header {
  display: none;
}
::v-deep .el-dialog__title {
  font-size: 32px;
  color: #333;
  font-weight: bold;
}
::v-deep .el-dialog__body {
  padding: 0;
}
.closediv {
  cursor: pointer;
  width: 50px;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
.tchdbox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 30px;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;

  .bjtp {
    width: 600px;
    height: 750px;
    background-image: url(../assets/images/ztph.png);
    background-size: 600px 750px;
    position: relative;
  }
  .top_kuai {
    width: 180px;
    .mname {
      color: #ffffff;
      text-align: center;
      font-size: 24px;
    }
    .mname2 {
      color: #d2322b;
      text-align: center;
      font-size: 26px;
    }
    // background: pink;
    .tx {
      width: 70px;
      height: 70px;
      margin: 0 auto;
      // background: pink;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .yeji {
      background: rgba(255, 192, 188, 0.4);
      border-radius: 8px;
      color: #d2322b;
      font-size: 20px;
      width: 100%;
      text-align: center;
      padding: 5px 0;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      margin-top: 15px;
    }
    .topb {
      font-weight: 600;
      color: #ffffff;

      text-align: center;
    }
  }
  .kuai1 {
    position: absolute;
    left: 210px;
    top: -60px;
  }
  .kuai2 {
    position: absolute;
    left: 8px;
    top: -30px;
  }
  .kuai3 {
    position: absolute;
    left: 410px;
    top: -10px;
  }
  .topb1 {
    font-size: 36px;
    margin-top: 30px;
  }
  .topb2 {
    font-size: 28px;
    margin-top: 20px;
  }
  .topb3 {
    font-size: 24px;
    margin-top: 5px;
  }
  .poupmain {
    // background: yellow;

    margin-top: 180px;

    padding: 0 20px;
    font-size: 16px;
    .main_title {
      text-align: center;
      color: #d23932;
      font-size: 26px;
      font-weight: 600;
      margin: 10px 0;
    }
    .table {
      height: 500px;
      overflow: auto;
    }
    .linerow {
      background: rgba(217, 217, 217, 0.4);
      padding: 10px 10px;
      border-radius: 8px;
    }
    .tcrow {
      display: flex;
      color: #d23932;
      background: rgba(217, 217, 217, 0.4);
      border-radius: 8px;
    }
    .flexrow {
      display: flex;
    }
    .theline {
      font-weight: 600;
      line-height: 50px;
      font-size: 20px;
    }
    .line1 {
      width: 50px;
    }
    .line2 {
      width: 100px;
      text-align: right;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
    .line3 {
      width: 150px;
      text-align: center;
    }
    .line4 {
      width: 180px;
      text-align: right;
    }
    .indexrow {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-color: #ffe7e8;
      clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
    }
  }
}
</style>