<template>
  <div class="sidebarWai">
    <div class="sidebarTop">
      <div class="sidebarHeader">
        <img
          :src="
            userInfo.headPath ? userInfo.headPath : userInfo.countryCircularIcon
          "
          alt=""
        />
      </div>
      <div class="nameCountry">
        <div class="username">{{ userInfo.memberName }}</div>
        <img :src="userInfo.countrySquareIcon" alt="" />
      </div>
      <!-- 如果是0表示没有奖衔 只显示等级徽章!-->
      <div class="gradeClass">
        <img
          @error="defaultSrc"
          v-if="userInfo.gradeIcon || defaultSrc == true"
          @click="goRouter(1)"
          :src="userInfo.gradeIcon"
          alt=""
        />
        <!-- <img
          v-if="userInfo.awardsIcon"
          @click="goRouter(2)"
          :src="userInfo.awardsIcon"
          alt=""
        /> -->
      </div>
      <div class="yongyu_t">
        <div class="yongyu1">
          <div class="jxTit">
            <div>{{ $t("w_1037") }}</div>
            <div class="jxTit1">{{ awardsList.awardsName }}</div>
          </div>
          <!-- <div class="jxTit">
            <div>{{ $t("w_1038") }}</div>
            <div class="jxTit1">{{ awardsList.awardsName }}</div>
          </div>
          <div class="jxTit" v-if="awardsList.historyAwards">
            <div>{{ $t("w_1036") }}</div>
            <div class="yt1">{{ awardsList.historyAwards }}</div>
          </div> -->
        </div>
      </div>
    </div>

    <!--! 用户卡片信息 -->
    <div class="awardscard">
      <div class="user-cards" @click="clickTap">
        <div class="user-cards-left">
          <!-- <div v-if="userInfo.pkCountry == 1">
            <div
              class="experience"
              v-if="awards.targetPv != '-6.40'"
              :spv="awards.sumRealPv"
              :tpv="awards.targetPv"
            >
              <span class="ju_text1">恭喜您!已达成 </span>
              <span class="ju_text2">{{ awards.awardsName }}</span>
              <span> 奖衔,冲刺 </span>
              <span class="ju_text2">{{ awards.pkTransactionVal }}</span>
              <span>奖衔</span>
              <span>小区仅需</span>
              <span class="ju_text2">
                {{
                  (awards.targetPv - awards.sumRealPv > 0
                    ? awards.targetPv - awards.sumRealPv
                    : "0.00") | toThousandthAndKeepDecimal
                }}{{ $t("S_C_58") }}
              </span>
            </div>
            <div class="experience" v-else>
              <span class="ju_text1">恭喜您!已达标当月最高奖衔</span>
              <span class="ju_text2">{{ awards.awardsName }}</span>
              <span class="ju_text1">!记得保持哦!</span>
            </div>
          </div>
          <div v-else>
            <div
              class="experience"
              v-if="awards.targetPv != '-1.00'"
              :spv="awards.sumRealPv"
              :tpv="awards.targetPv"
            >
              <span class="ju_text1">恭喜您!已达成 </span>
              <span class="ju_text2">{{ awards.awardsName }}</span>
              <span> 奖衔,冲刺 </span>
              <span class="ju_text2">{{ awards.pkTransactionVal }}</span>
              <span>奖衔</span>
              <span>小区仅需</span>
              <span class="ju_text2">
                {{
                  (awards.targetPv - awards.sumRealPv > 0
                    ? awards.targetPv - awards.sumRealPv
                    : "0.00") | toThousandthAndKeepDecimal
                }}{{ $t("S_C_58") }}
              </span>
            </div>
            <div class="experience" v-else>
              <span class="ju_text1">恭喜您!已达标当月最高奖衔</span>
              <span class="ju_text2">{{ awards.awardsName }}</span>
              <span class="ju_text1">!记得保持哦!</span>
            </div>
          </div> -->

          <!-- <div class="yestDay">
            <div class="yes_t">{{ $t("w_1039") }}</div>
            <div class="schedule">
              <div
                class="current-schedule"
                :style="{
                  width:
                    awards.targetPv == '-1.00' ||
                    awards.targetPv == '-1.0000' ||
                    awards.sumRealPv / awards.targetPv >= 1
                      ? '100%'
                      : GetPercent(awards.sumRealPv, awards.targetPv),
                }"
              ></div>
              <div class="cha" v-if="awards.targetPv > 0">
                {{ awards.sumRealPv }}/{{ awards.targetPv }}万
              </div>
              <div class="cha" v-else>{{ awards.sumRealPv }}万</div>
            </div>
          </div> -->
          <div class="yestDay">
            <div class="yes_t">{{ $t("w_1040") }}</div>
            <div
              :class="
                awards.aNewPv != 0 && awards.bNewPv != 0
                  ? 'schedule1'
                  : 'schedule'
              "
            >
              <div
                class="current-schedule"
                :style="{
                  width: yesPercent(awards.aNewPv, awards.bNewPv),
                }"
              ></div>
              <div class="cha">
                左区 {{ awards.aNewPv }}万/右区 {{ awards.bNewPv }}万
              </div>
            </div>
          </div>
          <div class="yestDay">
            <div class="yes_t">{{ $t("w_0488") }}</div>
            <div
              :class="
                awards.aSumPv != 0 && awards.bSumPv != 0
                  ? 'schedule1'
                  : 'schedule'
              "
            >
              <div
                class="current-schedule"
                :style="{
                  width: yesPercent(awards.aSumPv, awards.bSumPv),
                }"
              ></div>
              <div class="cha">
                左区 {{ awards.aSumPv }}万/右区 {{ awards.bSumPv }}万
              </div>
            </div>
          </div>
          <div class="state">
            {{ $t("S_L_4") }}：<span>{{ userInfo.countryCode }}</span>
          </div>
        </div>
        <!-- <div class="user-card-bg" v-if="userInfo.awardsIcon">
          <img :src="userInfo.awardsIcon" />
        </div> -->
      </div>
    </div>
    <!-- 会员有效!-->
    <div
      class="create-time"
      v-if="userInfo.pkCountry == 1 && userInfo.expireDate"
    >
      有效期：{{ userInfo.expireDate }}
    </div>
    <!-- 注册时间!-->
    <div class="create-time">
      {{ $t("S_L_5") }}：{{ userInfo.registerTime }}
    </div>
    <div class="countDown">
      <div class="divs">{{ userDay }}</div>
      <div class="margin-s">{{ $t("S_L_6") }}</div>
      <div class="divs">{{ userHr }}</div>
      <div class="margin-s">{{ $t("S_L_7") }}</div>
      <div class="divs">{{ userMin }}</div>
      <div class="margin-s">{{ $t("S_L_8") }}</div>
      <div class="divs">{{ userSec }}</div>
      <div class="margin-s">{{ $t("S_L_9") }}</div>
    </div>
    <!-- 荣誉墙!-->
    <div class="celebrity">
      <div class="title">{{ $t("S_L_10") }}</div>
      <div class="celebrity-content">
        <div
          class="celebrity-list"
          v-for="(item, index) in getMemberHonorWallList"
        >
          <img :src="item.img" />
        </div>
        <!--                <div class="celebrity-list"  v-for="item in 6">-->
        <!--                    <img  src="@/assets/images/avatar.png" />-->
        <!--                </div>-->
      </div>
      <div class="more" @click="honorMore">{{ $t("S_L_11") }}>></div>
    </div>
    <!-- 推荐达人!-->
    <div class="recommend">
      <div class="swiper-container swiper-no-swiping">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="title-con">{{ $t("S_L_12") }}</div>
            <div class="screen">
              <div
                class="screen-view"
                @click="screenTaps(index, item)"
                v-for="(item, index) in screenList"
                :style="remIndex == item.id ? 'font-weight:600' : ''"
              >
                {{ item.name }}
              </div>
            </div>
            <div>
              <div class="tjClass" v-for="(item, index) in tjList">
                <div :class="'styleColors' + index">NO.0{{ item.rank }}</div>
                <div class="avatar">
                  <img v-if="item.headPath" :src="item.headPath" />
                  <img v-else src="@/assets/images/avatar.png" />
                </div>
                <div class="user-name">{{ item.memberName }}</div>
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="title-con">{{ $t("S_L_13") }}</div>
            <div class="screen">
              <div
                class="screen-view"
                @click="screenTaps1(index, item)"
                v-for="(item, index) in screenList1"
                :style="remIndex1 == item.id ? 'font-weight:600' : ''"
              >
                {{ item.name }}
              </div>
            </div>
            <div>
              <div class="tjClass" v-for="(item, index) in ksList">
                <div :class="'styleColors' + index">NO.0{{ item.rank }}</div>
                <div class="avatar">
                  <img v-if="item.headPath" :src="item.headPath" />
                  <img v-else src="@/assets/images/avatar.png" />
                </div>
                <div class="user-name">{{ item.memberName }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
        <div class="swiper-button-prev" @click="swiperNext">
          <!--                <img src="图片路径"/>-->
        </div>
        <div class="swiper-button-next" @click="swiperPrev">
          <!--                <img src="图片路径"/>-->
        </div>
      </div>
    </div>
    <!-- 站内信 -->
    <div class="znBox">
      <div class="zntitle">{{ $t("ENU_NOTICE_FUNCTION_TYPE_2") }}</div>
      <div class="znCenter">
        <!--        <div class="znrightword" @click="goMore(2)">查看更多</div>-->
        <div class="znContent">
          <vue-seamless-scroll
            v-if="listData.length > 0"
            :class-option="optionSingleHeightTime"
            :data="listData"
            class="seamless-warp"
          >
            <ul class="item">
              <li v-for="(item, index) in listData" :key="index">
                <span class="title" v-text="item.title"></span>
              </li>
            </ul>
          </vue-seamless-scroll>
          <div class="kongznx" v-else>{{ $t("S_L_14") }}</div>
          <!--                    <vue-seamless-scroll-->

          <!--                        :class-option="optionSingleHeightTime"-->
          <!--                        :data="listData"-->
          <!--                        class="seamless-warp"-->
          <!--                    >-->
          <!--                        <ul class="item">-->
          <!--                            <li v-for="(item, index) in 6" :key="index">-->
          <!--                                <span class="title" v-text="123"></span>-->
          <!--                            </li>-->
          <!--                        </ul>-->
          <!--                    </vue-seamless-scroll>-->
        </div>
        <div class="more" @click="goMore(2)" style="margin: 20px 0 0 0">
          {{ $t("S_L_11") }}>>
        </div>
        <!-- <div class="bottonword">{{ $t('w_0128') }}</div> -->
      </div>
    </div>
    <!-- 意见反馈 -->
    <div class="znBox">
      <div class="zntitle">{{ $t("S_L_15") }}</div>
      <div class="ideaBox">
        <el-form ref="feedbackForm" :model="feedbackForm" label-width="0px">
          <el-form-item prop="tradeType">
            <el-select
              class="ideaselect"
              v-model="feedbackForm.type"
              :placeholder="$t('S_L_16')"
            >
              <el-option
                v-for="(item, index) in feedbackTypeList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="tradeType">
            <el-input
              type="textarea"
              class="ideainput"
              :placeholder="$t('S_L_16')"
              v-model="feedbackForm.idea"
            ></el-input>
          </el-form-item>
          <el-button
            :class="feedbackForm.idea ? 'tjbutton2' : 'tjbutton'"
            @click="submitFeed('feedbackForm')"
            >{{ $t("S_L_17") }}</el-button
          >
        </el-form>
      </div>
    </div>
    <!-- 意见列表 -->
    <!--    <div class="znBox" v-for="(item, index) in feedBackList" :key="index" v-if="index<3">-->
    <!--      <div class="yjheader">-->
    <!--        <el-avatar :size="50" :src="item.headPath"></el-avatar>-->
    <!--        <div class="headertitle">-->
    <!--          <div class="headername">{{ item.memberName }}</div>-->
    <!--          <div class="headertime">{{ item.creationTime }}</div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="yjcontent">{{ item.idea }}</div>-->
    <!--    </div>-->
    <!--    <div class="searchmore" @click="goMore(3)">查看更多>></div>-->
  </div>
</template>

<script>
import * as sid from "@/api/sidebaruserinfo.js";
import { mapGetters } from "vuex";
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
import user from "@/store/modules/user";
import { getMemberHonorWall, getUserAwards } from "@/api/sidebaruserinfo.js";
import { recommend, repurchase } from "@/api";
export default {
  name: "sidebarUserInfo",
  components: { Swiper },
  computed: {
    user() {
      return user;
    },
    ...mapGetters(["userInfo"]),

    optionSingleHeightTime() {
      return {
        step: 0.6, // 数值越大速度滚动越快
        limitMoveNum: this.listData.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 14, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  data() {
    return {
      remIndex: 6, //推荐达人下标
      remIndex1: 9, //抗衰达人下标
      screenList: [
        { name: this.$t("S_L_18"), id: 6 },
        { name: this.$t("S_L_19"), id: 5 },
        { name: this.$t("S_L_20"), id: 4 },
      ],
      screenList1: [
        { name: this.$t("S_L_18"), id: 9 },
        { name: this.$t("S_L_19"), id: 8 },
        { name: this.$t("S_L_20"), id: 7 },
      ],
      countTime: 11183423,
      userDay: 0,
      userHr: 0,
      awards: {},
      queryParams: {
        pageNum: 1,
        pageSize: 5,
      },
      userMin: 0,
      getMemberHonorWallList: [],
      userSec: 0,
      _interval: "",
      toLiveBtn: "",
      isHandImg: true,
      listData: [], //站内信列表
      feedbackTypeList: [], //意见反馈类型列表
      feedbackForm: {
        type: "",
        idea: "",
      },
      feedBackList: [], //意见列表
      tjList: [],
      ksList: [],
      userPrice: 0,
      awardPrice: 0,
      awardsList: [],
    };
  },
  created() {
    this.getData();
    this.getFeedBackList();
    this.getMemberHonorWall();
    this.countdown();
    this.getUserAwardss();

    this.getUserTj();
  },
  mounted() {
    this.swiper = new Swiper(".swiper-container", {
      pagination: ".swiper-pagination",
      loop: false, // 循环
      spaceBetween: 20, // swiper-slide 间的距离为0
      autoplay: 0, // 自动切换时间间隔
      speed: 1000, // 滑动速度
      prevButton: ".swiper-button-prev",
      nextButton: ".swiper-button-next",
    });
    // console.error(time1)
  },
  beforeDestroy() {
    clearInterval(this._interval);
  },
  methods: {
    clickTap() {
      this.$router.push({
        path: "/personal",
        query: {
          id: 5,
        },
      });
    },
    getUserTj() {
      recommend({ wallType: this.remIndex }).then((res) => {
        this.tjList = res.rows;
      });
    },
    getUserKs() {
      repurchase({ wallType: this.remIndex1 }).then((res) => {
        this.ksList = res.rows;
      });
    },
    swiperPrev() {
      this.getUserKs();
    },
    swiperNext() {
      this.getUserTj();
    },
    defaultSrc() {
      this.isHandImg = false;
    },
    yesPercent(left, right) {
      left = parseFloat(left);
      right = parseFloat(right);
      if (isNaN(left) || isNaN(right)) {
        return "-";
      }
      return right + left == 0
        ? "0%"
        : Math.round((left / (right + left)) * 10000) / 100.0 + "%";
    },
    GetPercent(num, total) {
      num = parseFloat(num);
      total = parseFloat(total);
      if (isNaN(num) || isNaN(total)) {
        return "-";
      }
      if (total == 0) {
        return "0%";
      } else if (total < 0) {
        return 100 + "%";
      } else {
        return Math.round((num / total) * 10000) / 100.0 + "%";
      }
    },
    honorMore() {
      this.$router.push({
        path: "/honorList",
      });
    },
    screenTaps(index, item) {
      this.remIndex = item.id;
      this.getUserTj();
    },
    screenTaps1(index, item) {
      this.remIndex1 = item.id;
      this.getUserKs();
    },
    // 倒计时事件
    countdown() {
      const that = this;
      that._interval = setInterval(() => {
        let time = new Date(this.userInfo.registerTime).getTime() / 1000;
        let time1 = new Date().getTime() / 1000;
        let time2 = parseInt(time1 - time);
        that.countTime = time2;
        that.countTime++;
        let day = parseInt(that.countTime / 60 / 60 / 24);
        let hr = parseInt((that.countTime / 60 / 60) % 24);
        let min = parseInt((that.countTime / 60) % 60);
        let sec = parseInt(that.countTime % 60);
        day = day > 9 ? day : "0" + day;
        hr = hr > 9 ? hr : "0" + hr;
        min = min > 9 ? min : "0" + min;
        sec = sec > 9 ? sec : "0" + sec;
        // that.toLiveBtn = `${day}天${hr}时${min}分${sec}秒`
        that.userDay = day;
        that.userHr = hr;
        that.userMin = min;
        that.userSec = sec;
      }, 1000);
    },
    //跳转详情页
    goMore(index) {
      //1公告 2站内信
      this.$router.push({
        path: "/noticLists",
        query: { index: index },
      });
    },
    //提交意见反馈
    submitFeed(formName) {
      if (this.feedbackForm.type == "" || this.feedbackForm.type == undefined) {
        this.$message({
          message: this.$t("w_0291"),
          type: "error",
        });
        return;
      }
      if (this.feedbackForm.idea == "" || this.feedbackForm.idea == undefined) {
        this.$message({
          message: this.$t("w_0292"),
          type: "error",
        });
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm(this.$t("w_0293") + "?", "", {
            confirmButtonText: this.$t("w_0035"),
            cancelButtonText: this.$t("ENU_P_TYPE0"),
            type: "warning",
          }).then((_) => {
            sid.saveFeedBack(this.feedbackForm).then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: this.$t("MY_CK_35"),
                  type: "success",
                });
                this.$refs.feedbackForm.clearValidate();
                this.getFeedBackList();
                this.feedbackForm = { type: "", idea: "" };
              }
            });
          });
        }
      });
    },
    //获取用户的荣誉墙
    getMemberHonorWall() {
      sid.getMemberHonorWall(this.queryParams).then((res) => {
        this.getMemberHonorWallList = res.data;
      });
    },
    //获取用户真实奖衔
    getUserAwardss() {
      sid.getUserAwards().then((res) => {
        this.awards = res.data;

        // this.userPrice =0
        // this.awardPrice=0
        //
        //
        // awards.targetPv>'0.00'?(awards.sumRealP
      });
    },
    //意见反馈列表
    getFeedBackList() {
      sid.getFeedbackList().then((res) => {
        this.feedBackList = res.rows;
      });
    },
    getData() {
      //functionType 公告1 站内信2
      sid.getNoticeList({ functionType: 2 }).then((res) => {
        this.listData = res.rows;
      });
      sid.getFeedbackType().then((res) => {
        this.feedbackTypeList = res.data;
      });
      sid.memberAwards().then((res) => {
        this.awardsList = res.data;
      });
    },
    goRouter(index) {
      //1等级2奖衔
      this.$router.push({
        path: "/roadtoGrowth",
        query: { index: index },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .swiper-button-next {
  width: 30px;
  height: 30px;
}
::v-deep .swiper-button-prev {
  width: 30px;
  height: 30px;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}
.avatar {
  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-top: 10px;
    padding: 0 15px;
  }
}
.user-name {
  color: #333;
  font-size: 18px;
  //width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.styleColors0 {
  color: #f64e49;
  font-size: 14px;
}
.styleColors1 {
  color: #85b9fe;
  font-size: 14px;
}
.styleColors2 {
  color: #6be8b0;
  font-size: 14px;
}
.tjClass {
  display: flex;
  //padding: 0 25px;
  //justify-content: center;
  align-items: center;
  padding-left: 20px;
}
.screen {
  display: flex;
  padding: 0 0 0 33px;
  .screen-view {
    margin-left: 21px;
    cursor: pointer;
  }
}
.title-con {
  color: #333;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin: 5px 0 15px 0;
}
.swiper-slide-active {
}
::v-deep .swiper-pagination-bullet {
}
::v-deep .swiper-pagination-bullet-active {
  background: #d61820;
  width: 14px;
  height: 6px;
  background: #d61820;
  border-radius: 10px 10px 10px 10px;
}
.swiper-container {
  width: 364px;
  height: 343px;
  //margin: 20px auto;
}
.swiper-slide:nth-child(2) {
  margin-left: 120px !important;
}
.swiper-slide {
  width: 262px !important;
  margin: 20px auto auto 55px !important;
  height: 285px !important;
  padding-bottom: 20px;
  //height: 303px;
  background: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2),
    inset 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px 10px 10px 10px;
}
.recommend {
  margin: 20px 0;
  height: 340px;
  background: #fff;
  border-top: 5px solid rgba(0, 0, 0, 0.05);
}
.more {
  color: #41b2ff;
  font-size: 16px;
  cursor: pointer;
}
::v-deep .el-input--suffix .el-input__inner {
  background: #fff !important;
}
::v-deep .el-input--suffix .el-input__inner {
  background: #fff !important;
}
.seamless-warp {
  //height: 196px;
  overflow: hidden;
  line-height: 14px;
  text-align: left;
  font-size: 12px;
  .item {
    margin: 0;
    text-align: center;
    margin-left: -40px;
    li {
      list-style: none;
    }
    .title {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.sidebarWai {
  width: 380px;
  padding: 20px 0;
  height: 1855px;
  background: #ffffff;
  box-shadow: 5px 5px 20px 0px rgba(233, 233, 233, 0.5);
  border-radius: 10px 10px 10px 10px;
  //height: fit-content;
  opacity: 1;
  margin-right: 20px;
  .sidebarTop {
    margin-top: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .sidebarHeader {
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
    }
    .nameCountry {
      margin-top: 20px;
      display: flex;
      align-content: center;
      align-items: center;
      .username {
        font-size: 22px;
        margin-right: 12px;
      }
      img {
        width: 39px;
        height: 26px;
      }
    }
    .gradeClass {
      display: flex;
      width: 200px;
      margin-top: 20px;
      align-items: center;
      justify-content: space-around;
      img {
        width: 55px;
        //height: 45px;
        cursor: pointer;
      }
    }
  }
  .create-time {
    margin: 10px auto 10px auto;
    color: #666;
    font-size: 14px;
    text-align: center;
  }
  .countDown {
    display: flex;
    padding: 0 40px;
    line-height: 40px;
    font-size: 14px;
    color: #666;
    .margin-s {
      margin: 0 10px;
    }
    .divs {
      padding: 0 10px;
      //width: 40px;
      height: 40px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px solid #979797;
      color: #c8161d;
      font-size: 18px;
      text-align: center;
      line-height: 40px;
    }
  }
  .celebrity {
    text-align: center;
    .celebrity-content {
      display: flex;
      height: 180px;
      flex-wrap: wrap;
      margin-left: -40px;
      //  height: 135px;
      //padding: 0 30px 0 0;
      justify-content: center;
      //align-items: center;
      .celebrity-list {
        margin-bottom: 20px;
        img {
          width: 66px;
          height: 66px;
          margin-left: 40px;
        }
      }
    }
    .title {
      color: #333;
      font-size: 24px;
      font-weight: 600;
      margin: 30px 0 15px 0;
    }
  }
  .awardscard {
    width: 346px;
    height: 138px;
    margin: 0 auto;
    margin-top: 10px;
    background: url("~@/assets/images/id-card-bg.png") no-repeat;
    background-size: 100% 100%;
    //background: linear-gradient(180deg, #f8d869 0%, #f48b1f 100%);
    border-radius: 10px;
    position: relative;
    .user-cards {
      cursor: pointer;
      // width: 316px;
      height: 168px;
      padding: 1px 10px;
      // position: absolute;
      z-index: 1;
      // display: flex;
      .user-card-bg {
        position: absolute;
        right: 0;
        top: 0;
        img {
          width: 145px;
          height: 145px;
        }
      }
      .user-cards-left {
        //width: 160px;
        // padding: 5px 0;
        .rank {
          font-size: 14px;
          color: #fff;
          margin: 5px 10px;
          display: flex;
          justify-content: space-between;
        }
        .lines {
          height: 1px;
          background: #ffffff;
          margin-top: 10px;
        }
        .experience {
          font-size: 8px;
          margin: 15px 0 0 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 0px;
          flex-wrap: wrap;
          color: #fff;
          span {
            font-size: 12px;
          }
          .ju_text1 {
            font-weight: 400;
          }

          .ju_text2 {
            color: #fb3024;
            font-weight: bold;
            font-size: 12px;
            padding: 0 3px;
          }
        }
        .schedule {
          // width: 149px;
          flex: 1;
          height: 16px;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 10px 10px 10px 10px;
          margin: 5px;
          position: relative;
        }
        .schedule1 {
          // width: 149px;
          flex: 1;
          height: 16px;
          background: #fff;
          border-radius: 10px 10px 10px 10px;
          margin: 5px;
          position: relative;
        }
        .current-schedule {
          position: absolute;
          width: 30%;
          background: #fea43c;
          height: 16px;
          border-radius: 10px 10px 10px 10px;
        }
        .yestDay {
          display: flex;
          align-items: center;
          margin-top: 5px;
        }
        .yes_t {
          margin-right: 10px;
          font-size: 12px;
          color: #fff;
        }
        .cha {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 12px;
          white-space: nowrap;
        }
        .state {
          font-size: 10px;
          color: #fff;
          margin: 10px 0 0 0px;
          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .znBox {
    margin-top: 20px;
    border-top: 5px solid rgba(0, 0, 0, 0.05);
    // border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    padding: 20px;
    text-align: center;
    .zntitle {
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .znCenter {
      // background: red;
      height: 180px;
      padding: 20px;
      .znrightword {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #41b2ff;
        text-align: right;
        cursor: pointer;
      }
      .znContent {
        height: 180px;
        overflow: hidden;
        // padding: 20px;
        .kongznx {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
      .bottonword {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: right;
        margin-top: 10px;
      }
    }
  }
  .ideaBox {
    //padding-top: 50px;
    padding: 20px 10px 0px 10px;
    .ideainput {
      width: 260px;
    }
    .tjbutton {
      background: #cccccc;
      border-radius: 5px 5px 5px 5px;
      width: 163px;
      font-size: 18px;
      height: 48px;
      color: #ffffff;
    }
    .tjbutton2 {
      background: #d61820;
      border-radius: 5px 5px 5px 5px;
      width: 163px;
      font-size: 18px;
      height: 48px;
      color: #ffffff;
    }
  }
  .yjheader {
    display: flex;
    text-align: left;
    padding: 0 20px;
    align-items: center;
    .headertitle {
      margin-left: 20px;
      .headername {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
      }
      .headertime {
        margin-top: 5px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #909090;
      }
    }
  }
  .yjcontent {
    padding: 0 20px;
    text-align: left;
    margin-top: 24px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    display: block;
    width: 260px;
    word-break: break-all;
  }
  .searchmore {
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #41b2ff;
    cursor: pointer;
  }
}
.yongyu_t {
  width: 100%;
}
.yongyu {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.yt1 {
  font-weight: 600;
  font-size: 18px;
  margin-left: 10px;
}
.hisImg {
  width: 40px;
  height: 40px;
  margin-left: 10px;
}
.yongyu1 {
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 0px 0;
}
.jxTit {
  flex: 1;
}
.jxTit1 {
  font-weight: 600;
  font-size: 18px;
}
::v-deep .ideaselect .el-input__inner {
  width: 260px;
  border-radius: 6px 6px 6px 6px;
  //background: rgba(0, 0, 0, 0.05) !important;
}

::v-deep .ideainput .el-textarea__inner {
  width: 260px;
  height: 160px;
  border-radius: 10px 10px 10px 10px;
  //background: rgba(0, 0, 0, 0.05) !important;
}
</style>
