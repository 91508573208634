<!--
 * @Descripttion: 会员协议
 * @version:
 * @Author: kBank
 * @Date: 2022-07-12 14:47:45
-->
<template>
  <div class="agreementBox flex_ac">
    <!-- <van-checkbox
      class="agreementIcon"
      v-model="toShow"
      @click="toCheck"
      checked-color="#72deed"
    >
    </van-checkbox> -->
<!--    <div class="agreementIcon">-->
<!--      <el-checkbox v-model="toShow"-->
<!--                   @change="toCheck">-->
<!--      </el-checkbox>-->
<!--      <div class="agreementText flex">-->
<!--        <span v-if="userInfo.pkCountry==1">-->
<!--          <span>请阅读并同意</span>-->
<!--          <span class="agreementDetail"-->
<!--                @click="popupShow = true">《购买协议》</span>-->
<!--          <span class="agreementDetail"-->
<!--                v-for="item,index in agreeData.agreement" v-if="item.agreementTitle"-->
<!--                @click="openIsqyj(item.agreementTitle)">《{{item.agreementTitle}}》</span>-->
<!--        </span>-->
<!--        <span class="agreementDetail"-->
<!--              @click="popupShow = true"-->
<!--              v-else>《KODE ETIK BAGI MITRA USAHA》</span>-->
<!--      </div>-->
<!--    </div>-->
    <el-dialog class="pop"
               @close='handleCancle'
               :visible.sync="popupShow">
      <div class="pop_box">
        <div class="pop_title2 pop_title1">
          KODE ETIK BAGI MITRA USAHA PT HZS INTERNATIONAL INDONESIA (HZS)
        </div>
        <h4>
          Kode Etik dan Peraturan Perusahaan ini adalah panduan dan peraturan
          baku yang berlaku bagi semua pelaku usaha Penjualan Langsung PT HZS
          INTERNATIONAL INDONESIA (selanjutnya disebut sebagai Mitra Usaha)
          dalam menjalankan bisnisnya .
        </h4>
        <h4>
          Dengan diterimanya pendaftaran pelaku usaha Penjualan Langsung oleh
          HZS, maka Mitra Usaha setuju untuk terikat dan tunduk kepada Kode Etik
          dan Peraturan ini dan bersifat mengikat antara Mitra Usaha dan
          Perusahaan (HZS). Kode Etik dan Peraturan ini menjadi satu-satunya
          ketentuan dan berlaku sampai dengan keanggotaan Mitra Usaha berakhir.
        </h4>
        <div class="pop_title2">BAB I</div>
        <div class="pop_title1">Definisi Umum</div>
        <h4>
          1.PT HZS INTERNATIONAL INDONESIA adalah suatu perseroan terbatas yang
          didirikan berdasarkan hukum Negara Republik Indonesia yang
          berkedudukan di Jakarta Barat.
        </h4>
        <h4>
          2.Mitra Usaha adalah anggota mandiri perseorangan yang terdaftar dalam
          jaringan pemasaran atau penjualan HZS yang telah mendapatkan
          persetujuan dari Perusahaan untuk menjadi Mitra Usaha melalui ajakan
          seorang sponsor.
        </h4>
        <h4>
          3.Mitra Usaha HZS bukanlah karyawan/staf PT HZS INTERNATIONAL
          INDONESIA. Seluruh karyawan PT HZS INTERNATIONAL INDONESIA dilarang
          mendaftar sebagai Mitra Usaha HZS.
        </h4>
        <h4>
          4.Sponsor adalah, MITRA yang memperkenalkan usaha HZS kepada calon
          MITRA dan kemudian secara resmi menjadi MITRA HZS.
        </h4>

        <h4>
          5.Jaringan keanggotaan adalah,semua MITRA yang menjalankan usaha HZS
          dan dalam kelompok MITRA yang bersangkutan.
        </h4>

        <h4>
          6.Konsumen adalah, MITRA pemakai produk dan pembeli akhir dari produk
          HZS dengan tujuan dipakai sendiri.
        </h4>

        <h4>
          7.Up line adalah,”atasan” MITRA atau atasannya lagi dan seterusnya
          lagi keatas.
        </h4>

        <h4>
          8.Down line adalah,MITRA dibawah Up Line,dibawahnya dan seterusnya ke
          bawah.
        </h4>

        <h4>
          9.Komisi adalah imbalan yang diberikan oleh Perusahaan kepada Mitra
          usaha yang besarnya dihitung berdasarkan hasil kerja nyata, sesuai
          volume atau nilai hasil penjualan barang, baik secara pribadi maupun
          jaringannya.
        </h4>
        <h4>
          10.Bonus atas adalah tambahan imbalan yang diberikan oleh perusahaan
          kepada mitra usaha, karena berhasil melebihi target penjualan barang
          yang ditetapkan perusahaan.
        </h4>
        <h4>
          11.Marketing Plan adalah program pemasaran yang dibuat oleh Perusahaan
          untuk mengatur perhitungan komisi, dan untuk memperoleh bonus atau
          keuntungan lainnya sesuai dengan persyaratan yang akan dicapai oleh
          Mitra Usaha dalam memasarkan produk dan atau mengembangkan jaringan.
        </h4>

        <h4>
          12.Kode Etik berarti peraturan ini, adalah suatu tatanan aturan yang
          mengikat dan menjadi pedoman bagi MITRA dalam menjalankan usaha HZS
          sejak MITRA tersebut tercatat secara resmi sebagai MITRA HZS.
        </h4>

        <h4>
          13.Produk adalah barang yang disediakan secara resmi oleh Perusahaan
          untuk dipergunakan sendiri oleh Mitra Usaha atau dijual kepada
          Konsumen dengan sistem Penjualan Langsung.
        </h4>
        <div class="pop_title2">BAB II</div>
        <div class="pop_title1">KEANGGOTAAN HZS</div>
        <h3>A.PENDAFTARAN & PERSYARATAN KEANGGOTAAN</h3>
        <h4>
          1.Pendaftaran keanggotaan dapat dilakukan oleh setiap orang dewasa
          yang berusia minimal 18 tahun, memiliki KTP, dan atau telah menikah
          bisa menjadi Mitra Usaha HZS.
        </h4>
        <h4>
          2.Setiap calon Mitra Usaha HZS wajib mengisi dan melengkapi formulir
          registrasi HZS dengan benar dan valid. Setiap Mitra Usaha HZS
          bertanggung jawab atas kebenaran isi data dirinya. PT HZS
          INTERNATIONAL INDONESIA akan dibebaskan dari tanggung jawab jika isi
          pendaftaran tersebut tidak benar.
        </h4>
        <h4>
          3.Pendaftaran dapat dilakukan dengan cara mengisi dan melengkapi
          formulir registrasi & bisa secara online lewat aplikasi , yang perlu
          dilengkapi dengan:
        </h4>
        <h4>a.Nomor ID (NIK/KTP)</h4>
        <h4>b.Nama Lengkap sesuai ID/KTP</h4>
        <h4>c.Jenis Kelamin & Tanggal Lahir</h4>
        <h4>d.Nomor Telepon & Email</h4>
        <h4>e.Alamat Sesuai ID/KTP</h4>
        <h4>f.Nomor NPWP Pribadi</h4>
        <h4>g.Data Rekening Pribadi</h4>
        <h4>
          4.Biaya pendaftaran gratis, mitra dapat mendownload e-Starterkit yang
          berisi : Brosur Produk, Marketing Plan, Kode Etik, Formulir
          Pendaftaran, Username & Password untuk akses ke web mitra.
        </h4>
        <h4>
          5.Perusahaan hanya mengakui alamat Mitra Usaha HZS sesuai yang
          tercantum pada Formulir Pendaftaran, kecuali terdapat perubahan alamat
          yang disahkan oleh Perusahaan.
        </h4>
        <h4>
          6.Setiap calon Mitra Usaha HZS yang mendaftar, harus memiliki rekening
          di bank sebagaimana yang tercantum dalam formulir registrasi HZS. Demi
          keamanan, semua komisi dan bonus akan ditransfer melalui rekening bank
          dan tidak bisa diambil secara tunai di kantor, PT HZS INTERNATIONAL
          INDONESIA tidak bertanggung jawab atas kesalahan pengisian data dan
          nomor rekening bank oleh Mitra Usaha.
        </h4>
        <h4>
          7.Semua pembayaran/transaksi calon Mitra Usaha HZS kepada HZS
          dinyatakan sah apabila dilakukan melalui transfer ke rekening HZS
          (atas nama PT HZS INTERNATIONAL INDONESIA) yang telah ditentukan, atau
          pembayaran langsung dengan bukti struk pembayaran yang disahkan oleh
          petugas PT HZS INTERNATIONAL INDONESIA. Pembayaran yang dilakukan
          tidak memenuhi ketentuan di atas adalah tidak sah dan HZS tidak
          bertanggungjawab apabila terjadi kerugian akibat transaksi tersebut.
        </h4>
        <h4>
          8.Mitra Usaha HZS bukan merupakan cabang, agen, pegawai, afiliasi
          ataupun kelompok dalam usaha patungan dari badan hukum PT HZS
          INTERNATIONAL INDONESIA, akan tetapi sebagai Pribadi yang lndependen
          atau Mitra Usaha Mandiri.
        </h4>
        <h3>B.NOMOR KEANGGOTAAN</h3>
        <h4>
          1.Peraturan mengenai keanggotaan suami istri akan diatur dalam Pasal
          tersendiri dalam Kode Etik ini.
        </h4>
        <h4>
          2.Keanggotaan Mitra Usaha berlaku 1 (satu) tahun dan dapat
          diperpanjang setiap tahunnya secara gratis dengan memiliki akumulasi
          pembelanjaan pribadi sebesar minimal 55PV sebelum masa keanggotaan
          berakhir.
        </h4>
        <h4>
          3.Dalam hal Mitra Usaha yang bersangkutan tidak memenuhi akumulasi
          pembelanjaan pribadi tersebut, maka Mitra Usaha tersebut dianggap
          tidak memperpanjang keanggotaannya dan bisa mendaftar kembali sebagai
          Mitra Usaha baru di kemudian hari.
        </h4>
        <h3>C.PERUBAHAN INFORMASI KEANGGOTAAN</h3>
        <h4>
          1.Perubahan informasi data keanggotaan dapat dilakukan dengan cara
          menghubungi Kantor Pusat PT HZS INTERNATIONAL INDONESIA bagian
          customer service, melalui email ataupun surat menyurat .
        </h4>
        <h4>
          2.Pengalihan identitas keanggotaan ataupun pertukaran keanggotaan dari
          satu jaringan ke jaringan lain tidak diperbolehkan dengan alasan
          apapun, kecuali diwariskan karena Mitra meninggal
        </h4>
        <h4>
          3.Apabila ditemukan identitas keanggotaan yang tidak jelas, atau
          terdapati keanggotaan ganda yang bisa merugikan pihak lain, maka
          perusahaan berhak menonaktifkan nomor keanggotaan yang terakhir
          didaftarkan dan secara otomatis kehilangan semua hak sebagai anggota
          HZS.
        </h4>
        <h4>
          4.Apabila ada laporan dengan bukti otentik bahwasanya Mitra Usaha HZS,
          baik dengan nama dia sendiri ataupun dengan nama lain telah mencoba
          bergabung kembali di group jaringan yang lain atau cross line tanpa
          persetujuan dari semua upline dan sponsornya, maka perusahaan akan
          menonaktifkan nomor keanggotaan tersebut tanpa kompensasi.
        </h4>

        <h3>D.KEANGGOTAAN SUAMI ISTRI</h3>
        <h4>
          1.Keanggotaan suami istri dianggap terpisah dan harus berada dalam
          satu garis sponsorisasi yang sama. Apabila melanggar, maka nomor
          keanggotaan yang terbaru akan dihapuskan oleh perusahaan tanpa
          terkecuali dan tanpa kompensasi apapun dari perusahaan.
        </h4>
        <h4>
          2.Apabila di kemudian hari terdapat dua orang Mitra Usaha HZS dari
          jaringan yang berbeda memutuskan untuk menikah, maka keanggotaan
          mereka tetap ada di dalam jaringan yang terpisah dan independen.
        </h4>
        <h4>
          3.Apabila di kemudian hari terdapat keanggotaan Mitra Usaha HZS yang
          merupakan pasangan nikah memilih untuk bercerai, maka PT HZS
          INTERNATIONAL INDONESIA akan tetap mempertahankan keanggotaan sesuai
          dengan aplikasi yang sudah ditandatangani di awal sampai ada keputusan
          pengadilan yang menyebutkan sebaliknya.
        </h4>
        <div class="pop_title2">BAB III</div>
        <div class="pop_title1">HAK DAN KEWAJIBAN ANGGOTA</div>
        <h3>A.HAK MITRA USAHA HZS</h3>
        <h4>
          1.Mitra Usaha HZS berhak mendapatkan penjelasan yang benar baik dalam
          hal informasi mengenai Perusahaan, Produk, Marketing Plan HZS, maupun
          promosi yang diadakan oleh Perusahaan.
        </h4>
        <h4>
          2.Mitra Usaha HZS berhak mendapatkan kesempatan yang sama untuk
          berprestasi dalam melakukan penjualan produk dan mendapatkan komisi
          sesuai Marketing Plan yang ditetapkan.
        </h4>
        <h4>
          3.Mitra Usaha HZS berhak memperoleh penghasilan uang, komisi, bonus,
          cashback, dan bonus rewards dari bisnis HZS berdasarkan acuan serta
          perhitungan di Marketing Plan HZS berdasarkan hasil kerja dan memenuhi
          ketentuan sesuai yang disyaratkan.
        </h4>
        <h4>
          4.Mitra Usaha HZS berhak mendapatkan produk yang bermutu sesuai dengan
          manfaat dan harga yang dibayarkan kepada Perusahaan.
        </h4>
        <h4>
          5.Mitra Usaha HZS berhak mengikuti semua kegiatan, pelatihan
          perusahaan, serta promosi sesuai dengan ketentuan Perusahaan.
        </h4>

        <h3>B.KEWAJIBAN MITRA USAHA HZS</h3>
        <h4>
          1.Mengikuti dan mematuhi semua prosedur, peraturan dan Kode Etik yang
          di tetapkan PT HZS INTERNATIONAL INDONESIA.
        </h4>
        <h4>
          2.Selalu menjaga nama baik PT HZS INTERNATIONAL INDONESIA dan tidak
          merugikan orang lain.
        </h4>
        <h4>
          3.Bersikap sopan, menghargai, menghormati dan menjunjung tinggi
          kejujuran, integritas sebagai Mitra Usaha HZS.
        </h4>
        <h4>
          4.Setiap Mitra Usaha HZS wajib menjaga kerahasiaan data loginnya
          sendiri, yaitu username dan passwordnya. Dan perusahaan tidak
          bertanggung jawab atas kelalaian yang terjadi pada Anggota tersebut.
        </h4>
        <div class="pop_title2">BAB IV</div>
        <div class="pop_title1">LARANGAN BAGI MITRA USAHA HZS</div>
        <h4>
          1.Mitra Usaha HZS dilarang memberikan keterangan menyesatkan atau over
          claim kepada khalayak ramai, dan atau informasi yang bertentangan
          dengan kebijakan atau literatur resmi dari PT HZS INTERNATIONAL
          INDONESIA.
        </h4>
        <h4>
          2.Mitra Usaha HZS dilarang menjual dengan cara paksaan atau cara lain
          yang dapt menimbulkan gangguan pada pihak lain baik secara fisik
          maupun psikis.
        </h4>
        <h4>
          3.Mitra Usaha HZS dilarang membujuk calon Mitra Usaha lain ataupun
          konsumen untuk membeli atau menimbun produk yang melebihi
          kebutuhannya.
        </h4>
        <h4>
          4.Mitra Usaha HZS dilarang menggunakan jaringan kerja PT HZS
          INTERNATIONAL INDONESIA untuk pemasaran produk-produk Penjualan
          Langsung (direct selling atau multi level marketing) lain.
        </h4>
        <h4>
          5.Mitra Usaha HZS dilarang mengganti kemasan produk ataupun merubah
          jumlah atau isi dari paket produk resmi yang sudah ditetapkan oleh PT
          HZS INTERNATIONAL INDONESIA, karena tindakan ini dapat merusak dan
          merugikan.
        </h4>
        <h4>
          6.Mitra Usaha HZS dilarang mengurangi ataupun menambah kompensasi
          program diluar yang sudah ditetapkan oleh PT HZS INTERNATIONAL
          INDONESIA. Apabila Mitra Usaha HZS ingin membuat program sendiri
          dengan tujuan ingin meningkatkan penjualan atau jaringannya, maka yang
          bersangkutan tidak diperbolehkan menggunakan logo/ kata-kata PT HZS
          INTERNATIONAL INDONESIA ataupun afiliasinya di dalam program tersebut.
        </h4>
        <h4>
          7.Mitra Usaha HZS dilarang menjual atau mengedarkan produk yang sudah
          tidak layak pakai atau tidak layak konsumsi.
        </h4>
        <h4>
          8.Mitra Usaha HZS dilarang menjual produk dibawah harga yang sudah
          ditetapkan resmi oleh PT HZS INTERNATIONAL INDONESIA. Apabila terjadi
          pelanggaran dan terdapat pelaporan dengan bukti yang cukup dan
          otentik, maka perusahaan berhak untuk memberikan surat peringatan
          hingga memberhentikan keanggotaannya, dan tanpa disertai kompensasi
          apapun.
        </h4>
        <h4>
          9.Mitra Usaha HZS dilarang menggunakan tulisan, lambang dan merek
          dagang HZS dan afiliasinya untuk brosur atau alat bantu jual lainnya
          untuk sesuatu yang tidak benar atau tidak sesuai.
        </h4>
        <h4>
          10.Mitra Usaha HZS dilarang bertindak untuk dan atas nama perusahaan,
          mewakili perusahaan melakukan pengikatan hukum dengan pihak lain
          ataupun mewakili seolah-olah dirinya adalah bagian dari struktur atau
          karyawan dari perusahaan.
        </h4>
        <h4>
          11.Mitra Usaha HZS dilarang mengklaim diri atau jaringannya untuk
          seolah-olah menguasai atau mempunyai wilayah usaha tertentu secara
          monopoli di bisnis ini.
        </h4>
        <h4>
          12.Mitra Usaha HZS dilarang memperkenalkan atau mensponsori
          staff/pegawai PT HZS INTERNATIONAL INDONESIA, termasuk orang tuanya
          dan saudara sedarah dari staff/pegawai tersebut. Apabila hal itu
          dilakukan, maka perusahaan akan menghapus keanggotaan dari yang telah
          disponsorinya, perusahaan juga berhak untuk memberhentikan Mitra Usaha
          yang melanggar tersebut dari HZS.
        </h4>
        <h4>
          13. Mitra Usaha HZS dilarang untuk berjualan produk-produk HZS melalui
          toko retail atau di lokasi eceran tetap, dan situs E-commerce atau
          Market Place seperti Shopee, Lazada, Matahari Mall, Tokopedia,
          Bukalapak, OLX, Qool0, Supermarket, dan usaha sejenis lainnya.
        </h4>
        <div class="pop_title2">BAB V</div>
        <div class="pop_title1">BERAKHIRNYA KEANGGOTAAN</div>
        <h4>
          1.Masa keanggotaan seorang di HZS dinyatakan batal atau berakhir
          apabila:
        </h4>
        <h4>
          a.Mitra Usaha yang bersangkutan tidak melakukan perpanjangan
          keanggotaan seperti pada Bab II Bagian B angka 2.
        </h4>
        <h4>
          b.Mitra Usaha yang bersangkutan membatalkan dengan cara mengundurkan
          diri terlebih dahulu dengan menyampaikan permohonan tertulis kepada
          HZS.
        </h4>
        <h4>
          c.Dihentikan keanggotaannya oleh karena terbukti melakukan pelanggaran
          Kode Etik yang telah di tentukan oleh HZS.
        </h4>
        <h4>
          d.Dihentikan keanggotaannya karena ada keputusan atau perintah dari
          Pengadilan.
        </h4>
        <h4>
          e.Dihentikan karena adanya perubahan peraturan perundang-undangan yang
          berlaku di INDONESIA.
        </h4>
        <h4>
          2.Mitra Usaha HZS yang telah membatalkan keanggotaannya tersebut,
          selanjutnya akan melepas segala bentuk keterkaitan dari seluruh
          jaringannya yang lama, termasuk terhadap seluruh komisi dan bonus
          serta pengumpulan pointnya jika ada, dan tidak ada kompensasi apapun
          yang akan digantikan oleh Perusahaan terhitung setelah tanggal
          pemberhentian.
        </h4>
        <h4>
          3.Seorang anggota atau Mitra Usaha yang sudah dibatalkan
          keanggotaannya, bisa mendaftar lagi setelah lewat dari 90 (sembilan
          puluh) hari, terkecuali yang bersangkutan termasuk dalam pelanggaran
          berat.
        </h4>
        <h4>
          4.Pelanggaran berat yang dimaksudkan dalam poin diatas adalah jika
          terlibat kasus pidana berat semisal pembunuhan, korupsi, penipuan,
          maupun terorisme, dan sebagainya dalam status yang sudah disangkakan
          maupun yang berketetapan hukum.
        </h4>
        <h4>
          5.Perusahaan tidak memberikan toleransi atas Pembajakan Jaringan atas
          seorang anggota lain yang keanggotaannya masih berlaku, jika ada
          seorang anggota mendaftarkan kembali keanggotaannya dengan menggunakan
          upline yang lain, baik karena kemauan sendiri maupun karena
          dipengaruhi orang lain, maka keanggotaan yang baru akan dibatalkan
          beserta seluruh haknya akan hangus pada keanggotaan baru tersebut.
        </h4>
        <div class="pop_title2">BAB VI</div>
        <div class="pop_title1">JAMINAN KEPUASAN</div>
        <h4>
          1.Jaminan Kepuasan (Satisfaction Guarantee), Mitra Usaha HZS berhak
          untuk melakukan penukaran produk yang telah dibeli dalam waktu 7
          (tujuh) hari, apabila ternyata mutu produk yang disampaikan tidak
          sesuai dengan yang diklaim atau dijanjikan secara tertulis oleh
          Perusahaan (sesuai yang terdapat dalam brosur/katalog resmi
          Perusahaan). Penukaran bisa dilakukan dengan melampirkan Nota
          Penjualan Resmi. Perusahaan akan menukarkan dengan produk yang sama,
          dan segala biaya pengiriman menjadi tanggungan Mitra Usaha.
        </h4>
        <h4>
          2.Apabila terbukti bahwa produk HZS yang sudah digunakan atau
          dimanfaatkan sesuai ketentuan menimbulkan kerugian fisik bagi Mitra
          Usaha, maka Perusahaan akan memberikan ganti rugi atau kompensasi atas
          kerugian tersebut dengan selayaknya. Ganti rugi tidak berlaku apabila
          kerugian yang timbul adalah akibat kelalaian dari Mitra Usaha atau
          akibat dari penggunaan produk yang tidak sesuai ketentuan.
        </h4>
        <div class="pop_title2">BAB VII</div>
        <div class="pop_title1">STATUS KEMATIAN DAN WARIS</div>
        <h4>
          1.Mitra Usaha HZS hanya dapat mewariskan hak-haknya yang melekat
          sebagai anggota kepada ahli warisnya yang sah apabila Mitra Usaha yang
          bersangkutan meninggal dunia.
        </h4>
        <h4>
          2.Ahli waris yang sah ditentukan oleh Mitra Usaha sendiri atau
          berdasarkan Hukum Waris dalam Undang-undang yang berlaku, dan atau
          melalui ketetapan Pengadilan. Perusahaan tidak berhak menentukan ahli
          waris dari Mitra Usaha yang bersangkutan.
        </h4>
        <h4>
          3.Ahli waris sah yang akan menggantikan keanggotaan Mitra Usaha
          Pewaris harus memenuhi prosedur yang ditetapkan oleh Perusahaan antara
          lain :
        </h4>
        <h4>a.Melampirkan surat wasiat atau surat keterangan kematian</h4>
        <h4>
          b.Melampirkan pernyataan dan ahli waris yang lain bila ada, yang
          isinya berupa persetujuan pewarisan keanggotaan tersebut yang
          dilampirkan diatas materai minimal Rp. 10.000 (enam ribu rupiah).
        </h4>
        <h4>c.Melampirkan fotocopy kartu keluarga terakhir.</h4>
        <h4>
          d.Mengisi form pendaftaran baru atas nama ahli waris dan diserahkan
          kepada Perusahaan.
        </h4>
        <h4>
          4.Apabila terjadi sengketa oleh pihak lain perihal kewarisan ini maka
          PT. HZS akan mengikuti keputusan akhir dari pengadilan. Selama dalam
          proses penyelesaian sengketa tersebut keanggotaan akan ditangguhkan
          sementara sampai mendapat keputusan hukum yang tetap dan komisi dan
          bonusnya akan ditahan sementara, dan akan diberikan dikemudian hari
          kepada ahli waris yang sah menurut pengadilan.
        </h4>
        <div class="pop_title2">BAB VIII</div>
        <div class="pop_title1">HAK DAN KEWAJIBAN PERUSAHAAN</div>
        <h3>A.Hak Perusahaan</h3>
        <h4>
          1.Perusahaan berhak menerima atau menolak permohonan menjadi anggota
          yang disampaikan melalui formulir pendaftaran Mitra Usaha HZS yang
          diisi oleh calon Mitra Usaha secara benar dan jujur.
        </h4>
        <h4>
          2.Demi perlindungan atas usahanya, Perusahaan berhak melakukan segala
          tindakan yang sesuai hukum atas pelanggaran yang dilakukan oleh Mitra
          Usaha HZS dalam menjalankan usahanya yang dinilai tidak mematuhi
          kebijakan-kebijakan yang telah digariskan Perusahaan.
        </h4>
        <h4>
          3.Perusahaan berhak menghentikan keanggotaan atau kerjasama bisnis
          dengan Mitra Usaha HZS dengan pemberitahuan tertulis sebelumnya.
        </h4>
        <h4>
          4.Perusahaan berhak mengadakan perubahan dan penyesuaian atas
          Marketing Plan, Promosi, dan Kode Etik dan Peraturan Perusahaan,
          dengan persetujuan dari Kementerian Perdagangan Republik Indonesia,
          dan di sosialisasikan kepada seluruh anggota selambat-lambatnya 30
          (tiga puluh) hari sebelum diberlakukan.
        </h4>

        <h3>B.Kewajiban Perusahaan</h3>
        <h4>
          1.Perusahaan berkewajiban memberikan informasi yang jelas dan benar
          kepada Mitra Usaha HZS berkaitan dengan usahanya, Marketing Plan,
          produk dan semua hal yang terkait dengan kegiatannya.
        </h4>
        <h4>
          2.Perusahaan berkewajiban mematuhi aturan yang mengacu pada ketentuan
          yang berlaku di Indonesia dalam melakukan usahanya dan pembinaan Mitra
          Usaha HZS.
        </h4>
        <h4>
          3.Perusahaan berkewajiban mengadakan produk yang baik, berkualitas,
          dan memiliki ijin edar, serta menyediakan alat-alat bantu penjualan
          yang diperlukan untuk Mitra Usaha HZS dalam menjalankan dan
          mengembangkan usahanya.
        </h4>
        <h4>
          4.Perusahaan wajib memberikan pelatihan dan pembinaan berkait dengan
          pengembangan usaha, mengadakan kegiatan dan fasilitas lainnya dalam
          rangka membantu pengembangan usaha para Mitra Usaha HZS.
        </h4>
        <h4>
          5.Perusahaan berkewajiban menjamin pembayaran komisi/bonus/reward atas
          usaha yang dilakukan oleh para Mitra Usaha HZS sesuai dengan yang
          tercantum dalam Marketing Plan.
        </h4>
        <h4>
          6.Perusahaan berkewajiban memberikan layanan sebaik mungkin kepada
          para Mitra Usaha HZS dan menjaga kondusifitas usaha bagi para
          pelakunya.
        </h4>
        <h4>
          7.Perusahaan berwajiban mengenakan pajak progresif atas penghasilan
          yang diperoleh Mitra Usaha HZS dalam menjalankan bisnisnya sesuai
          dengan ketentuan Undang-Undang Perpajakan yang berlaku di negara
          Indonesia untuk Industri Penjualan Langsung.
        </h4>
        <div class="pop_title2">BAB IX</div>
        <div class="pop_title1">KOMISI DAN BONUS</div>
        <h4>1.RINCIAN KOMISI DAN BONUS</h4>
        <h4>a.KOMISI/BONUS PLAN A</h4>
        <h4>
          i.KOMISI Sponsor adalah komisi yang anda terima berdasar belanja paket
          orang yang anda sponsori langsung.
        </h4>
        <h4>
          ii.KOMISI Group adalah komisi yang anda terima dihitung dari
          persentase PV kaki kecil anda.
        </h4>
        <h4>
          iii.Bonus Leadership adalah bonus yang anda terima sebesar 5% dari
          Komisi Grup downline anda, dari Level 1 sampai Level 10 sesuai
          Peringkat anda,
        </h4>

        <h4>b.KOMISI/BONUS PLAN B</h4>
        <h4>i.Komisi Diskon & Komisi selisih Peringkat</h4>
        <h4>
          a.Komisi Diskon adalah diskon atas belanja Repeat Order Pribadi yang
          besarnya sesuai dengan Peringkat Mitra.
        </h4>
        <h4>
          b.Komisi Perbedaan peringkat adalah diskon yang diterima Upline, bila
          downlinenya lebih rendah peringkatnya.
        </h4>
        <h4>
          ii.Bonus Sharing Bulanan adalah adalah bonus yang diambil 0,5% dari
          Omset Bulanan, dan dibagikan kepada masing-masing peringkat ( ada 10
          Peringkat )
        </h4>
        <h4>
          iii.Bonus Group Repeat Order adalah bonus yang anda terima dihitung
          dari persentase PV kaki kecil anda.
        </h4>
        <h4>
          2.Pembayaran komisi dan bonus akan dilakukan secara transfer melalui
          Bank dengan mengenakan biaya administrasi sesuai kebijakan Bank
          masing-masing. Jenis komisi dan bonus pada waktu transfernya, dengan
          pengecualian saat terjadi kondisi yang tidak memungkinkan atau force
          majeur :
        </h4>
        <div class="pop_title2">BAB X</div>
        <div class="pop_title1">Pajak</div>
        <h4>
          1.Penerimaan Komisi dan Bonus oleh mitra usaha dikenakan pajak
          mengikuti ketentuan perpajakan yang berlaku di Indonesia, dimana
          setiap mitra usaha yang mendapatkan komisi dan bonus atau bonus Reward
          akan langsung dipotong pajak penghasilan (PPh) sesuai dengan peraturan
          perpajakan yang berlaku.
        </h4>
        <h4>
          2.Bukti Potong Pajak akan diberikan kepada MITRA yang bersangkutan
        </h4>
        <h4>
          3.Segala kewajiban perpajakan dari seorang mitra usaha menjadi beban
          dari tanggung jawab mitra usaha yang bersangkutan.
        </h4>
        <div class="pop_title2">BAB XI</div>
        <div class="pop_title1">PELATIHAN DAN PEMBINAAN BAGI MITRA USAHA</div>
        <h4>1.Pelatihan yang diberikan oleh Perusahaan adalah:</h4>
        <h4>
          a.HZS Open Plan (HOP), adalah acara yang difasilitasi oleh Perusahaan
          untuk mengenalkan tentang peluang bisnis HZS kepada masyarakat umum
          sekaligus membantu para Mitra Usaha yang belum mampu melakukan
          presentasi mandiri. Acara ini dilakukan secara berkala setiap satu
          bulan sekali tiap minggu pertama tiap bulannya, bebas biaya, dan
          dilakukan di Kantor Pusat. Terbuka bagi semua peringkat.
        </h4>
        <h4>
          b.HZS Business Orientation (HBO), adalah pelatihan bagi para Mitra
          Usaha HZS yang baru bergabung untuk mendalami pengetahuan produk,
          marketing plan, cara menjual, serta cara menjalankan bisnis HZS. Acara
          ini dilakukan secara berkala setiap satu minggu sekali setiap
          bulannya, bebas biaya, dan dilakukan di Kantor Pusat. Terbuka bagi
          semua peringkat.
        </h4>
        <h4>
          c.HZS Leaders Club (HLC), adalah pertemuan khusus bagi para Mitra
          Usaha HZS yang telah mencapai peringkat tertentu sebanyak tiga kali
          dalam setahun. Pertemuan ini dilakukan secara tahunan dan tidak
          berbayar bagi para pencapainya. Hanya bagi Mitra Usaha yang telah
          mencapai peringkat Platinum.
        </h4>
        <h4>2.Pembinaan yang akan diberikan oleh Perusahaan adalah :</h4>
        <h4>
          a.HZS Champion Club (HCC), merupakan program pembinaan bagi pemula di
          bisnis HZS. Acara pembinaan sehari penuh yang diadakan oleh Perusahaan
          untuk mempersiapkan para pemula memiliki sikap seorang pengusaha dan
          keterampilan dasar dalam menjual produk maupun melakukan sponsorisasi.
          KBW akan diadakan dua bulan sekali dan tidak berbayar. Tidak ada
          syarat kualifikasi peringkat untuk mengikuti pembinaan ini.
        </h4>
        <h4>
          b.HZS STOCKIST MEETING (HSM), merupakan program pembinaan bagi seluruh
          Stokis HZS agar dapat memberikan pelayanan yang lebih baik kepada
          seluruh Mitra Usaha, sekaligus memberikan penghargaan kepada Stokis
          yang berprestasi. Stokist Meeting ini dilakukan setahun sekali di
          Kantor Pusat. Peserta adalah pemilik dan admin Stokist saja, dan tidak
          berbayar.
        </h4>
        <div class="pop_title2">BAB XII</div>
        <div class="pop_title1">
          SANKSI ATAS PELANGGARAN OLEH MITRA USAHA HZS
        </div>
        <h4>
          1.Mitra Usaha HZS yang melakukan perbuatan melanggar Kode Etik ini
          ataupun hukum, dan Perusahaan mendapatkan laporan secara tertulis
          dengan bukti yang cukup, maka perusahaan berhak menjatuhkan Surat
          Peringatan 1. Apabila melanggar untuk yang ke-2 kalinya, maka
          perusahaan berhak untuk memberhentikan/menghapus keanggotaan Mitra
          Usaha tersebut.
        </h4>
        <h4>
          2.Pelanggaran yang terjadi akan diberi surat peringatan atau dapat
          mengakibatkan diberhentikannya keanggotaan Mitra Usaha tersebut, atau
          dilakukan penundaan pembayaran komisi dan bonus sementara selama masa
          investigasi.
        </h4>
        <h4>
          3.Apabila berdasarkan hasil investigasi, Mitra Usaha yang bersangkutan
          terbukti melanggar ketentuan sebagaimana yang sudah ditetapkan, maka
          komisi dan bonus yang bersangkutan akan dibayarkan dan setelah itu
          keanggotaannya langsung dibatalkan. Sebaliknya apabila ia tidak
          terbukti melakukan pelanggaran, maka komisi dan bonus yang
          bersangkutan akan kembali diproses dan keanggotaannya tetap
          dilanjutkan.
        </h4>
        <div class="pop_title2">BAB XIII</div>
        <div class="pop_title1">PEMBAJAKAN DOWNLINE/MITRA USAHA LAIN</div>
        <h4>
          1.Mitra Usaha HZS tidak diperkenankan untuk membujuk atau membajak
          downline atau Mitra Usaha lain dari garis sponsorisasi yang berbeda
          untuk bergabung di garis sponsorisasinya. Apabila hal itu terbukti,
          maka:
        </h4>
        <h4>
          a.User Name Mitra Usaha yang baru disponsori tersebut akan dihapuskan.
        </h4>
        <h4>
          b.Sponsor yang merekrutnya akan dikenakan sanksi berupa Surat
          Peringatan hingga keanggotaan sponsor tersebut dicabut & diberhentikan
          dengan dengan ketentuan sesuai Kode Etik ini.
        </h4>
        <div class="pop_title2">BAB XIV</div>
        <div class="pop_title1">STOKIS</div>
        <h4>1.Persyaratan umum menjadi Stokist adalah sebagai berikut</h4>
        :
        <h4>
          a.Pemohon Stokist adalah Mitra Usaha dengan kualifikasi minimal
          peringkat Manager, sudah belanja Paket Diamond, dan mampu menciptakan
          omset Rp. 100.jt / bulan .
        </h4>
        <h4>
          b.Bersedia mematuhi Kode Etik Perusahaan dan peraturan stokist dari
          perusahaan, dan bersedia diberikan teguran atau sanksi apabila
          melanggar aturan.
        </h4>
        <h4>
          c.Bersedia memberikan layanan kepada semua Mitra Usaha tanpa
          membedakan jaringan.
        </h4>
        <h4>
          d.Siap melayani komplain produk dan layanan dari semua Mitra Usaha.
        </h4>
        <h4>
          e.Bersedia dilibatkan oleh perusahaan dalam acara-acara resmi
          perusahaan di wilayahnya.
        </h4>
        <h4>
          2.Besaran deposit awal sebagai Stokis HZS adalah Rp 100.000.000,-
          (seratus juta rupiah)
        </h4>
        <h4>3.Keuntungan menjadi Stokist :</h4>
        <h4>
          a.Mendapatkan pembinaan khusus dari perusahaan dalam acara tahunan
          khusus stokist. (semua pembinaan tahunan dengan akomodasi di tanggung
          perusahaan).
        </h4>
        <h4>
          b.Mendapatkan bantuan alat peraga baik brosur, pamflet, flyer, form
          kwitansi standar dari perusahaan secara berkala.
        </h4>
        <h4>
          c.Mendapatkan ongkos kirim gratis dengan pembelian nominal tertentu.
        </h4>
        <h4>
          d.Mendapatkan potongan harga (Discount) sebesar 4% dari harga produk
          setiap kali melakukan order ke perusahaan.
        </h4>
        <h4>4.Kewajiban bagi Stokis HZS :</h4>
        <h4>
          a.Stockis HZS wajib melayani setiap order dari perseorangan di area
          pemasarannya masing-masing tanpa terkecuali dan melakukan transaksi
          melalui sistem web HZS.
        </h4>
        <h4>
          b.Stokis wajib memberikan bukti transaksi yang kepada Mitra Usaha yang
          membeli produk, ataupun yang bergabung sebagai Mitra Usaha baru.
        </h4>
        <h4>
          c.Untuk order perseorangan yang memerlukan jasa kurir, Stockis HZS
          wajib menambahkan ongkos pengiriman, agar adil dan tidak terjadi
          penjualan dibawah harga resmi Perusahaan.
        </h4>
        <h4>
          d.Jumlah Repeat Order Minimum dari Stokis adalah sebesar Rp
          25.000.000,- ( lima juta rupiah) dalam sekali order untuk memperoleh
          pengiriman gratis.
        </h4>
        <div class="pop_title2">BAB XV</div>
        <div class="pop_title1">PERSELISIHAN ANTAR SPONSOR</div>
        <h4>
          1.PT HZS INTERNATIONAL INDONESIA tidak akan menengahi perselisihan
          apapun yang berasal dari satu atau beberapa individu yang menghubungi
          calon anggota (Prospek) yang sama. Jika lebih dari 1 (Satu) Mitra
          Usaha HZS yang mengklaim telah mensponsori orang yang sama, maka
          Perusahaan hanya akan mengakui atas aplikasi keanggotaan yang terlebih
          dahulu melakukan pendaftaran melalui online di Web Pusat
          www.HZS.co.id.
        </h4>
        <div class="pop_title2">BAB XVI</div>
        <div class="pop_title1">PERSELISIHAN DENGAN PERUSAHAAN</div>
        <h4>
          1.Segala bentuk perselisihan dengan perusahaan akan diselesaikan
          dengan mengutamakan prinsip musyawarah dan kekeluargaan. Namun apabila
          perselesihan tidak dapat terselesaikan dengan baik, maka penyelesaian
          akan dilakukan sesuai dengan hukum yang berlaku di Negara Kesatuan
          Republik Indonesia.
        </h4>
        <h4>
          2.Penyelesaian secara hukum akan dilakukan di Pengadilan Negeri dimana
          kedudukan PT HZS INTERNATIONAL INDONESIA berada, yakni Jakarta Barat
        </h4>
        <div class="pop_title2">BAB XVII</div>
        <div class="pop_title1">PENUTUP</div>
        <h4>
          Kode Etik dan Peraturan Perusahaan ini dibuat sebagai norma dan
          perjanjian antara PT HZS INTERNATIONAL INDONESIA dan Mitra Usaha HZS
          yang secara resmi telah diterima keanggotaannya. Setiap perubahan
          dalam Kode Etik dan Peraturan Perusahaan ini akan diberitahukan kepada
          seluruh Mitra Usaha selambatnya 30 (tiga puluh) hari sebelum
          diberlakukan dengan persetujuan terlebih dahulu dari Kementerian
          Perdagangan Republik Indonesia.
        </h4>
      </div>
    </el-dialog>
<!--    <el-dialog class="pop"-->
<!--               width="30%"-->
<!--               :visible.sync="isqyj"-->
<!--               center>-->
<!--      <span>-->
<!--        <div v-for="item,index in agreeData.agreement"-->
<!--             :key="index"-->
<!--             v-if='agreementTitle == item.agreementTitle'>-->
<!--          <div style="text-align:center;color:#333">{{item.agreementTitle}}</div>-->
<!--          <div v-html="item.agreement"></div>-->
<!--        </div>-->
<!--      </span>-->
<!--    </el-dialog>-->
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {getAlertUserAgreement, getAlertUserAgreementPost} from "@/api";

export default {
  name: 'Title',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isShow(n, o) {
      console.log('🚀 ~ n', n)
      this.toShow = n
    },
  },
  data() {
    return {
      popupShow: false,
      isqyj: false,
      toShow: this.isShow,
      hasQyj: 0,
      agreeData: '',
      agreementTitle: '',
    }
  },
    created() {
      this.getAlertUserAgreements()
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
  methods: {
    handleCancle() {
        getAlertUserAgreementPost().then(()=>{
        })
    },
    openIsqyj(val) {
      this.agreementTitle = val
      this.isqyj = true
    },
    getAlertUserAgreements(){
        getAlertUserAgreement().then((res)=>{
          if(res.code=='200'){
            this.popupShow = res.data==0?true:false
          }
        })
    },
    agreeList(val) {
      this.agreeData = val
    },
    getHasQyj(val) {
      this.hasQyj = val
    },
    toCheck(e) {
      this.$emit('agree', this.toShow)
    },
  },
}
</script>

<style lang="scss">
.agreementBox {
  width: 100%;
  height: auto;
  margin-top: 45px;
  .agreementIcon {
    display: flex;
    // align-items: center;
    justify-content: end;
  }
  .agreementText {
    // line-height: 0.2rem;
    margin-left: 10px;
    font-size: 14px;
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    .agreementDetail {
      color: #72deed;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
    }
  }
}

.pop {
  text-align: left;
  .pop_box {
    max-height: 70vh;
    overflow-y: scroll;
    padding: 0rem 0.4rem 0.8rem 0.4rem;
  }

  .pop_title {
    width: 100%;
    height: 1.4rem;
    line-height: 1.4rem;
    font-size: 16px;
    text-align: center;
  }
  .pop_title1 {
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .pop_title2 {
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
  }
}

h3 {
  margin: 0.1rem 0;
}

h4 {
  text-indent: 0.5rem;
}

.bold {
  font-weight: bold;
}

.footer {
  margin-top: 0.4rem;
  text-align: right;
}
</style>
