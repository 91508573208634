<template>
  <div class="sidebarWai">
    <!-- 公告列表-->
    <div class="right-notice">
      <div class="top">
        <div class="title1">{{ $t('ENU_NOTICE_FUNCTION_TYPE_1') }}</div>
        <div class="title2"
             @click="goMore(1)">{{ $t('S_L_11') }}》</div>
      </div>
      <div class="content"
           @click="goDetail(item)"
           style="cursor: pointer"
           v-for="(item, index) in tableData"
           :key="index">
        <div class="text1">
          {{ item.title }}
        </div>
        <div class="text2">{{ item.creationTime }}</div>
      </div>
    </div>
    <!-- 创造空间-->
    <div class="space">
      <div class="screen-view">
        <div class="spacetitle">{{ $t('S_R_1') }}</div>
        <div class="content">
          <div class="swiper-container swiper-no-swiping first-one"
               v-if="bannerLists.length > 0">
            <div class="swiper-wrapper swiper-list"
                 v-for="(item, indexs) in bannerLists"
                 :key="indexs">
              <div class="swiper-slide swiper-slide-s">
                <div :class="'swiper-container second-one-' + indexs">
                  <div class="swiper-wrapper">
                    <div class="swiper-slide"
                         v-for="(items, index) in item.fileList"
                         :key="index">
                      <img class="contentimg"
                           :src="items"
                           alt="" />
                    </div>
                  </div>
                </div>
                <div style="margin-left: 10px; margin-top: -20px">
                  <div class="text1">{{ item.storeName }}</div>
                  <div class="text2"
                       style="display: flex">
                    <img src="@/assets/images/phone.png"
                         style="
                        width: 14px;
                        height: 14px;
                        margin: 2px 5px 0 0;
                        display: block;
                      " /><span>{{$t('S_C_87')}}：{{ item.storePhone }}</span>
                  </div>
                  <div class="text2"
                       style="display: flex">
                    <img src="@/assets/images/address.png"
                         style="
                        width: 14px;
                        height: 14px;
                        margin: 2px 5px 0 0;
                        display: block;
                      " /><span>{{$t('S_C_88')}}：{{ item.storeProvinceVal }}{{ item.storeCityVal
                      }}{{ item.storeCountyVal }}{{ item.storeAddress }}</span>
                  </div>
                </div>
                <div class="swiper-button-prev"
                     @click="_stopPropagation($event)"
                     :class="'swiper-button-prev-' + indexs"></div>
                <div class="swiper-button-next"
                     @click="_stopPropagation($event)"
                     :class="'swiper-button-next-' + indexs"></div>
              </div>

              <!-- 如果需要分页器 -->
              <!--                      <div class="swiper-pagination"></div>-->
              <!-- 如果需要导航按钮 -->
            </div>
          </div>
          <div class="title2"
               @click="moreList()">{{ $t('S_L_11') }}>></div>
        </div>
      </div>
    </div>
    <!-- 购票-->
<!--    <div class="Ticket"></div>-->
  </div>
</template>

<script>
import * as sid from '@/api/sidebaruserinfo.js'
import { mapGetters } from 'vuex'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'
import { marketList } from '@/api/sidebaruserinfo.js'
export default {
  name: 'sidebarUserInfo',
  components: {},
  computed: {
    ...mapGetters(['userInfo']),
  },

  data() {
    return {
      tableData: [],
      bannerLists: [],
      queryParams: {
        pageNum: 1,
        pageSize: 5,
      },
    }
  },
  created() {
    this.getList()
  },
  mounted() {
    this.$nextTick(() => {
      this.bannerList()
    })
  },
  methods: {
    goDetail(item) {
      this.$router.push({
        path: '/noticeDetail',
        query: { pkId: item.pkId, functionType: 1 },
      })
    },
    _stopPropagation(ev) {
      var _this = this
      ev.stopPropagation()
    },
    moreList() {
      this.$router.push({
        path: '/makerspaceList',
      })
    },
    navTaps(item) {
      if (item.fileList) {
        this.$router.push({
          path: '/makerspaceDetail',
        })
        localStorage.setItem('marketSpace', JSON.stringify(item))
      }
    },
    getList() {
      sid.getNoticeList({ functionType: 1, isShowDay: '',pageSize:15 }).then((res) => {
        res.rows.forEach((item) => {
          item.creationTime = item.creationTime.substring(0, 10)
        })
        this.tableData = res.rows
      })
    },
    bannerList() {
      sid.marketList(this.queryParams).then((res) => {
        let arr = []
        res.rows.forEach((item) => {
          if (item.fileList != undefined) {
            arr.push(item)
          }
        })
        this.bannerLists = arr
        setTimeout(() => {
          this.initSwiper()
        }, 1500)
      })
    },
    initSwiper() {
      console.error('aaa')
      new Swiper('.first-one', {
        loop: false,
        grabCursor: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        observer: true,
        slidesPerView: 1,
        spaceBetween: 80,
        coverflowEffect: {
          rotate: 20,
          stretch: 3,
          depth: 200,
          modifier: 1,
          slideShadows: true,
        },
      })
      this.bannerLists.forEach((item, index) => {
        new Swiper('.second-one-' + index, {
          grabCursor: true,
          loop: false,
          observer: true,
          spaceBetween: 0,
          prevButton: '.swiper-button-prev-' + index,
          nextButton: '.swiper-button-next-' + index,
          nested: true, //用于嵌套相同方向的swiper时，当切换到子swiper时停止父swiper的切换。
        })
      })
    },
    //跳转详情页
    goMore(index) {
      //1公告 2站内信 3意见反馈
      this.$router.push({
        path: '/noticLists',
        query: { index: index },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.swiper-list .swiper-container {
}
.swiper-button-next {
  top: 130px;
}
.swiper-button-prev {
  top: 130px;
}
.swiper-slide-s {
  display: block !important;
  height: 300px !important;
}
.title2 {
  height: 17px;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #3499ff;
  cursor: pointer;
  text-align: center;
}
.swiper-pagination {
  top: 200px;
}
.swiper-wrapper {
  //height: 225px;
}
.swiper-container0 {
  width: 301px;
  height: 325px;
  margin: 0;
  //margin: 20px auto;
}
.swiper-slide {
  cursor: pointer;

  width: 301px !important;
  margin: 20px auto !important;
  //height: auto !important;
  padding-bottom: 20px;
  //height: 303px;
  background: #ffffff;
  //box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2),
  //inset 0px -1px 6px 0px rgba(0, 0, 0, 0.1);
  //border-radius: 10px 10px 10px 10px;
}
.space {
  // height: 1129px;
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  padding: 20px 20px 1px 20px;
  .spacetitle {
    font-size: 24px;
    font-family: PingFang SC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
  }
  .content {
    margin: 20px 0 40px 0;
    .contentimg {
      width: 301px;
      height: 195px;
      // background: #D8D8D8;
      border-radius: 8px 8px 8px 8px;
    }
    .text1 {
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-top: 10px;
    }
    .text2 {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin: 10px 0;
    }
  }
}
.Ticket {
  height: 354px;
  background: #fff;
  border-radius: 10px;
  margin-top: 20px;
}
.right-notice {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  padding: 5px 10px 20px 10px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    .title1 {
      font-size: 16px;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
    .title2 {
      height: 17px;
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #3499ff;
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
    .text1 {
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      margin-right: 10px;
      width: 230px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .text2 {
      font-size: 12px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
}
.seamless-warp {
  // height: 196px;
  overflow: hidden;
  line-height: 14px;
  text-align: left;
  font-size: 12px;
  .item {
    margin: 0;
    li {
      list-style: none;
    }
    .title {
      font-size: 12px;
      line-height: 20px;
    }
  }
}

.sidebarWai {
  width: 380px;
  //padding: 0 0 20px 0;
  //background: #ffffff;
  box-shadow: 5px 5px 20px 0px rgba(233, 233, 233, 0.5);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  margin-left: 40px;
  .sidebarTop {
    margin-top: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .sidebarHeader {
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
    }
    .nameCountry {
      margin-top: 20px;
      display: flex;
      align-content: center;
      align-items: center;
      .username {
        font-size: 22px;
        margin-right: 12px;
      }
      img {
        width: 39px;
        height: 26px;
      }
    }
    .gradeClass {
      display: flex;
      width: 200px;
      margin-top: 20px;
      align-items: center;
      justify-content: space-around;
      img {
        width: 55px;
        height: 45px;
        cursor: pointer;
      }
    }
  }
  .awardscard {
    width: 296px;
    height: 151px;
    margin: 0 auto;
    margin-top: 20px;
    background: linear-gradient(180deg, #f8d869 0%, #f48b1f 100%);
    border-radius: 10px;
  }
  .znBox {
    margin-top: 20px;
    border-top: 5px solid rgba(0, 0, 0, 0.05);
    // border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    padding: 20px;
    text-align: center;
    .zntitle {
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }
    .znCenter {
      // background: red;
      padding: 20px;
      .znrightword {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #41b2ff;
        text-align: right;
        cursor: pointer;
      }
      .znContent {
        // padding: 20px;
        .kongznx {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
      .bottonword {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: right;
        margin-top: 10px;
      }
    }
  }
  .ideaBox {
    padding-top: 50px;
    padding: 50px 10px 0px 10px;
    .ideainput {
      width: 260px;
    }
    .tjbutton {
      background: #d61820;
      border-radius: 5px 5px 5px 5px;
      width: 163px;
      font-size: 18px;
      height: 48px;
      color: #ffffff;
    }
  }
  .yjheader {
    display: flex;
    text-align: left;
    padding: 0 20px;
    align-items: center;
    .headertitle {
      margin-left: 20px;
      .headername {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
      }
      .headertime {
        margin-top: 5px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #909090;
      }
    }
  }
  .yjcontent {
    padding: 0 20px;
    text-align: left;
    margin-top: 24px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    display: block;
    width: 260px;
    word-break: break-all;
  }
  .searchmore {
    text-align: center;
    font-size: 18px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #41b2ff;
    cursor: pointer;
  }
}

::v-deep .ideaselect .el-input__inner {
  width: 260px;
  border-radius: 6px 6px 6px 6px;
  //background: rgba(0, 0, 0, 0.05) !important;
}

::v-deep .ideainput .el-textarea__inner {
  width: 260px;
  height: 160px;
  border-radius: 10px 10px 10px 10px;
  //background: rgba(0, 0, 0, 0.05) !important;
}
</style>