<template>
  <div class="content">
    <div class="level-max">
      <div class="max-img" v-if="dataList.memberAwardsUnderVO.image">
        <img :src="dataList.memberAwardsUnderVO.image" />
      </div>
      <div class="max-img" v-else>
        <img src="@/assets/images/avatar.png" />
      </div>
      <div class="max-info">
        <div class="max-name">
          {{ dataList.memberAwardsUnderVO.memberName }}
        </div>
        <div class="max-awards">
          {{ dataList.memberAwardsUnderVO.pkTransactionVal }}
        </div>
      </div>
      <div class="right-s">
        <div class="max-right">
          <div class="process">
            <div></div>
            <div
              class="experience"
              v-if="dataList.memberAwardsUnderVO.targetPv == '-1.00'"
            >
              {{ dataList.memberAwardsUnderVO.aSumRealPv }}/{{
                dataList.memberAwardsUnderVO.aSumRealPv
              }}
            </div>
            <div class="experience" v-else>
              <div v-if="dataList.memberAwardsUnderVO.aSumRealPv > '0.00'">
                {{ dataList.memberAwardsUnderVO.aSumRealPv }}/{{
                  dataList.memberAwardsUnderVO.targetPv
                }}
              </div>
              <div v-else>
                {{ dataList.memberAwardsUnderVO.aSumRealPv }}/{{
                  dataList.memberAwardsUnderVO.targetPv
                }}
              </div>
            </div>
            <div
              class="process-s"
              v-if="dataList.memberAwardsUnderVO.targetPv == '-1.00'"
              style="width: 100%"
            >
              <div
                class="image-icon"
                v-if="dataList.memberAwardsUnderVO.aSumRealPv > '0.00'"
              >
                <img src="@/assets/images/awards-1.png" />
              </div>
            </div>

            <div
              class="process-s"
              id="process"
              v-else
              :style="{
                width: `${
                  dataList.memberAwardsUnderVO.aSumRealPv > '0.00'
                    ? GetPercent(
                        dataList.memberAwardsUnderVO.aSumRealPv,
                        dataList.memberAwardsUnderVO.targetPv
                      ) >= 100
                      ? '100%'
                      : GetPercent(
                          dataList.memberAwardsUnderVO.aSumRealPv,
                          dataList.memberAwardsUnderVO.targetPv
                        ) + '%'
                    : '0%'
                }`,
              }"
            >
              <div
                class="image-icon"
                v-if="dataList.memberAwardsUnderVO.aSumRealPv > '0.00'"
              >
                <img src="@/assets/images/awards-1.png" />
              </div>
            </div>
          </div>
          <div class="money">
            {{ $t("S_C_18") }}：{{ dataList.memberAwardsUnderVO.aSumRealPv }}{{ $t("S_C_58") }}
          </div>
        </div>
        <div class="max-right">
          <div
            class="experience"
            v-if="dataList.memberAwardsUnderVO.targetPv == '-1.00'"
          >
            <div v-if="dataList.memberAwardsUnderVO.bSumRealPv > '0.00'">
              {{ dataList.memberAwardsUnderVO.bSumRealPv }}/{{
                dataList.memberAwardsUnderVO.bSumRealPv
              }}
            </div>
            <div v-else>
              {{ dataList.memberAwardsUnderVO.bSumRealPv }}/{{
                dataList.memberAwardsUnderVO.bSumRealPv
              }}
            </div>
          </div>
          <div class="experience" v-else>
            {{ dataList.memberAwardsUnderVO.bSumRealPv }}/{{
              dataList.memberAwardsUnderVO.targetPv
            }}
          </div>
          <div
            class="process"
            v-if="dataList.memberAwardsUnderVO.targetPv == '-1.00'"
          >
            <div
              class="process-s"
              style="background: #ffae36; width: 100%"
            ></div>
          </div>
          <div class="process" v-else>
            <div
              class="process-s"
              style="background: #ffae36"
              :style="{
                width: `${
                  dataList.memberAwardsUnderVO.bSumRealPv > '0.00'
                    ? GetPercent(
                        dataList.memberAwardsUnderVO.bSumRealPv,
                        dataList.memberAwardsUnderVO.targetPv
                      ) >= 100
                      ? '100%'
                      : GetPercent(
                          dataList.memberAwardsUnderVO.bSumRealPv,
                          dataList.memberAwardsUnderVO.targetPv
                        ) + '%'
                    : '0%'
                }`,
              }"
            >
              <div
                class="image-icon"
                v-if="dataList.memberAwardsUnderVO.bSumRealPv > '0.00'"
              >
                <img src="@/assets/images/awards-1.png" />
              </div>
            </div>
          </div>
          <div class="money">
            {{ $t("S_C_19") }}：{{ dataList.memberAwardsUnderVO.bSumRealPv
            }}{{ $t("S_C_58") }}
          </div>
        </div>
      </div>
    </div>
    <div class="award-content">
      <div class="award-left">
        <div class="title">
          <div class="icon">
            <img src="@/assets/images/awards-2.png" />
          </div>
          <div class="name">{{ $t("S_C_18") }}</div>
          <div class="icon">
            <img src="@/assets/images/awards-2.png" />
          </div>
        </div>
        <div
          class="award-list"
          v-for="(item, index) in dataList.leftMemberAwardsUnderList"
        >
          <div class="left-icons"></div>
          <div class="right-icons"></div>
          <div class="line-s"></div>
          <div class="content-s">
            <div class="user-top">
              <div class="user-avatar">
                <img  v-if="item.memberPath" :src="item.memberPath" />
                <img  v-else src="@/assets/images/avatar.png"  />
              </div>
              <div class="user-info">
                <div class="ranking" :class="'class' + index++">
                  <span style="font-size: 10px">NO.</span
                  ><span style="font-size: 14px">0{{ index }}</span>
                </div>
                <div class="">{{ item.memberName }}</div>
              </div>
            </div>
            <div class="user-bottom-s">
              <div class="process-width">
                <div>{{ $t("S_C_18") }}</div>
                <div class="process width-s">
                  <div
                    class="process-s"
                    v-if="item.targetPv == '-1.00'"
                    style="width: 100%"
                  >
                    <div class="image-icon" v-if="item.aSumRealPv > '0.00'">
                      <img src="@/assets/images/awards-1.png" />
                    </div>
                  </div>
                  <div
                    class="process-s"
                    v-else
                    :style="{
                      width: `${
                        item.aSumRealPv > '0.00'
                          ? GetPercent(item.aSumRealPv, item.targetPv) >= 100
                            ? '100%'
                            : GetPercent(item.aSumRealPv, item.targetPv) + '%'
                          : '0%'
                      }`,
                    }"
                  >
                    <div class="image-icon" v-if="item.aSumRealPv > '0.00'">
                      <img src="@/assets/images/awards-1.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="process-width">
                <div>{{ $t("S_C_19") }}</div>
                <div class="process width-s" v-if="item.targetPv == '-1.00'">
                  <div
                    class="process-s"
                    style="background: #ffae36; width: 100%"
                  ></div>
                </div>
                <div class="process width-s" v-else>
                  <div
                    class="process-s"
                    style="background: #ffae36"
                    :style="{
                      width: `${
                        item.bSumRealPv > '0.00'
                          ? GetPercent(item.bSumRealPv, item.targetPv) >= 100
                            ? '100%'
                            : GetPercent(item.bSumRealPv, item.targetPv) + '%'
                          : '0%'
                      }`,
                    }"
                  >
                    <div class="image-icon" v-if="item.bSumRealPv > '0.00'">
                      <img src="@/assets/images/awards-1.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="award-s">
              <div class="award-img">
                <img :src="item.image" v-if="item.pkAwards != 1&&item.image" />
                <div v-else style="width: 85px; height: 97px"></div>
              </div>
              <div>{{ item.pkTransactionVal }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="award-left left-s">
        <div class="title">
          <div class="icon">
            <img src="@/assets/images/awards-2.png" />
          </div>
          <div class="name">{{ $t("S_C_19") }}</div>
          <div class="icon">
            <img src="@/assets/images/awards-2.png" />
          </div>
        </div>
        <div
          class="award-list"
          v-for="(item, index) in dataList.rightMemberAwardsUnderList"
        >
          <div class="left-icons"></div>
          <div class="right-icons"></div>
          <div class="line-s"></div>
          <div class="content-s">
            <div class="user-top">
              <div class="user-avatar">
                <img :src="item.memberPath" v-if="item.memberPath" />
                <img src="@/assets/images/avatar.png" v-else />
              </div>
              <div class="user-info">
                <div class="ranking" :class="'class' + index++">
                  <span style="font-size: 10px">NO.</span
                  ><span style="font-size: 14px">0{{ index }}</span>
                </div>
                <div class="">{{ item.memberName }}</div>
              </div>
            </div>
            <div class="user-bottom-s">
              <div class="process-width">
                <div>{{ $t("S_C_18") }}</div>
                <div class="process width-s">
                  <div
                    class="process-s"
                    v-if="item.targetPv == '-1.00'"
                    style="width: 100%"
                  >
                    <div class="image-icon" v-if="item.aSumRealPv > '0.00'">
                      <img src="@/assets/images/awards-1.png" />
                    </div>
                  </div>
                  <div
                    class="process-s"
                    v-else
                    :style="{
                      width: `${
                        item.aSumRealPv > '0.00'
                          ? GetPercent(item.aSumRealPv, item.targetPv) >= 100
                            ? '100%'
                            : GetPercent(item.aSumRealPv, item.targetPv) + '%'
                          : '0%'
                      }`,
                    }"
                  >
                    <div class="image-icon" v-if="item.aSumRealPv > '0.00'">
                      <img src="@/assets/images/awards-1.png" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="process-width">
                <div>{{ $t("S_C_19") }}</div>
                <div class="process width-s" v-if="item.targetPv == '-1.00'">
                  <div
                    class="process-s"
                    style="background: #ffae36; width: 100%"
                  ></div>
                </div>
                <div class="process width-s" v-else>
                  <div
                    class="process-s"
                    style="background: #ffae36"
                    :style="{
                      width: `${
                        item.bSumRealPv > '0.00'
                          ? GetPercent(item.bSumRealPv, item.targetPv) >= 100
                            ? '100%'
                            : GetPercent(item.bSumRealPv, item.targetPv) + '%'
                          : '0%'
                      }`,
                    }"
                  ><div class="image-icon" v-if="item.bSumRealPv > '0.00'">
                      <img src="@/assets/images/awards-1.png" />
                    </div></div>
                </div>
              </div>
            </div>
            <div class="award-s">
              <div class="award-img">
                <img :src="item.image" v-if="item.pkAwards != 1&&item.image" />
                <div v-else style="width: 85px; height: 97px"></div>
              </div>
              <div>{{ item.pkTransactionVal }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getIndexAwards } from "@/api/index";
import { mapGetters } from "vuex";
export default {
  name: "honoraryAward",
  data() {
    return {
      dataList: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    GetPercent(num, total) {
      /// <summary>
      /// 求百分比
      /// </summary>
      /// <param name="num">当前数</param>
      /// <param name="total">总数</param>

      num = parseFloat(num);
      total = parseFloat(total);
      // if(isNaN(total) >= isNaN(num)){
      //     return 100+'%'
      // }
      if (total < 0) {
        return 100 + "%";
      }
      if (isNaN(num) || isNaN(total)) {
        return "-";
      }
      return total <= 0 ? 0 : (num / total) * 100;
    },
    getIndexAwardsData() {
      getIndexAwards().then((res) => {
        if (res.code == "200") {
          this.dataList = res.data;
        }
      });
    },
  },
  mounted() {
    this.getIndexAwardsData();
  },
};
</script>

<style scoped lang="scss">
.left-s {
  margin-left: 40px;
}
.class0 {
  color: #f64e49;
}
.class1 {
  color: #f6b049;
}
.class2 {
  color: #37d747;
}
.class3 {
  color: #bbbbbb;
}
.class4 {
  color: #bbbbbb;
}
.width-s {
  width: 420px !important;
  margin-left: 5px;
  margin-top: 2px;
}
.process-width {
  display: flex;
  margin-bottom: 10px;
}
.user-bottom-s {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 10px;
}
.ranking {
  font-size: 10px;
}
.award-s {
  position: absolute;
  right: 10px;
  top: 10px;
  text-align: center;
}
.award-img {
  img {
    width: 85px;
    height: 97px;
  }
}
.user-info {
  margin: 10px 0 0 13px;
}
.user-top {
  display: flex;
}
.user-avatar {
  img {
    width: 66px;
    height: 66px;
    border-radius: 50%;
  }
}
.line-s {
  position: absolute;
  width: 445px;
  height: 1px;
  bottom: 75px;
  left: 13px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.05);
}
.right-icons {
  width: 12px;
  height: 12px;
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 70px;
  right: 0px;
  border-radius: 50% 0 0 50%;
}
.left-icons {
  width: 12px;
  height: 12px;
  background: rgba(0, 0, 0, 0.05);
  position: absolute;
  bottom: 70px;
  left: 0px;
  border-radius: 0 50% 50% 0;
}
.award-list {
  margin-bottom: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
  border-radius: 10px 10px 10px 10px;
  height: 204px;
  width: 100%;
  position: relative;
  padding: 10px;
}
.award-left {
  width: 477px;
  //display: flex;
  //justify-content: center;
}
.title {
  margin: 0 auto 10px auto;
  display: flex;
  justify-content: center;
  color: #333;
  font-size: 26px;
  line-height: 24px;
  .name {
    margin: 0 10px;
  }
}
.icon {
  img {
    width: 40px;
    height: 20px;
  }
}
.award-content {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  margin-left: -20px;
}
.image-icon {
  img {
    width: 79px;
    height: 62px;
    position: absolute;
    right: -21px;
    //margin-left: 30px;
    top: -23px;
  }
}
.experience {
  color: #333;
  font-size: 12px;
  position: absolute;
  left: 50%;
  margin-top: 3px;
  z-index: 111;
}
.process-s {
  background: #fe4c4b;
  border-radius: 8px;
  height: 18px;
  //width: 30%;
  position: relative;
}
.money {
  color: #333;
  font-size: 18px;
  margin: -5px 0 0 20px;
}
.process {
  width: 527px;
  height: 18px;
  background: #ededed;
  border-radius: 8px 8px 8px 8px;
}
.max-right {
  display: flex;
  margin-bottom: 20px;
}
.right-s {
  margin: 30px 0 0 30px;
}
.content {
  padding: 20px 38px;
  .level-max {
    display: flex;
    .max-img {
      img {
        width: 126px;
        height: 126px;
      }
    }
    .max-info {
      margin: 30px 0 0 25px;
      .max-name {
        color: #333;
        font-size: 22px;
      }
      .max-awards {
        color: #666;
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }
}
</style>