<template>
  <div
    class="contain"
    :class="
      (showGoalsMeetingVOList_1.length > 0 && navIndex == 0) ||
      (showGoalsMeetingVOList_2.length > 0 && navIndex == 1) ||
      (showGoalsMeetingVOList_3.length > 0 && navIndex == 2)
        ? 'bgcolor-s'
        : ''
    "
  >
    <!-- 未填写图片 !-->
    <div
      class="top-target"
      v-if="
        (showGoalsMeetingVOList_1.length == 0 && navIndex == 0) ||
        (showGoalsMeetingVOList_2.length == 0 && navIndex == 1) ||
        (showGoalsMeetingVOList_3.length == 0 && navIndex == 2)
      "
    >
      <div class="target-icon">
        <img src="@/assets/images/target-icon2.png" />
      </div>
      <div class="text-s">
        {{ $t("S_C_1") }}
      </div>
      <div class="target-icon">
        <img src="@/assets/images/target-icon1.png" />
      </div>
    </div>
    <!-- 弹窗!-->
    <el-dialog :visible.sync="dialogs" width="30%" center>
      <div class="warning-s">
        <img src="@/assets/images/warning-icon.png" />
      </div>
      <div class="text3">
        {{ text1 }}{{ $t("S_C_2") }}{{ text2 }}{{ $t("S_C_3") }}！
      </div>
      <div
        style="display: flex; justify-content: center; margin: 30px 0 20px 0"
      >
        <el-button
          class="cancel"
          @click="dialogs = false"
          style="width: 117px; font-size: 16px"
          >{{ $t("ENU_P_TYPE0") }}</el-button
        >
        <el-button class="saveBtn" @click="saveTx">{{
          $t("ENU_P_TYPE4")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 填写完的显示 !-->
    <div
      v-if="
        (showGoalsMeetingVOList_1.length > 0 && navIndex == 0) ||
        (showGoalsMeetingVOList_2.length > 0 && navIndex == 1) ||
        (showGoalsMeetingVOList_3.length > 0 && navIndex == 2)
      "
    >
      <div class="target-bottom">
        <img src="@/assets/images/target-icon3.png" />
      </div>
      <div class="target-bottom-right">
        <img src="@/assets/images/target-icon4.png" />
      </div>
      <div class="top-title" v-if="navIndex == 0">
        <div>{{ $t("S_C_4") }}</div>
      </div>
      <div class="top-title" v-if="navIndex == 1">
        <div>{{ $t("S_C_5") }}</div>
      </div>
      <div class="top-title" v-if="navIndex == 2">
        <div>{{ $t("S_C_6") }}</div>
      </div>
    </div>
    <div
      :class="
        (showGoalsMeetingVOList_1.length > 0 && navIndex == 0) ||
        (showGoalsMeetingVOList_2.length > 0 && navIndex == 1) ||
        (showGoalsMeetingVOList_3.length > 0 && navIndex == 2)
          ? 'color-s'
          : ''
      "
    >
      <div class="bottom-bg-color"></div>
      <div>
        <div class="nav-top">
          <div
            class="nav-top-list"
            @click="navChange(index)"
            :class="navIndex == index ? 'styles' : ''"
            v-for="(item, index) in navList"
          >
            {{ item }}
            <div class="style-s" v-if="navIndex == index"></div>
          </div>
        </div>
      </div>
      <div class="top-s">
        <div class="nav-top">
          <div
            class="nav-top-views"
            @click="navChange1(index)"
            :class="navIndex1 == index ? 'styles1' : ''"
            v-for="(item, index) in navList1"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div
        class="target-sel"
        v-if="
          (showGoalsMeetingVOList_1.length > 0 && navIndex == 0) ||
          (showGoalsMeetingVOList_2.length > 0 && navIndex == 1) ||
          (showGoalsMeetingVOList_3.length > 0 && navIndex == 2)
        "
      >
        <div class="text">{{ $t("S_C_7") }}:{{ unit }}</div>
        <div
          class="right-month"
          v-if="navIndex == 0"
          style="margin-right: 10px"
        >
          {{ moth_s }}{{ $t("ENU_SETTLE_P_3") }}
        </div>
        <div
          class="right-month"
          v-if="navIndex == 1"
          style="margin-right: 10px"
        >
          {{ showGoalsMeetingVOList_2[0].month }}
        </div>
        <div
          class="right-month"
          v-if="navIndex == 2"
          style="margin-right: 10px"
        >
          {{ showGoalsMeetingVOList_3[0].month }}
        </div>
      </div>
      <div
        class="target-sel"
        v-if="
          (showGoalsMeetingVOList_1.length == 0 && navIndex == 0) ||
          (showGoalsMeetingVOList_2.length == 0 && navIndex == 1) ||
          (showGoalsMeetingVOList_3.length == 0 && navIndex == 2)
        "
      >
        <div class="text">{{ $t("S_C_7") }}:{{ unit }}</div>
        <div class="right-month" v-if="navIndex == 0">
          <el-form>
            <el-col :span="11" style="float: right; margin-right: 10px">
              <el-select v-model="texts" @change="selectTime" placeholder="">
                <el-option
                  v-for="item in months"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-form>
        </div>
        <div class="right-month" v-if="navIndex == 1 || navIndex == 2">
          <el-form>
            <el-col :span="11" style="float: right; margin-right: 10px">
              <el-select v-model="times" @change="selectTimes" placeholder="">
                <el-option
                  v-for="item in months1"
                  :key="item.name"
                  :label="item.name"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-col>
          </el-form>
        </div>
      </div>
      <!-- 月度目标 -未提交 !-->
      <div
        class="goalsClassifyVOList1"
        v-if="showGoalsMeetingVOList_1 == false"
      >
        <el-form
          :model="formModel"
          :rules="rules"
          ref="loginForm"
          :validate-on-rule-change="false"
        >
          <!-- 月 团队业绩 !-->
          <div class="table-lists" v-show="navIndex == 0 && navIndex1 == 0">
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_8") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_10") }}({{isOther()}})
              </div>
            </div>
            <div class="table-right">
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="totalAchieve_1">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel.totalAchieve_1"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_14") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m1_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m2_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m3_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m4_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 月 团队业绩 !-->
          <div class="table-lists" v-show="navIndex == 0 && navIndex1 == 1">
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_15") }}({{isOther()}})
              </div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_16") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_17") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_10") }}({{isOther()}})
              </div>
            </div>
            <div class="table-right">
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="totalAchieve_2">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel.totalAchieve_2"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="rightAchieve_2">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel.rightAchieve_2"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_14") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        {{ $t("S_C_18") }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        {{ $t("S_C_19") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        {{ $t("S_C_18") }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        {{ $t("S_C_19") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        {{ $t("S_C_18") }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        {{ $t("S_C_19") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        {{ $t("S_C_18") }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        {{ $t("S_C_19") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml1_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.ml1_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr1_2" style="padding-left: 10px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.mr1_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml2_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.ml2_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr2_2" style="padding-left: 10px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.mr2_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml3_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.ml3_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr3_2" style="padding-left: 10px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.mr3_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml4_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.ml4_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr4_2" style="padding-left: 10px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.mr4_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 月 推荐业绩 !-->
          <div class="table-lists" v-show="navIndex == 0 && navIndex1 == 2">
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span>
                {{ $t("S_C_8") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span>
                {{ $t("S_C_10") }}({{isOther()}})
              </div>
            </div>
            <div class="table-right">
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="totalAchieve_3">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel.totalAchieve_3"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_14") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m1_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m2_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m3_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m4_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 月 推荐人数 !-->
          <div class="table-lists" v-show="navIndex == 0 && navIndex1 == 3">
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span>
                {{ $t("S_C_20") }}
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span>
                {{ $t("S_C_21") }}({{isOther()}})
              </div>
            </div>
            <div class="table-right">
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="totalAchieve_4">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel.totalAchieve_4"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_14") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m1_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m2_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m3_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m4_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 月 {{$t('S_C_23')}} !-->
          <div class="table-lists" v-show="navIndex == 0 && navIndex1 == 4">
            <div class="table-title">
              <div class="title" style="width: 180px">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span>
                {{ $t("S_C_22") }}
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span>
                {{ $t("S_C_23") }}
              </div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span>
                {{ $t("S_C_24") }}
              </div>
              <div class="title" v-for="item in goalsMeetingVOList1">
                <el-row class="input-s style_s">
                  <el-col :span="22">
                    <el-form-item>
                      <el-input
                        type="text"
                        v-model="item.meetingType"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div
              class="table-right"
              style="width: auto; overflow: initial !important"
            >
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="4">
                    <el-form-item prop="totalAchieve_5">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        @blur="focus1"
                        v-model="formModel.totalAchieve_5"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s">{{ $t("S_C_14") }}</div>
                <div class="border-s" style="border: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m1_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m2_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m3_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m4_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_25") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_26") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_27") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_28") }}
                </div>
                <div class="border-s" style="border-right: none">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_29") }}
                </div>
              </div>
              <div class="title flex-s" v-for="item in goalsMeetingVOList1">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input
                          type="text"
                          v-model="item.meetingNumber"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-cascader
                          class="area"
                          v-model="item.areaAddress"
                          @change="setAddress1($event, item)"
                          :options="areaData"
                          :props="regionParams"
                          :placeholder="$t('S_C_30')"
                        ></el-cascader>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.address"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.meetingTheme"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.remarks"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 月 会议报备 !-->
          <div class="table-lists" v-show="navIndex == 0 && navIndex1 == 5">
            <div class="table-title">
              <div class="title" style="width: 180px">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_22") }}
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_23") }}
              </div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_24") }}
              </div>
              <div class="title" v-for="item in goalsMeetingVOList1">
                <el-row class="input-s style_s">
                  <el-col :span="22">
                    <el-form-item>
                      <el-input v-model="item.meetingType"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div
              class="table-right"
              style="width: auto; overflow: initial !important"
            >
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="3" style="width: 180px">
                    <el-form-item prop="totalAchieve_5">
                      <el-input
                        type="number"
                        :max="10"
                        :min="1"
                        @blur="focus1"
                        disabled="disabled"
                        v-model="formModel.totalAchieve_5"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s">{{ $t("S_C_14") }}</div>
                <div class="border-s" style="border: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_5">
                        <el-input
                          type="number"
                          disabled="disabled"
                          v-model="formModel.m1_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_5">
                        <el-input
                          type="number"
                          disabled="disabled"
                          v-model="formModel.m2_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_5">
                        <el-input
                          type="number"
                          disabled="disabled"
                          v-model="formModel.m3_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_5">
                        <el-input
                          type="number"
                          disabled="disabled"
                          v-model="formModel.m4_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_28") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_25") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_26") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_27") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_31") }}
                </div>
                <div class="border-s" style="border-right: none">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_29") }}
                </div>
              </div>
              <div class="title flex-s" v-for="item in goalsMeetingVOList1">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.meetingTheme"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="item.meetingNumber"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-cascader
                          class="area"
                          v-model="item.areaAddress"
                          @change="setAddress1($event, item)"
                          :options="areaData"
                          :props="regionParams"
                          :placeholder="$t('S_C_30')"
                        ></el-cascader>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.address"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-date-picker
                          type="date"
                          v-model="item.meetingDate"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          @change="changes($event, item)"
                        />
                        <!--                                            <el-input-->
                        <!--                                                v-model="item.meetingDate"></el-input>-->
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.remarks"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 月 创客空间 !-->
          <div class="table-lists" v-show="navIndex == 0 && navIndex1 == 6">
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_32") }}
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_33") }}
              </div>
            </div>
            <div class="table-right">
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="totalAchieve_7">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel.totalAchieve_7"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_14") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m1_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m2_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m3_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m4_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 月 超级工作室 !-->
          <div class="table-lists" v-show="navIndex == 0 && navIndex1 == 7">
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_8") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_10") }}({{isOther()}})
              </div>
            </div>
            <div class="table-right">
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="totalAchieve_8">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel.totalAchieve_8"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_14") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m1_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m2_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m3_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel.m4_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 月度目标 -已提交 !-->
      <div
        class="goalsClassifyVOList1"
        v-if="showGoalsMeetingVOList_1 != false"
      >
        <el-form>
          <!-- 月 团队业绩 !-->
          <div
            class="table-lists"
            id="myImage0"
            v-show="navIndex == 0 && navIndex1 == 0"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_8") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_10") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_34") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_35") }}(%)</div>
            </div>
            <div class="table-right">
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                        .totalRatio || 0
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_14") }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[0].m1
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[0].m2
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[0].m3
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[0].m4
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[1].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[1].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[1].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[1].m4 || 0
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[2].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[2].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[2].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[2].m4 || 0
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- 月 安置业绩 !-->
          <div
            class="table-lists"
            id="myImage1"
            v-show="navIndex == 0 && navIndex1 == 1"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_15") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_16") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_17") }}</div>
              <div class="title">{{ $t("S_C_10") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_34") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_35") }}(%)</div>
            </div>
            <div class="table-right">
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                        .totalRatio || 0
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .rightAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                        .rightRatio || 0
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_14") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{ $t("S_C_18") }}
                    </el-col>
                    <el-col :span="12">
                      {{ $t("S_C_19") }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{ $t("S_C_18") }}
                    </el-col>
                    <el-col :span="12">
                      {{ $t("S_C_19") }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{ $t("S_C_18") }}
                    </el-col>
                    <el-col :span="12">
                      {{ $t("S_C_19") }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{ $t("S_C_18") }}
                    </el-col>
                    <el-col :span="12">
                      {{ $t("S_C_19") }}
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml1 || 0.0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr1 || 0.0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml2 || 0.0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr2 || 0.0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml3 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr3 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml4 || 0.0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr4 || 0.0
                      }}
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[1].ml1 || 0.0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[1].mr1 || 0.0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[1].ml2 || 0.0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[1].mr2 || 0.0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[1].ml3 || 0.0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[1].mr3 || 0.0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[1].ml4 || 0.0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[1].mr4 || 0.0
                      }}
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[2].ml1 || 0.0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[2].mr1 || 0.0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[2].ml2 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[2].mr2 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[2].ml3 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[2].mr3 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[2].ml4 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[2].mr4 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 月 推荐业绩 !-->
          <div
            class="table-lists"
            id="myImage2"
            v-show="navIndex == 0 && navIndex1 == 2"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_8") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_10") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_34") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_35") }}(%)</div>
            </div>
            <div class="table-right">
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                        .totalRatio || 0
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_14") }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[0].m1
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[0].m2
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[0].m3
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[0].m4
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[1].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[1].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[1].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[1].m4 || 0
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[2].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[2].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[2].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[2].m4 || 0
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- 月 推荐人数 !-->
          <div
            class="table-lists"
            id="myImage3"
            v-show="navIndex == 0 && navIndex1 == 3"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_20") }}</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_21") }}</div>
              <div class="title">{{ $t("S_C_34") }}</div>
              <div class="title">{{ $t("S_C_35") }}(%)</div>
            </div>
            <div class="table-right">
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                          .totalAchieve
                      |_int}}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                        .totalRatio || 0
                        |_int}}</span>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_14") }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[0].m1
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[0].m2
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[0].m3
                      |_int}}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[0].m4
                      |_int}}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[1].m1 || 0
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[1].m2 || 0
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[1].m3 || 0
                      |_int}}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[1].m4 || 0
                      |_int }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[2].m1 || 0
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[2].m2 || 0
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[2].m3 || 0
                      |_int}}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[2].m4 || 0
                      |_int}}
                </div>
              </div>
            </div>
          </div>
          <!-- 月 {{$t('S_C_23')}} !-->
          <div
            class="table-lists"
            id="myImage4"
            v-show="navIndex == 0 && navIndex1 == 4"
          >
            <div class="table-title">
              <div class="title" style="width: 180px">{{ $t("S_C_22") }}</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_23") }}</div>
              <div class="title">{{ $t("S_C_24") }}</div>
              <div
                class="title size-s"
                v-for="item in showGoalsMeetingVOList_1[0]
                  .goalsClassifyVOList[4].goalsMeetingVOList"
              >
                {{ item.meetingType }}
              </div>
            </div>
            <div
              class="table-right"
              style="width: auto; overflow: initial !important"
            >
              <div class="title">
                <div class="table-width title flex-autos">
                  <div class="width-auto-s" style="border-right: none">
                    <div class="left-s">
                      <div>
                        {{
                          showGoalsMeetingVOList_1[0].goalsClassifyVOList[4]
                            .totalAchieve
                            |_int}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s">{{ $t("S_C_14") }}</div>
                <div class="border-s" style="border: none"></div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[4]
                      .goalsRowDetailVOList[0].m1
                      |_int }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[4]
                      .goalsRowDetailVOList[0].m2
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[4]
                      .goalsRowDetailVOList[0].m3
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[4]
                      .goalsRowDetailVOList[0].m4
                      |_int }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_25") }}</div>
                <div class="border-s">{{ $t("S_C_26") }}</div>
                <div class="border-s">{{ $t("S_C_27") }}</div>
                <div class="border-s">{{ $t("S_C_28") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_29") }}
                </div>
              </div>
              <div
                class="title flex-s size-s"
                v-for="item in showGoalsMeetingVOList_1[0]
                  .goalsClassifyVOList[4].goalsMeetingVOList"
              >
                <div class="border-s">
                  {{ item.meetingNumber }}
                </div>
                <div class="border-s">
                  {{ item.recProvinceVal }} {{ item.recCityVal }}
                  {{ item.recCountyVal }}
                </div>
                <div class="border-s">
                  {{ item.address }}
                </div>
                <div class="border-s">
                  {{ item.meetingTheme }}
                </div>
                <div class="border-s" style="border: none">
                  {{ item.remarks }}
                </div>
              </div>
            </div>
          </div>
          <!-- 月 会议报备 !-->
          <div
            class="table-lists"
            id="myImage5"
            v-show="navIndex == 0 && navIndex1 == 5"
          >
            <div class="table-title">
              <div class="title" style="width: 180px">{{ $t("S_C_22") }}</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_23") }}</div>
              <div class="title">{{ $t("S_C_24") }}</div>
              <div
                class="title size-s"
                v-for="item in showGoalsMeetingVOList_1[0]
                  .goalsClassifyVOList[5].goalsMeetingVOList"
              >
                {{ item.meetingType }}
              </div>
            </div>
            <div
              class="table-right"
              style="width: auto; overflow: initial !important"
            >
              <div class="title">
                <div class="table-width title flex-autos">
                  <div class="width-auto-s" style="border-right: none">
                    <div class="left-s">
                      <div>
                        {{
                          showGoalsMeetingVOList_1[0].goalsClassifyVOList[5]
                            .totalAchieve
                            |_int}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s">{{ $t("S_C_14") }}</div>
                <div class="border-s" style="border: none"></div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[5]
                      .goalsRowDetailVOList[0].m1
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[5]
                      .goalsRowDetailVOList[0].m2
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[5]
                      .goalsRowDetailVOList[0].m3
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[5]
                      .goalsRowDetailVOList[0].m4
                      |_int}}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_28") }}</div>
                <div class="border-s">{{ $t("S_C_25") }}</div>
                <div class="border-s">{{ $t("S_C_26") }}</div>
                <div class="border-s">{{ $t("S_C_27") }}</div>
                <div class="border-s">{{ $t("S_C_31") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_29") }}
                </div>
              </div>
              <div
                class="title flex-s size-s"
                v-for="item in showGoalsMeetingVOList_1[0]
                  .goalsClassifyVOList[5].goalsMeetingVOList"
              >
                <div class="border-s">
                  {{ item.meetingTheme }}
                </div>
                <div class="border-s">
                  {{ item.meetingNumber }}
                </div>
                <div class="border-s">
                  {{ item.recProvinceVal }} {{ item.recCityVal }}
                  {{ item.recCountyVal }}
                </div>
                <div class="border-s">
                  {{ item.address }}
                </div>
                <div class="border-s">
                  {{ item.meetingDate }}
                </div>
                <div class="border-s" style="border: none">
                  {{ item.remarks }}
                </div>
              </div>
            </div>
          </div>
          <!-- 月 创客空间 !-->
          <div
            class="table-lists"
            id="myImage6"
            v-show="navIndex == 0 && navIndex1 == 6"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_32") }}</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_33") }}</div>
              <div class="title">{{ $t("S_C_34") }}</div>
              <div class="title">{{ $t("S_C_35") }}(%)</div>
            </div>
            <div class="table-right">
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                          .totalAchieve
                          |_int}}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                        .totalRatio || 0
                        |_int }}</span>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_14") }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[0].m1
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[0].m2
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[0].m3
                      |_int}}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[0].m4
                      |_int}}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[1].m1 || 0
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[1].m2 || 0
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[1].m3 || 0
                      |_int}}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[1].m4 || 0
                      |_int}}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[2].m1 || 0
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[2].m2 || 0
                      |_int}}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[2].m3 || 0
                      |_int}}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[2].m4 || 0
                      |_int}}
                </div>
              </div>
            </div>
          </div>
          <!-- 月 超级工作室 !-->
          <div
            class="table-lists"
            id="myImage7"
            v-show="navIndex == 0 && navIndex1 == 7"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_8") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_10") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_34") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_35") }}(%)</div>
            </div>
            <div class="table-right">
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                        .totalRatio || 0
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_11") }}</div>
                <div class="border-s">{{ $t("S_C_12") }}</div>
                <div class="border-s">{{ $t("S_C_13") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_14") }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[0].m1
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[0].m2
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[0].m3
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[0].m4
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[1].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[1].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[1].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[1].m4 || 0
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[2].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[2].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[2].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_1[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[2].m4 || 0
                  }}
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 季度目标 -未提交 !-->
      <div
        class="goalsClassifyVOList1"
        v-if="showGoalsMeetingVOList_2 == false"
      >
        <el-form
          :model="formModel1"
          :rules="rules1"
          ref="loginForm1"
          :validate-on-rule-change="false"
        >
          <!-- 团队业绩 !-->
          <div class="table-lists" v-show="navIndex == 1 && navIndex1 == 0">
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_8") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_10") }}({{isOther()}})
              </div>
            </div>
            <div class="table-right">
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="totalAchieve_1">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel1.totalAchieve_1"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_39") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m1_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m2_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m3_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m4_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 团队业绩 !-->
          <div class="table-lists" v-show="navIndex == 1 && navIndex1 == 1">
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_15") }}({{isOther()}})
              </div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_16") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_17") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_10") }}({{isOther()}})
              </div>
            </div>
            <div class="table-right">
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="totalAchieve_2">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel1.totalAchieve_2"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="rightAchieve_2">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel1.rightAchieve_2"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_39") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        {{ $t("S_C_18") }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        {{ $t("S_C_19") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        {{ $t("S_C_18") }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        {{ $t("S_C_19") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        {{ $t("S_C_18") }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        {{ $t("S_C_19") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        {{ $t("S_C_18") }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        {{ $t("S_C_19") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml1_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.ml1_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr1_2" style="padding-left: 10px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.mr1_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml2_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.ml2_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr2_2" style="padding-left: 10px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.mr2_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml3_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.ml3_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr3_2" style="padding-left: 10px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.mr3_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml4_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.ml4_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr4_2" style="padding-left: 10px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.mr4_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 推荐业绩 !-->
          <div class="table-lists" v-show="navIndex == 1 && navIndex1 == 2">
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_8") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_10") }}({{isOther()}})
              </div>
            </div>
            <div class="table-right">
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="totalAchieve_3">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel1.totalAchieve_3"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_39") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m1_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m2_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m3_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m4_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 推荐人数 !-->
          <div class="table-lists" v-show="navIndex == 1 && navIndex1 == 3">
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_20") }}
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_21") }}({{isOther()}})
              </div>
            </div>
            <div class="table-right">
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="totalAchieve_4">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel1.totalAchieve_4"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_39") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m1_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m2_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m3_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m4_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- {{$t('S_C_23')}} !-->
          <div class="table-lists" v-show="navIndex == 1 && navIndex1 == 4">
            <div class="table-title">
              <div class="title" style="width: 180px">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_22") }}
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_23") }}
              </div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_24") }}
              </div>
              <div class="title" v-for="item in goalsMeetingVOList2">
                <el-row class="input-s style_s">
                  <el-col :span="22">
                    <el-form-item>
                      <el-input
                        type="text"
                        v-model="item.meetingType"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div
              class="table-right"
              style="width: auto; overflow: initial !important"
            >
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="4">
                    <el-form-item prop="totalAchieve_5">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        @blur="focus2"
                        v-model="formModel1.totalAchieve_5"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s">{{ $t("S_C_39") }}</div>
                <div class="border-s" style="border: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m1_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m2_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m3_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m4_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_25") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_26") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_27") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_28") }}
                </div>
                <div class="border-s" style="border-right: none">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_29") }}
                </div>
              </div>
              <div class="title flex-s" v-for="item in goalsMeetingVOList2">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input
                          type="text"
                          v-model="item.meetingNumber"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-cascader
                          class="area"
                          v-model="item.areaAddress"
                          @change="setAddress1($event, item)"
                          :options="areaData"
                          :props="regionParams"
                          :placeholder="$t('S_C_30')"
                        ></el-cascader>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.address"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.meetingTheme"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.remarks"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 月 会议报备 !-->
          <div class="table-lists" v-show="navIndex == 1 && navIndex1 == 5">
            <div class="table-title">
              <div class="title" style="width: 180px">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_22") }}
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_23") }}
              </div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_24") }}
              </div>
              <div class="title" v-for="item in goalsMeetingVOList2">
                <el-row class="input-s style_s">
                  <el-col :span="22">
                    <el-form-item>
                      <el-input v-model="item.meetingType"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div
              class="table-right"
              style="width: auto; overflow: initial !important"
            >
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="3" style="width: 180px">
                    <el-form-item prop="totalAchieve_5">
                      <el-input
                        type="number"
                        :max="10"
                        :min="1"
                        @blur="focus2"
                        disabled="disabled"
                        v-model="formModel1.totalAchieve_5"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s">{{ $t("S_C_39") }}</div>
                <div class="border-s" style="border: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_5">
                        <el-input
                          type="number"
                          disabled="disabled"
                          v-model="formModel1.m1_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_5">
                        <el-input
                          type="number"
                          disabled="disabled"
                          v-model="formModel1.m2_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_5">
                        <el-input
                          type="number"
                          disabled="disabled"
                          v-model="formModel1.m3_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_5">
                        <el-input
                          type="number"
                          disabled="disabled"
                          v-model="formModel1.m4_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_28") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_25") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_26") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_27") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_31") }}
                </div>
                <div class="border-s" style="border-right: none">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_29") }}
                </div>
              </div>
              <div class="title flex-s" v-for="item in goalsMeetingVOList2">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.meetingTheme"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="item.meetingNumber"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-cascader
                          class="area"
                          v-model="item.areaAddress"
                          @change="setAddress1($event, item)"
                          :options="areaData"
                          :props="regionParams"
                          :placeholder="$t('S_C_30')"
                        ></el-cascader>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.address"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-date-picker
                          value-format="yyyy-MM-dd HH:mm:ss"
                          type="date"
                          v-model="item.meetingDate"
                          @change="changes1($event, item)"
                        />
                        <!--                                            <el-input-->
                        <!--                                                v-model="item.meetingDate"></el-input>-->
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.remarks"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 月 创客空间 !-->
          <div class="table-lists" v-show="navIndex == 1 && navIndex1 == 6">
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_32") }}
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_33") }}
              </div>
            </div>
            <div class="table-right">
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="totalAchieve_7">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel1.totalAchieve_7"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_39") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m1_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m2_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m3_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m4_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 月 超级工作室 !-->
          <div class="table-lists" v-show="navIndex == 1 && navIndex1 == 7">
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_8") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_10") }}({{isOther()}})
              </div>
            </div>
            <div class="table-right">
              <div class="table-width title">
                <el-row class="input-s style_s">
                  <el-col :span="5">
                    <el-form-item prop="totalAchieve_8">
                      <el-input
                        type="text"
                        oninput="value=value.replace(/^0| [^0-9]/g, '')"
                        v-model="formModel1.totalAchieve_8"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_39") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m1_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m2_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m3_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel1.m4_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 季度目标 -已提交 !-->
      <div
        class="goalsClassifyVOList1"
        v-if="showGoalsMeetingVOList_2 != false"
      >
        <el-form>
          <!-- 月 团队业绩 !-->
          <div
            class="table-lists download"
            id="myImage10"
            v-show="navIndex == 1 && navIndex1 == 0"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_8") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_10") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_34") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_35") }}(%)</div>
            </div>
            <div class="table-right">
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                        .totalRatio || 0
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_39") }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[0].m1
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[0].m2
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[0].m3
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[0].m4
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[1].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[1].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[1].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[1].m4 || 0
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[2].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[2].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[2].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[0]
                      .goalsRowDetailVOList[2].m4 || 0
                  }}
                </div>
              </div>
            </div>
          </div>
          <a id="link"></a>
          <!-- 月 安置业绩 !-->
          <div
            class="table-lists"
            id="myImage11"
            v-show="navIndex == 1 && navIndex1 == 1"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_15") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_16") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_17") }}</div>
              <div class="title">{{ $t("S_C_10") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_34") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_35") }}(%)</div>
            </div>
            <div class="table-right">
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                        .totalRatio || 0
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .rightAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                        .rightRatio || 0
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_39") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{ $t("S_C_18") }}
                    </el-col>
                    <el-col :span="12">
                      {{ $t("S_C_19") }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{ $t("S_C_18") }}
                    </el-col>
                    <el-col :span="12">
                      {{ $t("S_C_19") }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{ $t("S_C_18") }}
                    </el-col>
                    <el-col :span="12">
                      {{ $t("S_C_19") }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{ $t("S_C_18") }}
                    </el-col>
                    <el-col :span="12">
                      {{ $t("S_C_19") }}
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml1 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr1 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml2 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr2 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml3 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr3 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml4 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr4 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml5 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr5 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml6 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr5 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml7 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr7
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml8 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr8 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml9 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr9 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml10 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr10 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml11 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr11 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none">
                  <el-row>
                    <el-col :span="12" style="border-right: 1px solid #000">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].ml12 || 0
                      }}
                    </el-col>
                    <el-col :span="12">
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[1]
                          .goalsRowDetailVOList[0].mr12 || 0
                      }}
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 月 推荐业绩 !-->
          <div
            class="table-lists"
            id="myImage12"
            v-show="navIndex == 1 && navIndex1 == 2"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_8") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_10") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_34") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_35") }}(%)</div>
            </div>
            <div class="table-right">
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                        .totalRatio || 0
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_39") }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[0].m1
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[0].m2
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[0].m3
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[0].m4
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[1].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[1].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[1].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[1].m4 || 0
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[2].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[2].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[2].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[2]
                      .goalsRowDetailVOList[2].m4 || 0
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- 月 推荐人数 !-->
          <div
            class="table-lists"
            id="myImage13"
            v-show="navIndex == 1 && navIndex1 == 3"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_8") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_10") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_34") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_35") }}(%)</div>
            </div>
            <div class="table-right">
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                        .totalRatio || 0
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_39") }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[0].m1
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[0].m2
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[0].m3
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[0].m4
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[1].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[1].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[1].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[1].m4 || 0
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[2].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[2].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[2].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[3]
                      .goalsRowDetailVOList[2].m4 || 0
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- 月 {{$t('S_C_23')}} !-->
          <div
            class="table-lists"
            id="myImage14"
            v-show="navIndex == 1 && navIndex1 == 4"
          >
            <div class="table-title">
              <div class="title" style="width: 180px">{{ $t("S_C_22") }}</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_23") }}</div>
              <div class="title">{{ $t("S_C_24") }}</div>
              <div
                class="title size-s"
                v-for="item in showGoalsMeetingVOList_2[0]
                  .goalsClassifyVOList[4].goalsMeetingVOList"
              >
                {{ item.meetingType }}
              </div>
            </div>
            <div
              class="table-right"
              style="width: auto; overflow: initial !important"
            >
              <div class="title">
                <div class="table-width title flex-autos">
                  <div class="width-auto-s" style="border-right: none">
                    <div class="left-s">
                      <div>
                        {{
                          showGoalsMeetingVOList_2[0].goalsClassifyVOList[4]
                            .totalAchieve
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s">{{ $t("S_C_39") }}</div>
                <div class="border-s" style="border: none"></div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[4]
                      .goalsRowDetailVOList[0].m1
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[4]
                      .goalsRowDetailVOList[0].m2
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[4]
                      .goalsRowDetailVOList[0].m3
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[4]
                      .goalsRowDetailVOList[0].m4
                  }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_25") }}</div>
                <div class="border-s">{{ $t("S_C_26") }}</div>
                <div class="border-s">{{ $t("S_C_27") }}</div>
                <div class="border-s">{{ $t("S_C_28") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_29") }}
                </div>
              </div>
              <div
                class="title flex-s size-s"
                v-for="item in showGoalsMeetingVOList_2[0]
                  .goalsClassifyVOList[4].goalsMeetingVOList"
              >
                <div class="border-s">
                  {{ item.meetingNumber }}
                </div>
                <div class="border-s">
                  {{ item.recProvinceVal }} {{ item.recCityVal }}
                  {{ item.recCountyVal }}
                </div>
                <div class="border-s">
                  {{ item.address }}
                </div>
                <div class="border-s">
                  {{ item.meetingTheme }}
                </div>
                <div class="border-s" style="border: none">
                  {{ item.remarks }}
                </div>
              </div>
            </div>
          </div>
          <!-- 月 会议报备 !-->
          <div
            class="table-lists"
            id="myImage15"
            v-show="navIndex == 1 && navIndex1 == 5"
          >
            <div class="table-title">
              <div class="title" style="width: 180px">{{ $t("S_C_22") }}</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_23") }}</div>
              <div class="title">{{ $t("S_C_24") }}</div>
              <div
                class="title size-s"
                v-for="item in showGoalsMeetingVOList_2[0]
                  .goalsClassifyVOList[5].goalsMeetingVOList"
              >
                {{ item.meetingType }}
              </div>
            </div>
            <div
              class="table-right"
              style="width: auto; overflow: initial !important"
            >
              <div class="title">
                <div class="table-width title flex-autos">
                  <div class="width-auto-s" style="border-right: none">
                    <div class="left-s">
                      <div>
                        {{
                          showGoalsMeetingVOList_2[0].goalsClassifyVOList[5]
                            .totalAchieve
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s">{{ $t("S_C_39") }}</div>
                <div class="border-s" style="border: none"></div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[5]
                      .goalsRowDetailVOList[0].m1
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[5]
                      .goalsRowDetailVOList[0].m2
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[5]
                      .goalsRowDetailVOList[0].m3
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[5]
                      .goalsRowDetailVOList[0].m4
                  }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_28") }}</div>
                <div class="border-s">{{ $t("S_C_25") }}</div>
                <div class="border-s">{{ $t("S_C_26") }}</div>
                <div class="border-s">{{ $t("S_C_27") }}</div>
                <div class="border-s">{{ $t("S_C_31") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_29") }}
                </div>
              </div>
              <div
                class="title flex-s size-s"
                v-for="item in showGoalsMeetingVOList_2[0]
                  .goalsClassifyVOList[5].goalsMeetingVOList"
              >
                <div class="border-s">
                  {{ item.meetingTheme }}
                </div>
                <div class="border-s">
                  {{ item.meetingNumber }}
                </div>
                <div class="border-s">
                  {{ item.recProvinceVal }} {{ item.recCityVal }}
                  {{ item.recCountyVal }}
                </div>
                <div class="border-s">
                  {{ item.address }}
                </div>
                <div class="border-s">
                  {{ item.meetingDate }}
                </div>
                <div class="border-s" style="border: none">
                  {{ item.remarks }}
                </div>
              </div>
            </div>
          </div>
          <!-- 月 创客空间 !-->
          <div
            class="table-lists"
            id="myImage16"
            v-show="navIndex == 1 && navIndex1 == 6"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_32") }}</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_33") }}</div>
              <div class="title">{{ $t("S_C_34") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_35") }}(%)</div>
            </div>
            <div class="table-right">
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                        .totalRatio || 0
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_39") }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[0].m1
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[0].m2
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[0].m3
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[0].m4
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[1].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[1].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[1].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[1].m4 || 0
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[2].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[2].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[2].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[6]
                      .goalsRowDetailVOList[2].m4 || 0
                  }}
                </div>
              </div>
            </div>
          </div>
          <!-- 月 超级工作室 !-->
          <div
            class="table-lists"
            id="myImage17"
            v-show="navIndex == 1 && navIndex1 == 7"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_8") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_10") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_34") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_35") }}(%)</div>
            </div>
            <div class="table-right">
              <div class="table-width title flex-autos">
                <div class="width-auto-s">
                  <div class="left-s">
                    <div>
                      {{
                        showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s" style="border-right: none">
                  <div class="right-s">
                    {{ $t("S_C_35") }}(%)
                    <span>{{
                      showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                        .totalRatio || 0
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">{{ $t("S_C_36") }}</div>
                <div class="border-s">{{ $t("S_C_37") }}</div>
                <div class="border-s">{{ $t("S_C_38") }}</div>
                <div class="border-s" style="border-right: none">
                  {{ $t("S_C_39") }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[0].m1
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[0].m2
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[0].m3
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[0].m4
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[1].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[1].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[1].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[1].m4 || 0
                  }}
                </div>
              </div>
              <div class="title flex-s size-s">
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[2].m1 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[2].m2 || 0
                  }}
                </div>
                <div class="border-s">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[2].m3 || 0
                  }}
                </div>
                <div class="border-s" style="border-right: none">
                  {{
                    showGoalsMeetingVOList_2[0].goalsClassifyVOList[7]
                      .goalsRowDetailVOList[2].m4 || 0
                  }}
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 年度目标 -未提交 !-->
      <div
        class="goalsClassifyVOList1"
        v-if="showGoalsMeetingVOList_3 == false"
      >
        <el-form
          :model="formModel2"
          :rules="rules2"
          ref="loginForm2"
          :validate-on-rule-change="false"
        >
          <!-- 团队业绩 !-->
          <div class="table-lists" v-show="navIndex == 2 && navIndex1 == 0">
            <div class="table-title">
              <div class="title" style="width: 180px">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_8") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
            </div>
            <div
              class="table-right width_s"
              style="overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="table-width title">
                  <el-row class="input-s style_s">
                    <el-col :span="5">
                      <el-form-item prop="totalAchieve_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.totalAchieve_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <!--                            <div class="border-s">-->
                <!--                                <el-row class="input-s">-->
                <!--                                    <el-col :span="21">-->
                <!--                                        <el-form-item prop="m1_5">-->
                <!--                                            <el-input-->
                <!--                                                type="text"-->
                <!--                                                oninput="value=value.replace(/^0| [^0-9]/g, '')"-->
                <!--                                                v-model="formModel2.m1_5"></el-input>-->
                <!--                                        </el-form-item>-->
                <!--                                    </el-col>-->
                <!--                                </el-row>-->
                <!--                            </div>-->
                <!--                            <div class="border-s">-->
                <!--                            <el-row class="input-s">-->
                <!--                                <el-col :span="21">-->
                <!--                                    <el-form-item prop="m1_1">-->
                <!--                                        <el-input-->
                <!--                                            type="text"-->
                <!--                                            oninput="value=value.replace(/^0| [^0-9]/g, '')"-->
                <!--                                            v-model="formModel.m1_1"></el-input>-->
                <!--                                    </el-form-item>-->
                <!--                                </el-col>-->
                <!--                            </el-row>-->
                <!--                            </div>-->
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">
                    <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                    >{{ $t("S_C_10") }}
                  </div>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m1_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m2_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m3_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m4_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m5_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m5_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m6_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m6_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m7_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m7_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m8_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m8_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m9_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m9_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m10_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m10_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m11_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m11_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m12_1">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m12_1"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
            </div>
          </div>
          <!-- 安置业绩 !-->
          <div class="table-lists" v-show="navIndex == 2 && navIndex1 == 1">
            <div class="table-title">
              <div class="title" style="width: 180px">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_15") }}({{isOther()}})
              </div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_16") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_17") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
            </div>
            <div
              class="table-right width_s"
              style="overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="table-width title">
                  <el-row class="input-s style_s">
                    <el-col :span="5">
                      <el-form-item prop="totalAchieve_2">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.totalAchieve_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s">
                <div class="table-width title">
                  <el-row class="input-s style_s">
                    <el-col :span="5">
                      <el-form-item prop="totalAchieve_2">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.rightAchieve_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s"></div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s text-color">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        {{ $t("S_C_18") }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        {{ $t("S_C_19") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml1_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.ml1_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr1_2" style="margin-left: 15px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.mr1_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml2_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.ml2_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr2_2" style="margin-left: 15px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.mr2_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml3_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.ml3_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr3_2" style="margin-left: 15px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.mr3_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml4_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.ml4_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr4_2" style="margin-left: 15px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.mr4_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml5_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.ml5_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr5_2" style="margin-left: 15px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.mr5_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml6_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.ml6_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr6_2" style="margin-left: 15px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.mr6_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml7_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.ml7_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr7_2" style="margin-left: 15px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.mr7_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml8_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.ml8_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr8_2" style="margin-left: 15px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.mr8_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml9_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.ml9_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr9_2" style="margin-left: 15px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.mr9_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml10_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.ml10_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr10_2" style="margin-left: 15px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.mr10_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml11_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.ml11_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr11_2" style="margin-left: 15px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.mr11_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        prop="ml12_2"
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.ml12_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item prop="mr12_2" style="margin-left: 15px">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.mr12_2"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
            </div>
          </div>
          <!-- 团队业绩 !-->
          <div class="table-lists" v-show="navIndex == 2 && navIndex1 == 2">
            <div class="table-title">
              <div class="title" style="width: 180px">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_8") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
            </div>
            <div
              class="table-right width_s"
              style="overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="table-width title">
                  <el-row class="input-s style_s">
                    <el-col :span="5">
                      <el-form-item prop="totalAchieve_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.totalAchieve_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <!--                            <div class="border-s">-->
                <!--                                <el-row class="input-s">-->
                <!--                                    <el-col :span="21">-->
                <!--                                        <el-form-item prop="m1_5">-->
                <!--                                            <el-input-->
                <!--                                                type="text"-->
                <!--                                                oninput="value=value.replace(/^0| [^0-9]/g, '')"-->
                <!--                                                v-model="formModel2.m1_5"></el-input>-->
                <!--                                        </el-form-item>-->
                <!--                                    </el-col>-->
                <!--                                </el-row>-->
                <!--                            </div>-->
                <!--                            <div class="border-s">-->
                <!--                            <el-row class="input-s">-->
                <!--                                <el-col :span="21">-->
                <!--                                    <el-form-item prop="m1_1">-->
                <!--                                        <el-input-->
                <!--                                            type="text"-->
                <!--                                            oninput="value=value.replace(/^0| [^0-9]/g, '')"-->
                <!--                                            v-model="formModel.m1_1"></el-input>-->
                <!--                                    </el-form-item>-->
                <!--                                </el-col>-->
                <!--                            </el-row>-->
                <!--                            </div>-->
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">
                    <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                    >{{ $t("S_C_10") }}
                  </div>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m1_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m2_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m3_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m4_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m5_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m5_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m6_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m6_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m7_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m7_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m8_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m8_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m9_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m9_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m10_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m10_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m11_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m11_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m12_3">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m12_3"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
            </div>
          </div>
          <!-- 推荐人数 !-->
          <div class="table-lists" v-show="navIndex == 2 && navIndex1 == 3">
            <div class="table-title">
              <div class="title" style="width: 180px">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_20") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
            </div>
            <div
              class="table-right width_s"
              style="overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="table-width title">
                  <el-row class="input-s style_s">
                    <el-col :span="5">
                      <el-form-item prop="totalAchieve_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.totalAchieve_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <!--                            <div class="border-s">-->
                <!--                                <el-row class="input-s">-->
                <!--                                    <el-col :span="21">-->
                <!--                                        <el-form-item prop="m1_5">-->
                <!--                                            <el-input-->
                <!--                                                type="text"-->
                <!--                                                oninput="value=value.replace(/^0| [^0-9]/g, '')"-->
                <!--                                                v-model="formModel2.m1_5"></el-input>-->
                <!--                                        </el-form-item>-->
                <!--                                    </el-col>-->
                <!--                                </el-row>-->
                <!--                            </div>-->
                <!--                            <div class="border-s">-->
                <!--                            <el-row class="input-s">-->
                <!--                                <el-col :span="21">-->
                <!--                                    <el-form-item prop="m1_1">-->
                <!--                                        <el-input-->
                <!--                                            type="text"-->
                <!--                                            oninput="value=value.replace(/^0| [^0-9]/g, '')"-->
                <!--                                            v-model="formModel.m1_1"></el-input>-->
                <!--                                    </el-form-item>-->
                <!--                                </el-col>-->
                <!--                            </el-row>-->
                <!--                            </div>-->
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">
                    <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                    >{{ $t("S_C_21") }}
                  </div>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m1_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m2_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m3_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m4_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m5_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m5_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m6_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m6_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m7_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m7_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m8_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m8_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m9_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m9_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m10_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m10_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m11_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m11_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m12_4">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m12_4"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
            </div>
          </div>
          <!-- {{$t('S_C_23')}} !-->
          <div class="table-lists" v-show="navIndex == 2 && navIndex1 == 4">
            <div class="table-title">
              <div class="title" style="width: 180px">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_22") }}
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_24") }}
              </div>
              <div class="title" v-for="item in goalsMeetingVOList3">
                <el-row class="input-s style_s">
                  <el-col :span="22">
                    <el-form-item>
                      <el-input
                        type="text"
                        v-model="item.meetingType"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div
              class="table-right"
              style="width: auto; overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="totalAchieve_5">
                        <el-input
                          type="text"
                          @blur="focus3"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.totalAchieve_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">
                    <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                    >{{ $t("S_C_23") }}
                  </div>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m1_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m2_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m3_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m4_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m5_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m5_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m6_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m6_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m7_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m7_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m8_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m8_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m9_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m9_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m10_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m10_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m11_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m11_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m12_5">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m12_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_25") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_26") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_27") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_28") }}
                </div>
                <div class="border-s" style="border-right: none">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_29") }}
                </div>
              </div>
              <div class="title flex-s" v-for="item in goalsMeetingVOList3">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input
                          type="text"
                          v-model="item.meetingNumber"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-cascader
                          class="area"
                          v-model="item.areaAddress"
                          @change="setAddress1($event, item)"
                          :options="areaData"
                          :props="regionParams"
                          :placeholder="$t('S_C_30')"
                        ></el-cascader>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.address"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.meetingTheme"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.remarks"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 会议报备 !-->
          <div class="table-lists" v-show="navIndex == 2 && navIndex1 == 5">
            <div class="table-title">
              <div class="title" style="width: 180px">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_22") }}
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_24") }}
              </div>
              <div class="title" v-for="item in goalsMeetingVOList3">
                <el-row class="input-s style_s">
                  <el-col :span="22">
                    <el-form-item>
                      <el-input
                        type="text"
                        v-model="item.meetingType"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div
              class="table-right"
              style="width: auto; overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="totalAchieve_5">
                        <el-input
                          type="text"
                          @blur="focus3"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.totalAchieve_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">
                    <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                    >{{ $t("S_C_23") }}
                  </div>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_5">
                        <el-input
                          type="text"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m1_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_5">
                        <el-input
                          type="text"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m2_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_5">
                        <el-input
                          type="text"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m3_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_5">
                        <el-input
                          type="text"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m4_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m5_5">
                        <el-input
                          type="text"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m5_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m6_5">
                        <el-input
                          type="text"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m6_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m7_5">
                        <el-input
                          type="text"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m7_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m8_5">
                        <el-input
                          type="text"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m8_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m9_5">
                        <el-input
                          type="text"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m9_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m10_5">
                        <el-input
                          type="text"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m10_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m11_5">
                        <el-input
                          type="text"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m11_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m12_5">
                        <el-input
                          type="text"
                          disabled="disabled"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m12_5"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s"></div>
                <div class="border-s"></div>
                <div class="border-s"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_28") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_25") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_26") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_27") }}
                </div>
                <div class="border-s">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_31") }}
                </div>
                <div class="border-s" style="border-right: none">
                  <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                  >{{ $t("S_C_29") }}
                </div>
              </div>
              <div class="title flex-s" v-for="item in goalsMeetingVOList3">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.meetingTheme"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input
                          type="text"
                          v-model="item.meetingNumber"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-cascader
                          class="area"
                          v-model="item.areaAddress"
                          @change="setAddress1($event, item)"
                          :options="areaData"
                          :props="regionParams"
                          :placeholder="$t('S_C_30')"
                        ></el-cascader>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.address"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-date-picker
                          type="date"
                          v-model="item.meetingDate"
                          @change="changes($event, item)"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border: none">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item>
                        <el-input v-model="item.remarks"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 创客空间 !-->
          <div class="table-lists" v-show="navIndex == 2 && navIndex1 == 6">
            <div class="table-title">
              <div class="title" style="width: 180px">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_32") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
            </div>
            <div
              class="table-right width_s"
              style="overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="table-width title">
                  <el-row class="input-s style_s">
                    <el-col :span="5">
                      <el-form-item prop="totalAchieve_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.totalAchieve_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">
                    <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                    >{{ $t("S_C_33") }}
                  </div>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m1_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m2_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m3_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m4_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m5_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m5_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m6_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m6_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m7_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m7_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m8_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m8_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m9_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m9_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m10_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m10_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m11_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m11_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m12_7">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m12_7"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
            </div>
          </div>
          <!-- 创客空间 !-->
          <div class="table-lists" v-show="navIndex == 2 && navIndex1 == 7">
            <div class="table-title">
              <div class="title" style="width: 180px">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_8") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
            </div>
            <div
              class="table-right width_s"
              style="overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="table-width title">
                  <el-row class="input-s style_s">
                    <el-col :span="5">
                      <el-form-item prop="totalAchieve_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.totalAchieve_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">
                    <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                    >{{ $t("S_C_10") }}({{isOther()}})
                  </div>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m1_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m1_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m2_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m2_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m3_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m3_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m4_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m4_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m5_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m5_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m6_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m6_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m7_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m7_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m8_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m8_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m9_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m9_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m10_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m10_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m11_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m11_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <el-row class="input-s">
                    <el-col :span="21">
                      <el-form-item prop="m11_8">
                        <el-input
                          type="text"
                          oninput="value=value.replace(/^0| [^0-9]/g, '')"
                          v-model="formModel2.m12_8"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 年度目标 -已提交 !-->
      <div
        class="goalsClassifyVOList1"
        v-if="showGoalsMeetingVOList_3 != false"
      >
        <el-form>
          <!-- 团队业绩 !-->
          <div
            class="table-lists"
            id="myImage20"
            v-show="navIndex == 2 && navIndex1 == 0"
          >
            <div class="table-title">
              <div class="title" style="width: 180px">{{ $t("S_C_8") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
            </div>
            <div
              class="table-right width_s"
              style="overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>
                      {{
                        showGoalsMeetingVOList_3[0].goalsClassifyVOList[0]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s border-none">
                  <div class="title title-left">{{ $t("S_C_40") }}(%)</div>
                  <div class="left-s2">
                    <div>
                      {{
                        showGoalsMeetingVOList_3[0].goalsClassifyVOList[0]
                          .totalRatio || 0
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">{{ $t("S_C_10") }}</div>
                </div>
                <div class="border-s">
                  <div class="title">{{ $t("S_C_34") }}</div>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div
                class="title flex-s"
                v-for="item in showGoalsMeetingVOList_3[0]
                  .goalsClassifyVOList[0].monthDetailVOList"
              >
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>{{ item.leftAchieve }}</div>
                  </div>
                </div>
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>{{ item.leftRatio || 0 }}</div>
                  </div>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
            </div>
          </div>
          <!-- 安置业绩 !-->
          <div
            class="table-lists"
            id="myImage21"
            v-show="navIndex == 2 && navIndex1 == 1"
          >
            <div class="table-title">
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_15") }}({{isOther()}})
              </div>
              <div class="title">
                <span style="color: #c8161d; margin: 5px 3px 0 0">*</span
                >{{ $t("S_C_16") }}({{isOther()}})
              </div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title">{{ $t("S_C_17") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
            </div>
            <div
              class="table-right width_s"
              style="overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="width-autos title right-border">
                  <div>
                    {{
                      showGoalsMeetingVOList_3[0].goalsClassifyVOList[1]
                        .totalAchieve
                    }}
                  </div>
                </div>
                <div class="width-auto-s border-none">
                  <div class="title title-left">{{ $t("S_C_41") }}(%)</div>
                  <div class="left-s2">
                    <div>
                      {{
                        showGoalsMeetingVOList_3[0].goalsClassifyVOList[1]
                          .totalRatio
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="width-autos title right-border">
                  <div>
                    {{
                      showGoalsMeetingVOList_3[0].goalsClassifyVOList[1]
                        .totalAchieve
                    }}
                  </div>
                </div>
                <div class="width-auto-s border-none">
                  <div class="title title-left">{{ $t("S_C_42") }}(%)</div>
                  <div class="left-s2">
                    <div>
                      {{
                        showGoalsMeetingVOList_3[0].goalsClassifyVOList[1]
                          .rightRatio
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s width-280"></div>
                <div class="border-s width-280">
                  {{ $t("S_C_34") }}
                </div>
                <div class="border-s width-280 border-none">
                  {{ $t("S_C_35") }}
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s text-color width-280">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        {{ $t("S_C_18") }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        {{ $t("S_C_19") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s text-color width-280">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        {{ $t("S_C_18") }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        {{ $t("S_C_19") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s text-color width-280 border-none">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 10px;
                        "
                      >
                        {{ $t("S_C_18") }}
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item>
                        {{ $t("S_C_19") }}
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div
                class="title flex-s"
                v-for="(item, index) in showGoalsMeetingVOList_3[0]
                  .goalsClassifyVOList[1].monthDetailVOList"
              >
                <div class="border-s width-280">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <div>{{ item.leftAchieve }}</div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item style="padding-right: 5px">
                        <div>{{ item.rightAchieve }}</div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s width-280">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <div>{{ item.leftRealAchieve || 0 }}</div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item style="padding-right: 5px">
                        <div>{{ item.rightRealAchieve || 0 }}</div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
                <div class="border-s width-280 border-none">
                  <el-row class="input-s">
                    <el-col :span="12">
                      <el-form-item
                        style="
                          border-right: 1px solid #333;
                          padding-right: 15px;
                        "
                      >
                        <div>{{ item.leftRadio || 0 }}</div>
                      </el-form-item>
                    </el-col>
                    <el-col :span="12">
                      <el-form-item style="padding-right: 5px">
                        <div>{{ item.rightRadio || 0 }}</div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
          <!-- 推荐业绩 !-->
          <div
            class="table-lists"
            id="myImage22"
            v-show="navIndex == 2 && navIndex1 == 2"
          >
            <div class="table-title">
              <div class="title">{{ $t("S_C_8") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
            </div>
            <div
              class="table-right width_s"
              style="overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>
                      {{
                        showGoalsMeetingVOList_3[0].goalsClassifyVOList[2]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s border-none">
                  <div class="title title-left">{{ $t("S_C_40") }}(%)</div>
                  <div class="left-s2">
                    <div>
                      {{
                        showGoalsMeetingVOList_3[0].goalsClassifyVOList[2]
                          .totalRatio || 0
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">{{ $t("S_C_10") }}</div>
                </div>
                <div class="border-s">
                  <div class="title">{{ $t("S_C_34") }}</div>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div
                class="title flex-s"
                v-for="item in showGoalsMeetingVOList_3[0]
                  .goalsClassifyVOList[2].monthDetailVOList"
              >
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>{{ item.leftAchieve }}</div>
                  </div>
                </div>
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>{{ item.leftRatio || 0 }}</div>
                  </div>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
            </div>
          </div>
          <!-- 推荐人数 !-->
          <div
            class="table-lists"
            id="myImage23"
            v-show="navIndex == 2 && navIndex1 == 3"
          >
            <div class="table-title">
              <div class="title" style="width: 180px">{{ $t("S_C_20") }}</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
            </div>
            <div
              class="table-right width_s"
              style="overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>
                      {{
                        showGoalsMeetingVOList_3[0].goalsClassifyVOList[3]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s border-none">
                  <div class="title title-left">{{ $t("S_C_43") }}(%)</div>
                  <div class="left-s2">
                    <div>
                      {{
                        showGoalsMeetingVOList_3[0].goalsClassifyVOList[3]
                          .totalRatio || 0
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">{{ $t("S_C_21") }}</div>
                </div>
                <div class="border-s">
                  <div class="title">{{ $t("S_C_34") }}</div>
                </div>
                <div class="border-fs" style="border-right: none"></div>
              </div>
              <div
                class="title flex-s"
                v-for="item in showGoalsMeetingVOList_3[0]
                  .goalsClassifyVOList[3].monthDetailVOList"
              >
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>{{ item.leftAchieve }}</div>
                  </div>
                </div>
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>{{ item.leftRatio || 0 }}</div>
                  </div>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
            </div>
          </div>
          <!-- {{$t('S_C_23')}}!-->
          <div
            class="table-lists"
            id="myImage24"
            v-show="navIndex == 2 && navIndex1 == 4"
          >
            <div class="table-title">
              <div class="title" style="width: 180px">{{ $t("S_C_22") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
              <div class="title">{{ $t("S_C_24") }}</div>
              <div
                class="title"
                v-for="item in showGoalsMeetingVOList_3[0]
                  .goalsClassifyVOList[4].goalsMeetingVOList"
              >
                {{ item.meetingType }}
              </div>
            </div>
            <div class="table-right" style="overflow: initial !important">
              <div class="title flex-s">
                <div class="width-table title right-border center">
                  <div>
                    {{
                      showGoalsMeetingVOList_3[0].goalsClassifyVOList[4]
                        .totalAchieve
                    }}
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">{{ $t("S_C_23") }}</div>
                </div>
              </div>
              <div
                class="title flex-s"
                v-for="item in showGoalsMeetingVOList_3[0]
                  .goalsClassifyVOList[4].monthDetailVOList"
              >
                <div class="width-table title right-border center">
                  <div>{{ item.leftAchieve }}</div>
                </div>
              </div>
              <div class="title flex-s width-auto-center">
                <div class="width-table title right-border center">
                  {{ $t("S_C_25") }}
                </div>
                <div class="width-table title right-border center">
                  {{ $t("S_C_26") }}
                </div>
                <div class="width-table title right-border center">
                  {{ $t("S_C_27") }}
                </div>
                <div class="width-table title right-border center">
                  {{ $t("S_C_28") }}
                </div>
                <div class="width-table title right-border center border-none">
                  {{ $t("S_C_29") }}
                </div>
              </div>
              <div
                class="title flex-s width-auto-center"
                v-for="item in showGoalsMeetingVOList_3[0]
                  .goalsClassifyVOList[4].goalsMeetingVOList"
              >
                <div class="width-table title right-border center">
                  {{ item.meetingNumber }}
                </div>
                <div class="width-table title right-border center">
                  {{ item.recProvinceVal }}{{ item.recCityVal
                  }}{{ item.recCountyVal }}
                </div>
                <div class="width-table title right-border center">
                  {{ item.address }}
                </div>
                <div class="width-table title right-border center">
                  {{ item.meetingTheme }}
                </div>
                <div class="width-table title right-border center border-none">
                  {{ item.remarks }}
                </div>
              </div>
            </div>
          </div>
          <!-- {{$t('S_C_23')}} !-->
          <div
            class="table-lists"
            id="myImage25"
            v-show="navIndex == 2 && navIndex1 == 5"
          >
            <div class="table-title">
              <div class="title" style="width: 180px">{{ $t("S_C_22") }}</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
              <div class="title">{{ $t("S_C_24") }}</div>
              <div
                class="title"
                v-for="item in showGoalsMeetingVOList_3[0]
                  .goalsClassifyVOList[5].goalsMeetingVOList"
              >
                {{ item.meetingType }}
              </div>
            </div>
            <div class="table-right" style="overflow: initial !important">
              <div class="title flex-s">
                <div class="width-table title right-border center">
                  <div>
                    {{
                      showGoalsMeetingVOList_3[0].goalsClassifyVOList[5]
                        .totalAchieve
                    }}
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">{{ $t("S_C_23") }}</div>
                </div>
              </div>
              <div
                class="title flex-s"
                v-for="item in showGoalsMeetingVOList_3[0]
                  .goalsClassifyVOList[5].monthDetailVOList"
              >
                <div class="width-table title right-border center">
                  <div>{{ item.leftAchieve }}</div>
                </div>
              </div>
              <div class="title flex-s width-auto-center">
                <div class="width-table title right-border center">
                  {{ $t("S_C_28") }}
                </div>
                <div class="width-table title right-border center">
                  {{ $t("S_C_25") }}
                </div>
                <div class="width-table title right-border center">
                  {{ $t("S_C_26") }}
                </div>
                <div class="width-table title right-border center">
                  {{ $t("S_C_27") }}
                </div>
                <div class="width-table title right-border center">
                  {{ $t("S_C_31") }}
                </div>
                <div class="width-table title right-border center border-none">
                  {{ $t("S_C_29") }}
                </div>
              </div>
              <div
                class="title flex-s width-auto-center"
                v-for="item in showGoalsMeetingVOList_3[0]
                  .goalsClassifyVOList[4].goalsMeetingVOList"
              >
                <div class="width-table title right-border center">
                  {{ item.meetingTheme }}
                </div>
                <div class="width-table title right-border center">
                  {{ item.meetingNumber }}
                </div>
                <div class="width-table title right-border center">
                  {{ item.recProvinceVal }}{{ item.recCityVal
                  }}{{ item.recCountyVal }}
                </div>
                <div class="width-table title right-border center">
                  {{ item.address }}
                </div>
                <div class="width-table title right-border center">
                  {{ item.meetingDate }}
                </div>
                <div class="width-table title right-border center border-none">
                  {{ item.remarks }}
                </div>
              </div>
            </div>
          </div>
          <!-- 创客空间 !-->
          <div
            class="table-lists"
            id="myImage26"
            v-show="navIndex == 2 && navIndex1 == 6"
          >
            <div class="table-title">
              <div class="title" style="width: 180px">{{ $t("S_C_32") }}</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
            </div>
            <div
              class="table-right width_s"
              style="overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>
                      {{
                        showGoalsMeetingVOList_3[0].goalsClassifyVOList[6]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s border-none">
                  <div class="title title-left">{{ $t("S_C_44") }}(%)</div>
                  <div class="left-s2">
                    <div>
                      {{
                        showGoalsMeetingVOList_3[0].goalsClassifyVOList[6]
                          .totalRatio || 0
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">{{ $t("S_C_33") }}</div>
                </div>
                <div class="border-s">
                  <div class="title">{{ $t("S_C_34") }}</div>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div
                class="title flex-s"
                v-for="item in showGoalsMeetingVOList_3[0]
                  .goalsClassifyVOList[6].monthDetailVOList"
              >
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>{{ item.leftAchieve }}</div>
                  </div>
                </div>
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>{{ item.leftRatio || 0 }}</div>
                  </div>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
            </div>
          </div>
          <!-- 超级工作室 !-->
          <div
            class="table-lists"
            id="myImage27"
            v-show="navIndex == 2 && navIndex1 == 7"
          >
            <div class="table-title">
              <div class="title" style="width: 180px">{{ $t("S_C_8") }}({{isOther()}})</div>
              <div class="title">{{ $t("S_C_9") }}</div>
              <div class="title" v-for="item in typeList">{{ item }}</div>
            </div>
            <div
              class="table-right width_s"
              style="overflow: initial !important"
            >
              <div class="title flex-s">
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>
                      {{
                        showGoalsMeetingVOList_3[0].goalsClassifyVOList[7]
                          .totalAchieve
                      }}
                    </div>
                  </div>
                </div>
                <div class="width-auto-s border-none">
                  <div class="title title-left">{{ $t("S_C_40") }}(%)</div>
                  <div class="left-s2">
                    <div>
                      {{
                        showGoalsMeetingVOList_3[0].goalsClassifyVOList[7]
                          .totalRatio || 0
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="title flex-s">
                <div class="border-s">
                  <div class="title">{{ $t("S_C_10") }}({{isOther()}})</div>
                </div>
                <div class="border-s">
                  <div class="title">{{ $t("S_C_34") }}</div>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
              <div
                class="title flex-s"
                v-for="item in showGoalsMeetingVOList_3[0]
                  .goalsClassifyVOList[7].monthDetailVOList"
              >
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>{{ item.leftAchieve }}</div>
                  </div>
                </div>
                <div class="width-table title right-border">
                  <div class="left-s1">
                    <div>{{ item.leftRatio || 0 }}</div>
                  </div>
                </div>
                <div class="border-s" style="border-right: none"></div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
    <!-- 月目标确认 !-->
    <div
      class="submit-s"
      v-if="showGoalsMeetingVOList_1 == false && navIndex == 0"
      @click="sbumits"
    >
      {{ $t("ENU_P_TYPE4") }}
    </div>
    <!-- 月目标下载 !-->
    <div
      class="donwnloads"
      v-if="showGoalsMeetingVOList_1 != false && navIndex == 0 && text != ''"
      @click="saveImage"
    >
      {{ text }}
    </div>
    <!-- 季度目标确认 !-->
    <div
      class="submit-s"
      v-if="showGoalsMeetingVOList_2 == false && navIndex == 1"
      @click="sbumits1"
    >
      {{ $t("ENU_P_TYPE4") }}
    </div>
    <!-- 季度目标下载 !-->
    <div
      class="donwnloads"
      v-if="showGoalsMeetingVOList_2 != false && navIndex == 1 && text != ''"
      @click="saveImage1"
    >
      {{ text }}
    </div>
    <!-- 年目标确认 !-->
    <div
      class="submit-s"
      v-if="showGoalsMeetingVOList_3 == false && navIndex == 2"
      @click="sbumits2"
    >
      {{ $t("ENU_P_TYPE4") }}
    </div>
    <!-- 年目标下载 !-->
    <div
      class="donwnloads"
      v-if="showGoalsMeetingVOList_3 != false && navIndex == 2 && text != ''"
      @click="saveImage2"
    >
      {{ text }}
    </div>

    <div v-if="text == ''" style="height: 100px"></div>
  </div>
</template>

<script>
import { getTransactionDetails, saveGoals, showGoals } from "@/api/wallet.js";
import user from "@/store/modules/user";
import { mapGetters } from "vuex";
import * as api from "@/api/register";
import { Loading, Message } from "element-ui";
import html2canvas from "html2canvas";
import {isLocalSymbol,isOther} from "@/util/numberToCurrency";

export default {
  name: "walletBalance",
  components: {},
  computed: {
    user() {
      return user;
    },
    ...mapGetters(["userInfo"]),
  },
  filters: {
    _int(val) {
      return parseInt(val);
    },
  },
  data() {
    return {
      text: this.$t("S_C_45"),
      typeList: [
        this.$t("S_C_46"),
        this.$t("S_C_47"),
        this.$t("S_C_48"),
        this.$t("S_C_49"),
        this.$t("S_C_50"),
        this.$t("S_C_51"),
        this.$t('S_C_52'),
        this.$t('S_C_53'),
        this.$t('S_C_54'),
        this.$t('S_C_55'),
        this.$t('S_C_56'),
        this.$t('S_C_57'),
      ],
      //
      goalsMeetingVOList1: [],
      goalsMeetingVOList2: [],
      goalsMeetingVOList3: [],
      formModel: {},
      formModel1: {},
      formModel2: {},
      // 验证月
      rules: {},
      // 验证季
      rules1: {},
      // 验证年
      rules2: {},
      navIndex: 0,
      unit: this.$t('S_C_58'),
      navIndex1: 0,
      texts: "",
      times: "",
      navList: [this.$t('S_C_59'),this.$t('S_C_60'), this.$t('S_C_61')],
      navList1: [
      this.$t("ENU_G_C_1"),
      this.$t("ENU_G_C_2"),
      this.$t("ENU_G_C_3"),
      this.$t("ENU_G_C_4"),
      this.$t("ENU_G_C_5"),
      this.$t("ENU_G_C_6"),
      this.$t("ENU_G_C_7"),
      this.$t("ENU_G_C_8"),
      ],
      userTarget: {},
      months: [
        { name: "", value: "" },
        { name: "", value: "" },
      ],
      months1: [
        { name: "", value: "" },
        { name: "", value: "" },
      ],
      userType: {
        pkMember: "",
        goalType: "",
        month: "",
      },
      // 月度目标
      goalsClassifyVOList1: [
        {
          goalClassify: 1,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 2,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "ml1",
              cycleValue: "",
            },
            {
              cycleKey: "mr1",
              cycleValue: "",
            },
            {
              cycleKey: "ml2",
              cycleValue: "",
            },
            {
              cycleKey: "mr2",
              cycleValue: "",
            },
            {
              cycleKey: "ml3",
              cycleValue: "",
            },
            {
              cycleKey: "mr3",
              cycleValue: "",
            },
            {
              cycleKey: "ml4",
              cycleValue: "",
            },
            {
              cycleKey: "mr4",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 3,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 4,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 5,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
          goalsMeetingVOList: [
            // {
            //     "meetingType": "",
            //     "meetingNumber": "",
            //     "meetingTheme": "",
            //     "meetingDate": "",
            //     "pkProvince": "",
            //     "pkCity": "",
            //     "pkCounty": "",
            //     "address": "",
            //     "remarks": ""
            // },
            // {
            //     "meetingType": "",
            //     "meetingNumber": "",
            //     "meetingTheme": "",
            //     "meetingDate": "",
            //     "pkProvince": "",
            //     "pkCity": "",
            //     "pkCounty": "",
            //     "address": "",
            //     "remarks": ""
            // }
          ],
        },
        {
          goalClassify: 6,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
          goalsMeetingVOList: [
            // {
            //     "meetingType": "",
            //     "meetingNumber": "",
            //     "meetingTheme": "",
            //     "meetingDate": "",
            //     "pkProvince": "",
            //     "pkCity": "",
            //     "pkCounty": "",
            //     "address": "",
            //     "remarks": ""
            // },
            // {
            //     "meetingType": "",
            //     "meetingNumber": "",
            //     "meetingTheme": "",
            //     "meetingDate": "",
            //     "pkProvince": "",
            //     "pkCity": "",
            //     "pkCounty": "",
            //     "address": "",
            //     "remarks": ""
            // }
          ],
        },
        {
          goalClassify: 7,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 8,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
        },
      ],
      // 季度目标
      goalsClassifyVOList2: [
        {
          goalClassify: 1,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 2,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "ml1",
              cycleValue: "",
            },
            {
              cycleKey: "mr1",
              cycleValue: "",
            },
            {
              cycleKey: "ml2",
              cycleValue: "",
            },
            {
              cycleKey: "mr2",
              cycleValue: "",
            },
            {
              cycleKey: "ml3",
              cycleValue: "",
            },
            {
              cycleKey: "mr3",
              cycleValue: "",
            },
            {
              cycleKey: "ml4",
              cycleValue: "",
            },
            {
              cycleKey: "mr4",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 3,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 4,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 5,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
          goalsMeetingVOList: [
            // {
            //     "meetingType": "",
            //     "meetingNumber": "",
            //     "meetingTheme": "",
            //     "meetingDate": "",
            //     "pkProvince": "",
            //     "pkCity": "",
            //     "pkCounty": "",
            //     "address": "",
            //     "remarks": ""
            // },
            // {
            //     "meetingType": "",
            //     "meetingNumber": "",
            //     "meetingTheme": "",
            //     "meetingDate": "",
            //     "pkProvince": "",
            //     "pkCity": "",
            //     "pkCounty": "",
            //     "address": "",
            //     "remarks": ""
            // }
          ],
        },
        {
          goalClassify: 6,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
          goalsMeetingVOList: [
            // {
            //     "meetingType": "",
            //     "meetingNumber": "",
            //     "meetingTheme": "",
            //     "meetingDate": "",
            //     "pkProvince": "",
            //     "pkCity": "",
            //     "pkCounty": "",
            //     "address": "",
            //     "remarks": ""
            // },
            // {
            //     "meetingType": "",
            //     "meetingNumber": "",
            //     "meetingTheme": "",
            //     "meetingDate": "",
            //     "pkProvince": "",
            //     "pkCity": "",
            //     "pkCounty": "",
            //     "address": "",
            //     "remarks": ""
            // }
          ],
        },
        {
          goalClassify: 7,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 8,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
          ],
        },
      ],
      // 年度目标
      goalsClassifyVOList3: [
        {
          goalClassify: 1,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
            {
              cycleKey: "m5",
              cycleValue: "",
            },
            {
              cycleKey: "m6",
              cycleValue: "",
            },
            {
              cycleKey: "m7",
              cycleValue: "",
            },
            {
              cycleKey: "m8",
              cycleValue: "",
            },
            {
              cycleKey: "m9",
              cycleValue: "",
            },
            {
              cycleKey: "m10",
              cycleValue: "",
            },
            {
              cycleKey: "m11",
              cycleValue: "",
            },
            {
              cycleKey: "m12",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 2,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "ml1",
              cycleValue: "",
            },
            {
              cycleKey: "mr1",
              cycleValue: "",
            },
            {
              cycleKey: "ml2",
              cycleValue: "",
            },
            {
              cycleKey: "mr2",
              cycleValue: "",
            },
            {
              cycleKey: "ml3",
              cycleValue: "",
            },
            {
              cycleKey: "mr3",
              cycleValue: "",
            },
            {
              cycleKey: "ml4",
              cycleValue: "",
            },
            {
              cycleKey: "mr4",
              cycleValue: "",
            },
            {
              cycleKey: "ml5",
              cycleValue: "",
            },
            {
              cycleKey: "mr5",
              cycleValue: "",
            },
            {
              cycleKey: "ml6",
              cycleValue: "",
            },
            {
              cycleKey: "mr6",
              cycleValue: "",
            },
            {
              cycleKey: "ml7",
              cycleValue: "",
            },
            {
              cycleKey: "mr7",
              cycleValue: "",
            },
            {
              cycleKey: "ml8",
              cycleValue: "",
            },
            {
              cycleKey: "mr8",
              cycleValue: "",
            },

            {
              cycleKey: "ml9",
              cycleValue: "",
            },
            {
              cycleKey: "mr9",
              cycleValue: "",
            },
            {
              cycleKey: "ml10",
              cycleValue: "",
            },
            {
              cycleKey: "mr10",
              cycleValue: "",
            },
            {
              cycleKey: "ml11",
              cycleValue: "",
            },
            {
              cycleKey: "mr11",
              cycleValue: "",
            },
            {
              cycleKey: "ml12",
              cycleValue: "",
            },
            {
              cycleKey: "mr12",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 3,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
            {
              cycleKey: "m5",
              cycleValue: "",
            },
            {
              cycleKey: "m6",
              cycleValue: "",
            },
            {
              cycleKey: "m7",
              cycleValue: "",
            },
            {
              cycleKey: "m8",
              cycleValue: "",
            },
            {
              cycleKey: "m9",
              cycleValue: "",
            },
            {
              cycleKey: "m10",
              cycleValue: "",
            },
            {
              cycleKey: "m11",
              cycleValue: "",
            },
            {
              cycleKey: "m12",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 4,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
            {
              cycleKey: "m5",
              cycleValue: "",
            },
            {
              cycleKey: "m6",
              cycleValue: "",
            },
            {
              cycleKey: "m7",
              cycleValue: "",
            },
            {
              cycleKey: "m8",
              cycleValue: "",
            },
            {
              cycleKey: "m9",
              cycleValue: "",
            },
            {
              cycleKey: "m10",
              cycleValue: "",
            },
            {
              cycleKey: "m11",
              cycleValue: "",
            },
            {
              cycleKey: "m12",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 5,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },

            {
              cycleKey: "m5",
              cycleValue: "",
            },
            {
              cycleKey: "m6",
              cycleValue: "",
            },
            {
              cycleKey: "m7",
              cycleValue: "",
            },
            {
              cycleKey: "m8",
              cycleValue: "",
            },
            {
              cycleKey: "m9",
              cycleValue: "",
            },
            {
              cycleKey: "m10",
              cycleValue: "",
            },
            {
              cycleKey: "m11",
              cycleValue: "",
            },
            {
              cycleKey: "m12",
              cycleValue: "",
            },
          ],
          goalsMeetingVOList: [
            // {
            //     "meetingType": "",
            //     "meetingNumber": "",
            //     "meetingTheme": "",
            //     "meetingDate": "",
            //     "pkProvince": "",
            //     "pkCity": "",
            //     "pkCounty": "",
            //     "address": "",
            //     "remarks": ""
            // },
            // {
            //     "meetingType": "",
            //     "meetingNumber": "",
            //     "meetingTheme": "",
            //     "meetingDate": "",
            //     "pkProvince": "",
            //     "pkCity": "",
            //     "pkCounty": "",
            //     "address": "",
            //     "remarks": ""
            // }
          ],
        },
        {
          goalClassify: 6,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
            {
              cycleKey: "m5",
              cycleValue: "",
            },
            {
              cycleKey: "m6",
              cycleValue: "",
            },
            {
              cycleKey: "m7",
              cycleValue: "",
            },
            {
              cycleKey: "m8",
              cycleValue: "",
            },
            {
              cycleKey: "m9",
              cycleValue: "",
            },
            {
              cycleKey: "m10",
              cycleValue: "",
            },
            {
              cycleKey: "m11",
              cycleValue: "",
            },
            {
              cycleKey: "m12",
              cycleValue: "",
            },
          ],
          goalsMeetingVOList: [
            // {
            //     "meetingType": "",
            //     "meetingNumber": "",
            //     "meetingTheme": "",
            //     "meetingDate": "",
            //     "pkProvince": "",
            //     "pkCity": "",
            //     "pkCounty": "",
            //     "address": "",
            //     "remarks": ""
            // },
            // {
            //     "meetingType": "",
            //     "meetingNumber": "",
            //     "meetingTheme": "",
            //     "meetingDate": "",
            //     "pkProvince": "",
            //     "pkCity": "",
            //     "pkCounty": "",
            //     "address": "",
            //     "remarks": ""
            // }
          ],
        },
        {
          goalClassify: 7,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
            {
              cycleKey: "m5",
              cycleValue: "",
            },
            {
              cycleKey: "m6",
              cycleValue: "",
            },
            {
              cycleKey: "m7",
              cycleValue: "",
            },
            {
              cycleKey: "m8",
              cycleValue: "",
            },
            {
              cycleKey: "m9",
              cycleValue: "",
            },
            {
              cycleKey: "m10",
              cycleValue: "",
            },
            {
              cycleKey: "m11",
              cycleValue: "",
            },
            {
              cycleKey: "m12",
              cycleValue: "",
            },
          ],
        },
        {
          goalClassify: 8,
          totalAchieve: "",
          rightAchieve: "",
          goalsDetailVOList: [
            {
              cycleKey: "m1",
              cycleValue: "",
            },
            {
              cycleKey: "m2",
              cycleValue: "",
            },
            {
              cycleKey: "m3",
              cycleValue: "",
            },
            {
              cycleKey: "m4",
              cycleValue: "",
            },
            {
              cycleKey: "m5",
              cycleValue: "",
            },
            {
              cycleKey: "m6",
              cycleValue: "",
            },
            {
              cycleKey: "m7",
              cycleValue: "",
            },
            {
              cycleKey: "m8",
              cycleValue: "",
            },
            {
              cycleKey: "m9",
              cycleValue: "",
            },
            {
              cycleKey: "m10",
              cycleValue: "",
            },
            {
              cycleKey: "m11",
              cycleValue: "",
            },
            {
              cycleKey: "m12",
              cycleValue: "",
            },
          ],
        },
      ],
      regionParams: {
        label: "name",
        value: "id",
        children: "children",
        expandTrigger: "hover",
      },
      dialogs: false,
      areaData: [],
      goalsMeetingVOList_1: [], //提交到后台的月目标数据
      goalsMeetingVOList_2: [], // 提交到季度目标
      goalsMeetingVOList_3: [], // 提交到年度目标
      texts1: "", //月份
      showGoalsMeetingVOList_1: [], //回显到前台的月目标数据
      showGoalsMeetingVOList_2: [], //回显到前台的季目标数据
      showGoalsMeetingVOList_3: [], //回显到前台的年目标数据
      moth_s: "",
      text1:this.$t("ENU_SETTLE_P_3"),
      text2: this.$t("S_L_20"),

    };
  },
  mounted() {
    let time = new Date();
    this.months[0].name =
      time.getMonth() + 1 > 12 ? 1 : time.getMonth() + 1 +this.$t("ENU_SETTLE_P_3");
    this.months[1].name =
      time.getMonth() + 2 > 12 ? 1 : time.getMonth() + 2 + this.$t("ENU_SETTLE_P_3");
    this.months[0].value = time.getMonth() + 1;
    this.months[1].value = time.getMonth() + 2;

    this.months1[0].name = time.getFullYear() +this.$t("S_C_62");
    this.months1[1].name = time.getFullYear() + 1 + this.$t("S_C_62");
    this.months1[0].value = time.getFullYear();
    this.months1[1].value = time.getFullYear();

    this.texts = time.getMonth() + 1 > 12 ? 1 : time.getMonth() + 1 + this.$t("ENU_SETTLE_P_3");
    this.texts1 = time.getMonth() + 1 > 12 ? 1 : time.getMonth() + 1;
    this.times = time.getFullYear() + this.$t("S_C_62");
   
  },
  methods: {
    getAllData(){
      this.initData();
    this.getAllArea();
    this.getUserData(); //获取月目标
    this.getUserData1(); //获取季度目标
    this.getUserData2(); //获取年目标
    },
    isLocalSymbol,
    isOther,
    saveImage(index) {
      let downloadLoadingInstance = Loading.service({
        text:this.$t("S_C_63"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.text = "";
      const options = {
        backgroundColor: "#fff", // null或transparent可将canvas背景设置为透明
      };
      let myImg = "#" + "myImage" + this.navIndex1;
      this.$nextTick(() => {
        html2canvas(document.querySelector("#targetId"), options).then(
          (canvas) => {
            console.error(canvas);
            const image = canvas
              .toDataURL("image/jpeg")
              .replace("image/jpeg", "image/octet-stream");
            const link = document.getElementById("link");
            link.setAttribute("download", "月目标.jpg");
            link.setAttribute(
              "href",
              canvas
                .toDataURL("image/jpeg")
                .replace("image/jpeg", "image/octet-stream")
            );
            link.click();
            downloadLoadingInstance.close();
            this.text =this.$t("S_C_64");
          }
        );
      });
    },
    saveImage1(index) {
      let downloadLoadingInstance = Loading.service({
        text:this.$t("S_C_63"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.text = "";
      const options = {
        backgroundColor: "#fff", // null或transparent可将canvas背景设置为透明
      };
      this.$nextTick(() => {
        html2canvas(document.querySelector("#targetId"), options).then(
          (canvas) => {
            console.error(canvas);
            const image = canvas
              .toDataURL("image/jpeg")
              .replace("image/jpeg", "image/octet-stream");
            const link = document.getElementById("link");
            link.setAttribute("download", "季度目标.jpg");
            link.setAttribute(
              "href",
              canvas
                .toDataURL("image/jpeg")
                .replace("image/jpeg", "image/octet-stream")
            );
            link.click();
            downloadLoadingInstance.close();
            this.text =this.$t("S_C_64");
          }
        );
      });
    },
    saveImage2(index) {
      let downloadLoadingInstance = Loading.service({
        text:this.$t("S_C_63"),
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.text = "";
      const options = {
        backgroundColor: "#fff", // null或transparent可将canvas背景设置为透明
      };
      this.$nextTick(() => {
        html2canvas(document.querySelector("#targetId"), options).then(
          (canvas) => {
            console.error(canvas);
            const image = canvas
              .toDataURL("image/jpeg")
              .replace("image/jpeg", "image/octet-stream");
            const link = document.getElementById("link");
            link.setAttribute("download", "年目标.jpg");
            link.setAttribute(
              "href",
              canvas
                .toDataURL("image/jpeg")
                .replace("image/jpeg", "image/octet-stream")
            );
            link.click();
            downloadLoadingInstance.close();
            this.text =this.$t("S_C_64");
          }
        );
      });
    },
    saveTx() {
      if (this.navIndex == 0) {
        this.formSubmit(1);
      }
      if (this.navIndex == 1) {
        this.formSubmit(2);
      }
      if (this.navIndex == 2) {
        this.formSubmit(3);
      }
    },
    sbumits1() {
      this.$refs["loginForm1"].validate((valid) => {
        if (valid) {
          this.dialogs = true;
          // this.appendParams1();
          // let time_1 = this.times
          // setTimeout(()=>{
          //     let list ={}
          //     list.pkMember = 100000
          //     list.goalType = this.navIndex+1
          //     list.month =  time_1.substring(0, time_1.length - 1);;
          //     list.goalsClassifyVOList = this.goalsMeetingVOList_2
          //     saveGoals(list).then((res)=>{
          //         console.error(res)
          //     })
          // },50)
        } else {
          this.$message({
            message: this.$t("S_C_65"),
            type: "warning",
          });
        }
      });
    },
    formSubmit(index) {
      var that = this;
      //提交月数据
      if (index == 1) {
        this.appendParams();
        setTimeout(() => {
          let list = {};
          let time = new Date();
          let date = time.getFullYear();
          list.pkMember = 100000;
          list.goalType = this.navIndex + 1;
          let time_1 = this.texts;
          let date_s = time_1.substring(0, time_1.length - 1);
          let data = date_s >= 10 ? date_s : 0 + "" + date_s;
          list.month = date + "-" + data;
          list.goalsClassifyVOList = this.goalsMeetingVOList_1;
          saveGoals(list).then((res) => {
            console.error(list);

            if (res.code == "200") {
              that.$message({
                message: res.msg,
                type: "success",
              });
              that.dialogs = false;
              setTimeout(() => {
                that.getUserData();
              });
            } else {
              Message({
                message: res.msg,
                type: "error",
              });
            }
          });
        }, 50);
      }
      //提交季度数据
      if (index == 2) {
        this.appendParams1();
        let time_1 = this.times;
        setTimeout(() => {
          let list = {};
          list.pkMember = 100000;
          list.goalType = this.navIndex + 1;
          list.month = time_1.substring(0, time_1.length - 1);
          list.goalsClassifyVOList = this.goalsMeetingVOList_2;
          saveGoals(list).then((res) => {
            if (res.code == "200") {
              that.$message({
                message: res.msg,
                type: "success",
              });
              setTimeout(() => {
                that.getUserData1();
              });
              that.dialogs = false;
            } else {
              Message({
                message: res.msg,
                type: "error",
              });
            }
          });
        }, 50);
      }
      //提交年数据
      if (index == 3) {
        this.appendParams2();
        let time_1 = this.times;
        setTimeout(() => {
          let list = {};
          list.pkMember = 100000;
          list.goalType = this.navIndex + 1;
          list.month = time_1.substring(0, time_1.length - 1);
          list.goalsClassifyVOList = this.goalsMeetingVOList_3;
          saveGoals(list).then((res) => {
            if (res.code == "200") {
              that.$message({
                message: res.msg,
                type: "success",
              });
              setTimeout(() => {
                that.getUserData2();
              });
              that.dialogs = false;
            } else {
              Message({
                message: res.msg,
                type: "error",
              });
            }
          });
        }, 50);
      }
    },
    sbumits2() {
      this.$refs["loginForm2"].validate((valid) => {
        if (valid) {
          this.dialogs = true;
        } else {
          this.$message({
            message: this.$t("S_C_65"),
            type: "warning",
          });
        }
      });
    },
    downloads() {},
    getUserData() {
      showGoals({ goalType: 1 }).then((res) => {
        if (res.code == "200" && res.data) {
          this.showGoalsMeetingVOList_1.push(res.data);
          let time = res.data.month.substring(5, res.data.month.length);
          let time1 = time >= 10 ? time : time.substring(1, time);
          this.moth_s = time1;
        }
      });
    },
    getUserData1() {
      showGoals({ goalType: 2 }).then((res) => {
        if (res.code == "200" && res.data) {
          this.showGoalsMeetingVOList_2.push(res.data);
        }
      });
    },
    getUserData2() {
      showGoals({ goalType: 3 }).then((res) => {
        if (res.code == "200" && res.data) {
          this.showGoalsMeetingVOList_3.push(res.data);
        }
      });
    },
    changes(data, item) {
      console.error(data);
      // item.meetingDate = data
      this.$set(item, "meetingDate", data);
    },
    changes1(data, item) {
      this.$set(item, "meetingDate", data);
    },
    getAllArea() {
      api.allArea().then((res) => {
        this.areaData = res.data;
      });
    },
    setAddress1(res, data) {
      data.pkProvince = res[0];
      data.pkCity = res[1];
      data.pkCounty = res[2];
    },
    focus3(e) {
      if (e.target.value) {
        this.goalsMeetingVOList3 = [];
        for (let i = 0; i < e.target.value; i++) {
          this.goalsMeetingVOList3.push({
            meetingType: "",
            meetingNumber: "",
            meetingTheme: "",
            meetingDate: "",
            pkProvince: "",
            pkCity: "",
            pkCounty: "",
            address: "",
            remarks: "",
            areaAddress: "",
          });
        }
      }
    },
    focus1(e) {
      if (e.target.value) {
        this.goalsMeetingVOList1 = [];
        for (let i = 0; i < e.target.value; i++) {
          this.goalsMeetingVOList1.push({
            meetingType: "",
            meetingNumber: "",
            meetingTheme: "",
            meetingDate: "",
            pkProvince: "",
            pkCity: "",
            pkCounty: "",
            address: "",
            remarks: "",
            areaAddress: "",
          });
        }
      }
    },
    focus2(e) {
      if (e.target.value) {
        this.goalsMeetingVOList2 = [];
        for (let i = 0; i < e.target.value; i++) {
          this.goalsMeetingVOList2.push({
            meetingType: "",
            meetingNumber: "",
            meetingTheme: "",
            meetingDate: "",
            pkProvince: "",
            pkCity: "",
            pkCounty: "",
            address: "",
            remarks: "",
            areaAddress: "",
          });
        }
      }
    },
    initData() {
      this.goalsClassifyVOList1.forEach((item) => {
        item["totalAchieveKey"] = "totalAchieve" + "_" + item.goalClassify;
        item["rightAchieveKeY"] = "rightAchieve" + "_" + item.goalClassify;
        item.goalsDetailVOList.forEach((_item) => {
          _item["originalKey"] = _item.cycleKey + "_" + item.goalClassify;
        });
      });
      this.goalsClassifyVOList2.forEach((item) => {
        item["totalAchieveKey"] = "totalAchieve" + "_" + item.goalClassify;
        item["rightAchieveKeY"] = "rightAchieve" + "_" + item.goalClassify;
        item.goalsDetailVOList.forEach((_item) => {
          _item["originalKey"] = _item.cycleKey + "_" + item.goalClassify;
        });
      });
      this.goalsClassifyVOList3.forEach((item) => {
        item["totalAchieveKey"] = "totalAchieve" + "_" + item.goalClassify;
        item["rightAchieveKeY"] = "rightAchieve" + "_" + item.goalClassify;
        item.goalsDetailVOList.forEach((_item, index) => {
          if (_item.cycleKey) {
            _item["originalKey"] = _item.cycleKey + "_" + item.goalClassify;
          }
        });
      });
      this.initRules();
    },
    navChange(index) {
      this.navIndex = index;
      this.navIndex1 = 0;
      this.unit = this.$t("S_C_58");
      if (index == 0) {
        this.text1 =this.$t("S_L_20");
        this.text2 = this.$t("ENU_SETTLE_P_3");
      }
      if (index == 1) {
        this.text1 = this.$t("S_L_19");
        this.text2 =  this.$t("S_C_66");
      }
      if (index == 2) {
        this.text1 =this.$t("S_L_18");;
        this.text2 = this.$t("S_C_62");
      }
    },
    navChange1(index) {
      this.navIndex1 = index;
      if (index == 0 || index == 1 || index == 2 || index == 7) {
        this.unit = this.$t("S_C_58");
      }
      if (index == 3) {
        this.unit =  this.$t("S_C_67");
      }
      if (index == 4 || index == 5) {
        this.unit =  this.$t("S_C_68");
      }
      if (index == 6) {
        this.unit = this.$t("S_C_69");
      }
    },
    initRules() {
      let rules = {};
      let _formModel = {};
      let rules1 = {};
      let _formModel1 = {};
      let rules2 = {};
      let _formModel2 = {};
      this.goalsClassifyVOList1.forEach((item, index) => {
        let _rule = [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ];
        rules[item.totalAchieveKey] = _rule;
        rules[item.rightAchieveKeY] = _rule;
        _formModel[item.totalAchieveKey] = "";
        _formModel[item.rightAchieveKeY] = "";
        item.goalsDetailVOList.forEach((_item) => {
          rules[_item.originalKey] = _rule;
          _formModel[_item.originalKey] = "";
        });
      });
      this.rules = rules;
      this.formModel = _formModel;
      this.goalsClassifyVOList2.forEach((item, index) => {
        let _rule = [
          { required: true, message:  this.$t("S_C_70"), trigger: "blur" },
        ];
        rules1[item.totalAchieveKey] = _rule;
        rules1[item.rightAchieveKeY] = _rule;
        _formModel1[item.totalAchieveKey] = "";
        _formModel1[item.rightAchieveKeY] = "";
        item.goalsDetailVOList.forEach((_item) => {
          rules1[_item.originalKey] = _rule;
          _formModel1[_item.originalKey] = "";
        });
      });
      this.rules1 = rules1;
      this.formModel1 = _formModel1;

      this.goalsClassifyVOList3.forEach((item, index) => {
        let _rule1 = [
          { required: true, message:  this.$t("S_C_70"), trigger: "blur" },
        ];
        rules2[item.totalAchieveKey] = _rule1;
        rules2[item.rightAchieveKeY] = _rule1;
        _formModel2[item.totalAchieveKey] = "";
        _formModel2[item.rightAchieveKeY] = "";
        item.goalsDetailVOList.forEach((_item, index) => {
          if (_item.originalKey) {
            rules2[_item.originalKey] = _rule1;
            _formModel2[_item.originalKey] = "";
          }
        });
      });
      this.rules2 = rules2;
      this.formModel2 = _formModel2;
    },
    appendParams() {
      let _formModel = this.formModel;
      var data = JSON.parse(JSON.stringify(this.goalsMeetingVOList1));
      var _goalsClassifyVOList1 = JSON.parse(
        JSON.stringify(this.goalsClassifyVOList1)
      );
      if (data.length >= 1) {
        var obj = data;
        for (var key in obj) {
          if (obj[key].meetingType == "") {
            delete obj[key].meetingType;
          }
          if (obj[key].meetingNumber == "") {
            delete obj[key].meetingNumber;
          }
          if (obj[key].meetingTheme == "") {
            delete obj[key].meetingTheme;
          }
          if (obj[key].meetingDate == "") {
            delete obj[key].meetingDate;
          }
          if (obj[key].pkProvince == "") {
            delete obj[key].pkProvince;
          }
          if (obj[key].pkCounty == "") {
            delete obj[key].pkCounty;
          }
          if (obj[key].pkCity == "") {
            delete obj[key].pkCity;
          }
          if (obj[key].address == "") {
            delete obj[key].address;
          }
          if (obj[key].remarks == "") {
            delete obj[key].remarks;
          }
        }
        var newArray = obj.filter((value) => Object.keys(value).length !== 0);
      }
      if (newArray && newArray != false) {
        let arr1 = [];
        let arr2 = [];
        arr1.push(newArray);
        _goalsClassifyVOList1[5].goalsMeetingVOList = newArray;
        _goalsClassifyVOList1[4].goalsMeetingVOList = newArray;
      }
      _goalsClassifyVOList1.forEach((item, index) => {
        item.totalAchieve = _formModel[item.totalAchieveKey];
        item.rightAchieve = _formModel[item.rightAchieveKeY];
        setTimeout(() => {
          delete item.totalAchieveKey;
          delete item.rightAchieveKeY;
        }, 50);
        item.goalsDetailVOList.forEach((_item) => {
          _item.cycleValue = _formModel[_item.originalKey];
          setTimeout(() => {
            delete _item.originalKey;
          }, 50);
        });
      });
      _goalsClassifyVOList1[5].totalAchieve =
        _goalsClassifyVOList1[4].totalAchieve;

      this.goalsMeetingVOList_1 = _goalsClassifyVOList1;
    },
    appendParams1() {
      let _formModel = this.formModel1;
      var data = JSON.parse(JSON.stringify(this.goalsMeetingVOList2));
      var _goalsClassifyVOList1 = JSON.parse(
        JSON.stringify(this.goalsClassifyVOList2)
      );
      if (data.length >= 1) {
        var obj = data;
        for (var key in obj) {
          if (obj[key].meetingType == "") {
            delete obj[key].meetingType;
          }
          if (obj[key].meetingNumber == "") {
            delete obj[key].meetingNumber;
          }
          if (obj[key].meetingTheme == "") {
            delete obj[key].meetingTheme;
          }
          if (obj[key].meetingDate == "") {
            delete obj[key].meetingDate;
          }
          if (obj[key].pkProvince == "") {
            delete obj[key].pkProvince;
          }
          if (obj[key].pkCounty == "") {
            delete obj[key].pkCounty;
          }
          if (obj[key].pkCity == "") {
            delete obj[key].pkCity;
          }
          if (obj[key].address == "") {
            delete obj[key].address;
          }
          if (obj[key].remarks == "") {
            delete obj[key].remarks;
          }
          delete obj[key].areaAddress;
        }
        var newArray = obj.filter((value) => Object.keys(value).length !== 0);
      }
      if (newArray && newArray != false) {
        let arr1 = [];
        let arr2 = [];
        arr1.push(newArray);
        _goalsClassifyVOList1[5].goalsMeetingVOList = newArray;
        _goalsClassifyVOList1[4].goalsMeetingVOList = newArray;
      }
      _goalsClassifyVOList1.forEach((item, index) => {
        item.totalAchieve = _formModel[item.totalAchieveKey];
        item.rightAchieve = _formModel[item.rightAchieveKeY];
        setTimeout(() => {
          delete item.totalAchieveKey;
          delete item.rightAchieveKeY;
        }, 50);
        item.goalsDetailVOList.forEach((_item) => {
          _item.cycleValue = _formModel[_item.originalKey];
          setTimeout(() => {
            delete _item.originalKey;
          }, 50);
        });
      });
      _goalsClassifyVOList1[5].totalAchieve =
        _goalsClassifyVOList1[4].totalAchieve;
      this.goalsMeetingVOList_2 = _goalsClassifyVOList1;
    },
    appendParams2() {
      let _formModel = this.formModel2;
      var data = JSON.parse(JSON.stringify(this.goalsMeetingVOList3));
      var _goalsClassifyVOList1 = JSON.parse(
        JSON.stringify(this.goalsClassifyVOList3)
      );
      if (data.length >= 1) {
        var obj = data;
        for (var key in obj) {
          if (obj[key].meetingType == "") {
            delete obj[key].meetingType;
          }
          if (obj[key].meetingNumber == "") {
            delete obj[key].meetingNumber;
          }
          if (obj[key].meetingTheme == "") {
            delete obj[key].meetingTheme;
          }
          if (obj[key].meetingDate == "") {
            delete obj[key].meetingDate;
          }
          if (obj[key].pkProvince == "") {
            delete obj[key].pkProvince;
          }
          if (obj[key].pkCounty == "") {
            delete obj[key].pkCounty;
          }
          if (obj[key].pkCity == "") {
            delete obj[key].pkCity;
          }
          if (obj[key].address == "") {
            delete obj[key].address;
          }
          if (obj[key].remarks == "") {
            delete obj[key].remarks;
          }
          delete obj[key].areaAddress;
        }
        var newArray = obj.filter((value) => Object.keys(value).length !== 0);
      }
      if (newArray && newArray != false) {
        let arr1 = [];
        let arr2 = [];
        arr1.push(newArray);
        _goalsClassifyVOList1[5].goalsMeetingVOList = newArray;
        _goalsClassifyVOList1[4].goalsMeetingVOList = newArray;
      }
      _goalsClassifyVOList1.forEach((item, index) => {
        item.totalAchieve = _formModel[item.totalAchieveKey];
        item.rightAchieve = _formModel[item.rightAchieveKeY];
        setTimeout(() => {
          delete item.totalAchieveKey;
          delete item.rightAchieveKeY;
        }, 50);
        item.goalsDetailVOList.forEach((_item) => {
          _item.cycleValue = _formModel[_item.originalKey];
          setTimeout(() => {
            delete _item.originalKey;
          }, 50);
        });
      });
      _goalsClassifyVOList1[5].totalAchieve =
        _goalsClassifyVOList1[4].totalAchieve;
      this.goalsMeetingVOList_3 = _goalsClassifyVOList1;
    },
    selectTime(data) {
      this.texts = data;
    },
    selectTimes(data) {
      this.times = data;
    },
    sbumits(formName) {
      this.$refs["loginForm"].validate((valid) => {
        if (valid) {
          this.dialogs = true;
          // this.appendParams();
          //    setTimeout(()=>{
          //        let list ={};
          //        let time = new Date()
          //        let date = time.getFullYear()
          //        list.pkMember = 100000
          //        list.goalType = this.navIndex+1
          //        let time_1 = this.times
          //        list.month = date +'-'+ time_1.substring(0, time_1.length - 1);
          //        list.goalsClassifyVOList = this.goalsMeetingVOList_1
          //        saveGoals(list).then((res)=>{
          //            console.error(res)
          //        })
          //    },50)
        } else {
          this.$message({
            message:  this.$t("S_C_65"),
            type: "warning",
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.width-auto-center {
  width: fit-content;
}
.center {
  text-align: center;
}
.width-280 {
  width: 270px !important;
}
.title-left {
  left: 60px;
  position: absolute;
}
.border-none {
  border: none !important;
}
.width-table-s {
  width: 120px;
}
.width-table {
  width: 220px;
}
.right-border {
  border-right: 1px solid #333;
}
.warning-s {
  text-align: center;
  img {
    width: 83px;
    height: 74px;
  }
}
.text3 {
  color: #333;
  font-size: 24px;
  margin-top: 20px;
  font-weight: bold;
  text-align: center;
}
.saveBtn {
  width: 117px;
  height: 50px;
  background: #d5251d;
  border-radius: 8px 8px 8px 8px;
  color: #fff;
}
.cancel {
  width: 117px;
  height: 50px;
  background: #cccccc;
  border-radius: 8px 8px 8px 8px;
  color: #fff;
}
.width_s {
  width: 870px;
}
.target-bottom {
  img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 250px;
    //height: 252px;
  }
}
.target-bottom-right {
  img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 250px;
    //height: 252px;
  }
}
.color-s {
  background: #ffffff;
  border-radius: 20px 20px 20px 20px;
  margin: 0 10px;
  padding: 30px 10px 10px 10px;
}
.bgcolor-s {
  border-radius: 10px;
  background: linear-gradient(180deg, #ffe03a 0%, #f2d006 100%);
}
.top-title {
  div {
    color: #333;
    font-size: 40px;
    margin: 30px auto;
    text-align: center;
    font-weight: 600;
  }
}
.size-s {
  font-weight: inherit !important;
}
.right-s {
  position: absolute;
  left: 66px;
  span {
    position: absolute;
    left: 220px;
  }
}
.left-s3 {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 115px;
  //left: 75px;
  div {
    text-align: center;
  }
}
.left-s1 {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 220px;
  //left: 75px;
  div {
    text-align: center;
  }
}
.width-autos {
  width: 145px;
  text-align: center;
}
.left-s2 {
  position: absolute;
  left: 200px;
  //left: 75px;
  div {
    text-align: center;
  }
}
.left-s {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 208px;
  //left: 75px;
  div {
    text-align: center;
  }
}
.flex-autos {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: auto;
}
.width-190 {
  width: 190px;
}
::v-deep .el-form-item {
  margin-bottom: 0;
}
::v-deep .el-form-item__content {
  line-height: 58px;
  font-size: 18px;
}
::v-deep .el-form-item__error {
  margin-top: -18px;
  display: none;
}
::v-deep .el-input__inner {
  height: 35px;
  text-align: center;
}
.bottom-s {
  border-bottom: 1px solid #333;
}
.heights {
  height: 124px !important;
}
.flex-s {
  display: flex;
}
.border-s {
  height: 100%;
  width: 220px;
  border-right: 1px solid #333;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.width-auto-s {
  height: 100%;
  width: 50%;
  border-right: 1px solid #333;
  text-align: left;
  position: relative;
}
.input-s {
  padding-left: 20px;
}
.donwnloads {
  width: 128px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  color: #fff;
  font-size: 18px;
  margin: 100px auto 20px auto;
  background: #d61820;
  border-radius: 6px;
  cursor: pointer;
}
.submit-s {
  width: 128px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  color: #fff;
  font-size: 18px;
  margin: 50px auto;
  background: #d61820;
  border-radius: 6px;
  cursor: pointer;
}

.table-right {
  //width: 800px;
  overflow: auto;
}

.table-width {
  height: 55px;
  width: 100%;
  //line-height: 55px;
  border-bottom: 1px solid #333;
}

.title {
  height: 58px;
  line-height: 58px;
  border-bottom: 1px solid #333;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-title {
  width: 180px;
  border-right: 1px solid #000000;
  text-align: center;
}

.table-lists {
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border-top: 1px solid #333333;
  border-right: 1px solid #333333;
  border-left: 1px solid #333333;
  //border-top: 1px solid #333333;
  margin: 20px 0 30px 0;
  display: flex;
  overflow: auto;
}
.text-color {
  color: #333;
  font-size: 18px !important;
}
.text {
  color: #666;
  font-size: 18px;
}
.text-s {
  color: #c8161d;
  font-size: 32px;
  margin: 0 10px;
}

.target-icon {
  img {
    width: 180px;
    height: 45px;
  }
}

.top-target {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-month {
  position: absolute;
  right: 0;
}

.target-sel {
  position: relative;
  display: flex;
  padding-left: 5px;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
}

.styles1 {
  background: #d61820;
  border: none !important;
  color: #fff !important;
}

.nav-top-views {
  width: 110px;
  height: 34px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #999999;
  line-height: 34px;
  text-align: center;
  margin-right: 20px;
  color: #999;
  cursor: pointer;
}

.top-s {
  padding: 20px 0;
}

.style-s {
  width: 47px;
  height: 4px;
  background: #b42b2a;
  border-radius: 6px 6px 6px 6px;
  position: absolute;
  left: 10px;
  margin-top: 3px;
}

.styles {
  color: #b42b2a !important;
  font-size: 18px !important;
}

.nav-top {
  display: flex;

  .nav-top-list {
    cursor: pointer;
    color: #999;
    font-size: 16px;
    margin-right: 20px;
    position: relative;
  }
}

.contain {
  //background: rgba(249, 249, 249, 1);
  //height: 100vh;
  display: block !important;
  padding: 10px 0 !important;
  position: relative;
  //display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);

    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }

    .actArea {
      color: #b42b2a;
    }
  }

  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 40px 0;
    padding: 30px 16px 10px 16px;

    .searchbox {
      display: flex;
      align-items: center;

      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }

      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
  }

  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;

    .tabletop {
      text-align: right;
      padding: 20px;

      .tabletoptitle1 {
        font-size: 18px;
      }

      .tabletoptitle2 {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}
</style>