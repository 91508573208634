
<template>
  <div>
    <el-dialog
      class="notice"
      width="35%"
      :title="$t('ENU_APPROVE_B_522')"
      :visible.sync="item.noticeFlag"
     
      v-for="(item, index) in popupList"
      @close="closeTap(item, 1)"
      :key="index"
    >
      <div class="title">
        {{ item.title }}
      </div>
      <div class="img-auto" v-html="item.content"></div>
      <div class="surebtn1">
        <!--      <div class="btn hh" @click="closeTap">取消</div>-->
        <div class="btn" @click="closeTap(item, 2)">已读</div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import { getIndexPopmail } from "@/api/index.js";
import { getNoticeDetail } from "@/api/sidebaruserinfo.js";
export default {
  name: "Title",
 
  data() {
    return {
      popupList: [],
    };
  },
  mounted() {
    // this.getUserTc();
  },
  methods: {
    closeTap(item, type) {
      if (type == 1) {
        //关闭
        item.noticeFlag = false;
        this.$forceUpdate(); // 强制更新视图
        if (item.pkId === this.popupList[0].pkId) {
          this.$emit("childMethodTrigger");
        }
      } else {
        getNoticeDetail({ pkId: item.pkId, functionType: 2 }).then((res) => {});
        item.noticeFlag = false;
        this.$forceUpdate(); // 强制更新视图
        if (item.pkId === this.popupList[0].pkId) {
          this.$emit("childMethodTrigger");
        }
      }

      // this.$set(this.popupList[index], 'noticeFlag', false);
    },
    getUserTc() {
      getIndexPopmail().then((res) => {
        if (res.code == 200) {
          if (res.data.length > 0) {
            this.popupList = res.data;
            this.popupList.forEach((ele) => {
              ele.noticeFlag = true;
            });
          } else {
            this.$emit("childMethodTrigger");
          }
        }
      });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
::v-deep .el-dialog__headerbtn i {
  font-size: 24px;
  //visibility: hidden;
  color: #333;
}
::v-deep .el-dialog {
  margin-top: 113px !important;
  height: 815px;
  margin-bottom: 0;
}
::v-deep .el-dialog__body {
  padding-bottom: 20px;
}
.surebtn1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  //justify-content: space-around;
  .btn {
    width: 200px;
    height: 66px;
    background: #d5251d;
    border-radius: 6px 6px 6px 6px;
    text-align: center;
    font-size: 22px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 66px;
    cursor: pointer;
  }
  .hh {
    background: #cccccc;
    margin-right: 78px;
  }
}
::v-deep .el-dialog__header {
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
::v-deep .el-dialog__title {
  font-size: 32px;
  color: #333;
  font-weight: bold;
}
::v-deep .el-dialog__body {
  text-align: center;
  padding-top: 10px;
}
.title {
  color: #333;
  font-weight: bold;
  margin: 10px auto 10px 0;
  font-size: 16px;
}
.img-auto {
  text-align: center;
  height: 600px;
  overflow-y: auto;
  ::v-deep p {
    font-size: 14px;
  }
  ::v-deep img {
    max-width: 100%;
    height: auto;
  }
}
</style>