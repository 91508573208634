<template>
  <div class="contain1">
    <!-- <Tabbar></Tabbar> -->
    <div class="themapdiv">
      <div class="chart pt-10">
        <!-- <div class="white text-center" :style="{ width: '100%', fontSize: '24px' }">
        中国地图
      </div> -->
        <div id="mapChart" class="mapChart"></div>
        <div class="mapChoose">
          <span>
            <div class="mapimg" v-if="userInfo.pkCountry == 1">
              <img
                v-if="theMapC == 2"
                @click="chooseMap(1)"
                style="width: 220px; height: 150px"
                src="@/assets/images/wordmap.png"
                alt=""
              />
              <img
                v-if="theMapC == 1"
                @click="chooseMap(2)"
                style="width: 220px; height: 150px"
                src="@/assets/images/chinamap.png"
                alt=""
              />
            </div>
          </span>
        </div>
      </div>
    </div>
    <div class="superRight">
      <div class="super_title supertitel_left">
        <div class="su_title">{{ $t("S_C_82") }}</div>
        <div class="su_counrty">
          {{ marketData.countryNum }}{{ $t("S_C_83") }}
        </div>
        <div class="su_counrty">
          {{ marketData.regionNum }}{{ $t("S_C_84") }}
        </div>
      </div>
      <div class="super_title">
        <div class="su_title">{{ $t("S_C_81") }}</div>
        <div class="su_counrty">
          {{ marketData.myCountryNum }}{{ $t("S_C_83") }}
        </div>
        <div class="su_counrty">
          {{ marketData.myRegionNum }}{{ $t("S_C_84") }}
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
// import { mapCode } from "@/assets/js/map/mapCode.js";
import { getMyMarket } from "@/api/index.js";
// import { getMyMarketColor } from "@/api/sidebaruserinfo.js";
import { _debounce } from "@/util/common.js";
import { mapGetters } from "vuex";

import axios from "axios";
export default {
  name: "mySuperEcharts",
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      countryList: [],
      myChart: null,
      chinaCode: "china",
      curMapName: "china", // 当前地图名
      selectedMaps: [
        {
          name: "世界",
          code: "world",
        },
        {
          name: "China",
          code: "china",
        },
      ],
      theMapC: 2, //1中国2世界
      colorList: [],
      marketData: {},
    };
  },
  mounted() {
    if (this.userInfo.pkCountry != 1) {
      this.theMapC = 1; //1中国2世界
      this.chinaCode = "world";
      this.curMapName = "world"; // 当前地图名
    }
    this.getMapData(this.chinaCode);
    window.addEventListener("resize", this.resizeCharts);
  },
  methods: {
    // 绘制地图
    async drawMapChart(mapName, mapJSON) {
      if (
        this.myChart != null &&
        this.myChart != "" &&
        this.myChart != undefined
      ) {
        this.myChart.dispose();
      }
      this.myChart = this.$echarts.init(document.getElementById("mapChart"));
      this.$echarts.registerMap(mapName, mapJSON);

      // 获取颜色列表
      const res = await getMyMarket();
      this.marketData = res.data;
      res.data.myMarketDetailVOList.forEach((ele) => {
        if (ele != null) {
          ele.name = ele.lightName;
        }
      });
      const thecolorJson = res.data.myMarketDetailVOList.filter(
        (item) => item !== null
      );
      // 修改颜色列表
      thecolorJson.forEach((item) => {
        item.itemStyle = {
          normal: {
            areaColor: "#C8161D", // 选中区域的颜色
          },
        };
      });
      this.myChart.setOption({
        series: [
          {
            type: "map",
            map: mapName,
            roam: false, // 是否开启鼠标缩放和平移漫游
            zoom: 1,
            selectedMode: "false", // 是否允许选中多个区域
            label: {
              show: false, //地图上地区文字的现实
              color: "#a8e6e1",
            },

            emphasis: {
              label: {
                show: true,
                color: "#ffffff",
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#bae7ff",
                borderColor: "#ffffff",
                borderWidth: 1,
              },
              //鼠标悬浮区域上的背景颜色
              emphasis: {
                show: true,
                areaColor: "#C8161D",
              },
            },
            tooltip: {
              trigger: "item",
              show: true,
              backgroundColor: "transparent",

              textStyle: {
                width: 300,
                height: 300,
              },
            },

            // data: this.initMapData(mapJSON),
            data: thecolorJson, // 使用修改后的颜色列表作为地图数据
          },
        ],
      });

      this.myChart.on("click", (params) => {});
    },
    initMapData(mapJson) {
      var thecolorJson = this.colorList;
      thecolorJson.map((item) => {
        item.itemStyle = {
          normal: {
            areaColor: "#C8161D", //选中区域的颜色
          },
        };
      });
      let mapData = [];

      for (let i = 0; i < mapJson.features.length; i++) {
        mapData.push({ name: mapJson.features[i].properties.name });
      }
      return thecolorJson;
    },
    // 地图标题菜单点击
    chooseMap(val) {
      //val==1中国val==2世界
      if (val == 1) {
        this.theMapC = 1;
        this.getMapData("world");
      } else if (val == 2) {
        this.theMapC = 2;
        this.getMapData("china");
      }
    },
    // 浏览器窗口大小改变时，重新加载图表以自适应
    resizeCharts: _debounce(function () {
      this.$echarts.init(document.getElementById("mapChart")).resize();
    }, 500),
    // 获取地图数据
    getMapData(map) {
      axios
        .get(`/map/${map}.json`)
        // .get(`/map/${map}.json`)
        .then((res) => {
          if (res.status == 200) {
            const mapJSON = res.data;
            this.drawMapChart(this.curMapName, mapJSON);
          }
        })
        .catch((err) => {
          this.$message({ message: err, type: "error", showClose: true });
        });
    },
  },

  beforeDestroy() {
    window.addEventListener("resize", this.resizeCharts);
  },
};
</script>
    <style lang='scss' scoped>
.mapimg {
  width: 221px;
  height: 150px;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 4px solid #cccccc;
  cursor: pointer;
}
.chart {
  // background-image: url("../assets/img/bgImg.png");
  // background-size: 100% 100%;
  #mapChart {
    width: 750px;
    height: 530px;
    position: relative;
  }
  .mapChoose {
    position: absolute;
    left: 20px;
    bottom: 20px;
    // top: 20px;
    color: #eee;
    .title {
      padding: 5px;
      border-top: 1px solid rgba(132, 219, 233, 0.8);
      border-bottom: 1px solid rgba(147, 235, 248, 0.8);
      cursor: pointer;
    }
    .icon {
      font-family: "simsun";
      font-size: 25px;
      margin: 0 11px;
    }
  }
}
.contain1 {
  display: flex;
  // padding-bottom: 94px;
  background: #ffffff;
  .themapdiv {
    background: #ffffff;
    //   height: 952px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .superRight {
    display: flex;
    height: 110px;
    background: #ffffff;
    .super_title {
      padding: 0 10px;
    }
    .supertitel_left {
      border-right: 1px solid #cccccc;
    }
    .su_title {
      font-size: 20px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .su_counrty {
      font-size: 18px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      margin-top: 10px;
    }
  }
}
</style>