<template>
  <div class="page">
    <div class="glotop">
      <div
        class="glo_btn"
        v-for="(item, index) in navList"
        :key="index"
        @click="navTab(index)"
        :class="navIndex == index ? 's1' : 's2'"
      >
        {{ item.regionName }}
      </div>
      <!-- <div class="glo_btn">欧洲</div>
      <div class="glo_btn">南美洲</div> -->
    </div>
    <div class="glocont">
      <div class="cont" v-for="(aitem, aindex) in belowList" :key="aindex">
        <div class="cont_title">
          {{ aitem.shortName }}·{{ aitem.provinceName }}{{ aitem.cityName }}
        </div>
        <div class="cont_phone">
          <span><i style="font-size:18px;" class="el-icon-user"></i> {{$t('S_C_86')}}：</span
          >{{ aitem.contacts }}
        </div>
        <div class="cont_phone">
          <span><i style="font-size:18px;" class="el-icon-phone-outline"></i> {{$t('S_C_87')}}：</span
          >{{ aitem.phone }}
        </div>
        <div class="cont_phone">
          <span><i style="font-size:20px;" class="el-icon-location-outline"></i> {{$t('S_C_88')}}：</span>
          {{ aitem.shortName }}{{ aitem.provinceName }}{{ aitem.cityName
          }}{{ aitem.countyName || "" }}{{ aitem.address }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as glo from "@/api/index.js";
export default {
  data() {
    return {
      navIndex: 0,
      navList: [],
      belowList: [],
    };
  },
  mounted() {
    this.getGloaData();
  },
  methods: {
    navTab(index) {
      this.navIndex = index;
      this.navList.forEach((ele, eindex) => {
        if (index == eindex) {
          this.belowList = ele.belowList;
        }
      });
    },
    getGloaData() {
      glo.getGloableCompany().then((res) => {
        this.navList = res.rows;
        this.belowList = this.navList[0].belowList;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 32px 19px;
  .glotop {
    display: flex;

    .glo_btn {
      padding: 6px 32px;
      cursor: pointer;
      border-radius: 39px 39px 39px 39px;
      height: 32px;

      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 40px;
    }
    .s1 {
      background: #d61820;
      color: #ffffff;
    }
    .s2 {
      border: 2px solid #979797;
      color: #999999;
    }
  }
  .glocont {
    display: flex;
    flex-wrap: wrap;
    .cont {
      margin-top: 50px;
      width: 50%;
      .cont_title {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .cont_phone {
        margin-top: 20px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
    }
  }
}
</style>