var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{staticClass:"notice",attrs:{"width":"35%","title":_vm.$t('ENU_APPROVE_B_522'),"visible":_vm.noticeFlag},on:{"update:visible":function($event){_vm.noticeFlag=$event},"close":function($event){return _vm.closeTap()}}},[_c('div',{staticClass:"tchdbox"},[_c('div',{staticClass:"bjtp"},[_c('div',{staticClass:"top_kuai kuai1"},[_c('div',{class:_vm.popupList[0].isLoginMember == 1 ? 'mname' : 'mname2'},[_vm._v(" "+_vm._s(_vm.popupList[0].memberName)+" ")]),_c('div',{staticClass:"tx"},[_c('img',{attrs:{"src":_vm.popupList[0].headPath
                  ? _vm.popupList[0].headPath
                  : _vm.userInfo.countryCircularIcon,"alt":""}})]),_c('div',{staticClass:"yeji"},[_vm._v(_vm._s(_vm.popupList[0].orderAchieveStr))]),_c('div',{staticClass:"topb topb1"},[_vm._v("TOP.1")])]),_c('div',{staticClass:"top_kuai kuai2"},[_c('div',{class:_vm.popupList[1].isLoginMember == 1 ? 'mname' : 'mname2'},[_vm._v(" "+_vm._s(_vm.popupList[1].memberName)+" ")]),_c('div',{staticClass:"tx"},[_c('img',{attrs:{"src":_vm.popupList[1].headPath
                  ? _vm.popupList[1].headPath
                  : _vm.userInfo.countryCircularIcon,"alt":""}})]),_c('div',{staticClass:"yeji"},[_vm._v(_vm._s(_vm.popupList[1].orderAchieveStr))]),_c('div',{staticClass:"topb topb2"},[_vm._v("TOP.2")])]),_c('div',{staticClass:"top_kuai kuai3"},[_c('div',{class:_vm.popupList[2].isLoginMember == 1 ? 'mname' : 'mname2'},[_vm._v(" "+_vm._s(_vm.popupList[2].memberName)+" ")]),_c('div',{staticClass:"tx"},[_c('img',{attrs:{"src":_vm.popupList[2].headPath
                  ? _vm.popupList[2].headPath
                  : _vm.userInfo.countryCircularIcon,"alt":""}})]),_c('div',{staticClass:"yeji"},[_vm._v(_vm._s(_vm.popupList[2].orderAchieveStr))]),_c('div',{staticClass:"topb topb3"},[_vm._v("TOP.3")])]),_c('div',{staticClass:"poupmain"},[_c('div',{staticClass:"main_title"},[_vm._v(_vm._s(_vm.themonth)+"月直推排行榜")]),_c('div',{staticClass:"table"},[_c('div',{staticClass:"linerow flexrow"},[_c('div',{staticClass:"line1"},[_vm._v("排名")]),_c('div',{staticClass:"line2"}),_c('div',{staticClass:"line3"},[_vm._v("会员姓名")]),_c('div',{staticClass:"line4"},[_vm._v("直推业绩(万)")])]),_vm._l((_vm.popupList2),function(item,index){return _c('div',{key:index,class:item.isLoginMember == 1 ? 'flexrow' : 'tcrow'},[_c('div',{staticClass:"theline line1"},[_c('div',{staticClass:"indexrow"},[_vm._v(_vm._s(index + 4))])]),_c('div',{staticClass:"theline line2"},[_c('img',{attrs:{"src":item.headPath
                      ? item.headPath
                      : _vm.userInfo.countryCircularIcon,"alt":""}})]),_c('div',{staticClass:"theline line3"},[_vm._v(_vm._s(item.memberName))]),_c('div',{staticClass:"theline line4"},[_vm._v(_vm._s(item.orderAchieveStr))])])})],2)])]),_c('div',{staticClass:"closediv"},[_c('img',{staticClass:"qyclose",attrs:{"src":_vm.closeImg},on:{"click":function($event){return _vm.closeTap()}}})])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }