<template>
    <div>
        <div class="styles">
            <el-form >
            <el-col :span="12">
            <el-select v-model="texts"
                       @change="selectTime"
                       placeholder="">
                <el-option v-for="item in deliList"

                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
            </el-select>
            </el-col>
            </el-form>
        </div>
        <div class="right-lines">
            <div class="item-s" v-for="item in list">
                <div>{{item.name}}</div>
                <div class="bg-color" :style="{backgroundColor:`${item.color}`}"></div>
            </div>
        </div>
<!--        <div class="lines"></div>-->
        <div class="flex-s">
            <div id="main1" style="width: 100%;margin-top: 10px"  :style="myChartStyle"></div>
        </div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import {getDayType, getIndexAddAchieve, getMemberRecommend} from "@/api/index";
import {formatSeconds} from "@/util/common";
export default {
    name: "recommendTime",
    data(){
        return{
            list:[{"name":this.$t('S_C_73'),"color":"#FF5151"},
                {"name":this.$t('S_C_74'),"color":"#BB0909"},
                {"name":this.$t('S_C_75'),"color":"#FFAE36"},
                {"name":this.$t('S_C_76'),"color":"#935801"}
            ],
            texts:'',
            deliList:[{"label":this.$t('S_C_77'),"value":7},{"label":this.$t('S_C_78'),"value":14},{"label":this.$t('S_C_79'),"value":30}],
            myChartStyle: {
                float: "center",
                width: "700px",
                height: "550px",
                paddingTop: "0",
                // margin: "20px",
            }, //图表样式
            sexData: [
                {
                    data: [150, 230, 224, 218, 135, 147, 260],
                    type: 'line',
                    color:'#FE9A02',
                    label: {
                        show: false,
                        position:'right',
                    },
                },
            ],
        }
    },
    mounted() {
        this.selected();
    },
    methods:{
        selectTime(data) {
            this.texts =data
            this.getData();
        },
        selected() {
            getDayType().then((res)=>{
                this.deliList = res.data
                this.ageChart(res.data);
                this.getData(res.data[0].value);
                this.texts = res.data[0].label
            })
        },
        getData(value) {
            getIndexAddAchieve({"dayType":this.texts || value}).then((res)=>{
                this.ageChart(res.data);
            })
        },
    ageChart(data) {
            var myChart = echarts.init(document.getElementById('main1'));
            let time =[];
            let time1 =[];
            let arr1 = [];
            let arr2 = [];
            let arr3 = [];
            let arr4 = [];
            data.forEach((item)=>{
                if(item.settleDate){
                    // let time1=item.settleDate.split("-");
                    // let json = time1[1]+'-'+time1[2]
                    time.push(item.settleDate)
                }
                arr1.push(item.repANewPv)
                arr2.push(item.repBNewPv)
                arr3.push(item.bNewPv)
                arr4.push(item.aNewPv)
            })
            console.error(time)
            var option = {
                title: {
                    text: this.$t('S_C_72')
                },
                tooltip: {
                    // _8:function (params){
                    //     return `<div style="text-align: center;color: #fff">`+params[0].axisValue+'<br/>'+ '<span style="">'+params[0].value + `</span>`
                    // },
                    // backgroundColor: '#FF5151',
                    // trigger: 'axis',
                    // axisPointer: {
                    //     type: 'shadow'
                    // }
                },
                legend: {
                    // left: "right",\\
                    top: "70px",
                    right: "30px",
                    width: "auto",
                    orient: 'vertical',
                    itemHeight: 21,
                    itemWidth:39
                },
                grid: {
                    left: '0%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data:time,
                    axisLine:{
                        show:true,
                        lineStyle: {
                            color: "#333",
                            width: '1',    // 坐标轴线线宽
                            type: 'solid',    // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
                        },
                    },
                    axisTick: {
                        show: true,    // 是否显示坐标轴刻度
                        inside: true,     // 坐标轴刻度是否朝内，默认朝外
                        length: 5,    // 坐标轴刻度的长度
                        lineStyle: {
                            color: '#FFF',     // 刻度线的颜色
                            width: 10,    // 坐标轴刻度线宽
                            type: 'solid',    // 坐标轴线线的类型（solid实线类型；dashed虚线类型；dotted点状类型）
                        },
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel:{
                        type: 'category',
                        interval: 0,
                        textStyle: {
                            color: '#000',
                            fontSize: '10',
                            itemSize: '',
                        },
                        formatter: function(data){
                            return data
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#CCCCCC",
                            type: "dashed", //虚线
                            width: 2
                        }
                    }
                },
                series: [
                    {
                        data: arr4,
                        type: 'line',
                        color:'#FF5151',
                        label: {
                            show: false,
                            position:'right',
                        },
                        formatter: function(data){
                           console.error(data)
                        }
                    },
                    {
                        data: arr1,
                        type: 'line',
                        color:'#BB0909',
                        label: {
                            show: false,
                            position:'right',
                        },
                    },
                    {
                        data: arr3,
                        type: 'line',
                        color:'#FE9A02',
                        label: {
                            show: false,
                            position:'right',
                        },
                    },
                    {
                        data: arr2,
                        type: 'line',
                        color:'#935801',
                        label: {
                            show: false,
                            position:'right',
                        },
                    }
                ],
            };

            myChart.setOption(option);

        },
    }
}
</script>

<style scoped lang="scss">
::v-deep .el-select .el-input .el-select__caret{
    color: #333;
}
.lines{
    width: 2px;
    height: 470px;
    opacity: 1;
    background: #979797;
    position: absolute;
    bottom: 50px;
    left: 50%;
    margin-left: -110px;
}
.right-lines{
  position: absolute;
  right: 80px;
  top: 140px;
  color: #999;
  font-size: 12px;
}
.item-s{
  padding-bottom: 10px;
  display: flex;
  line-height: 24px;
}
.bg-color{
    width: 40px;
    height: 22px;
    border-radius: 2px;
  margin-left: 5px;
}
.flex-s{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 850px;
}
.styles{
    position: absolute;
    right: -30px;
    z-index: 111;
    top: 60px;
    /*background: rgba(0,0,0,0.05);*/
    /*border-radius: 4px 4px 4px 4px;*/
}
::v-deep .el-select .el-input__inner{
    border: none;
    background: rgba(0,0,0,0.05);
}
</style>