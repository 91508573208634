<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2024-09-19 11:15:25
-->
<template>
  <div class="content">
    <el-row style="width: 100%" :gutter="20">
      <el-col :span="8" v-for="(item, index) in awardsList" :key="index">
        <div :class="item.nowFlag == 0 ? 'huang' : 'kuang'">
          <div class="kuang_t">
            <div>{{ item.dateYear }}</div>
            <div>{{ item.dateMonth }}月</div>
          </div>
          <div class="kuang_b">{{ item.awardsName }}</div>
        </div>
      </el-col>
    </el-row>
    <!-- <div
      v-for="(item, index) in awardsList"
      :key="index"
      :class="item.nowFlag == 0 ? 'huang' : 'kuang'"
    >
     
    </div> -->
  </div>
</template>

<script>
import * as api from "@/api/index";

export default {
  data() {
    return {
      awardsList: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      api.yearAwards().then((res) => {
        this.awardsList = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px 20px;
  .kuang {
    padding: 8px 16px;
    background: #fff;
    box-shadow: 0px 0px 10px 0px rgba(59, 59, 59, 0.14);
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 20px;
    height: 50px;
    .kuang_t {
      display: flex;
      align-items: center;
      justify-content: space-between;
      :nth-child(1) {
        color: #5f5f5f;
      }
      :nth-child(2) {
        color: #333;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .kuang_b {
      color: #333;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .huang {
    padding: 8px 16px;
    background: linear-gradient(#dca665, #d1924d);
    box-shadow: 7px 5px 10px 0px rgba(59, 59, 59, 0.14);
    border-radius: 10px 10px 10px 10px;
    margin-bottom: 20px;
    height: 50px;
    .kuang_t {
      display: flex;
      align-items: center;
      justify-content: space-between;
      :nth-child(1) {
        color: #fff;
      }
      :nth-child(2) {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .kuang_b {
      color: #fff;
      font-size: 18px;
      font-weight: 600;
    }
  }
}
</style>
